import { UserStore } from "@/data/user-settings.store";
import { cn } from "@/lib/utils";
import { setUIState } from "@game/ui/ui.states";
import type { IItemShopProduct } from "./itemShop.store";
import { itemData } from "store-contracts/script/input/itemData.json";
import { getEntityDataByID } from "@/data/entity/entity.data.fn";
import { formatUnits } from "viem";
import { t } from "i18next";
import { StoreStates } from "./itemShop.states";
import { GameStates } from "@game/game.states";
import { GameMenuStates } from "../gameMenu/gameMenu.states";

// function that makes an array a minimum of 2 lines
function makeArray2Lines(arr: string[]) {
	if (arr.length > 1) return arr;
	return [...arr, ""];
}

export function alternateFlexLines(sentence: string) {
	// if sentence contains dots, we use split on that instead:
	let splitter = " ";
	if (sentence.includes(".")) {
		splitter = ".";
	}
	return (
		<>
			{makeArray2Lines(sentence?.split(splitter)).map((line, idx) => (
				<div
					key={idx}
					className={cn(
						idx % 1 === 0 ? "justify-end self-end" : "justify-start self-start",
					)}
				>
					{line}
				</div>
			))}
		</>
	);
}

export function openItemShop() {
	setUIState(StoreStates.store);
}

export function closeItemShop() {
	if (UserStore().signedUp) {
		setUIState(GameStates.playMode);
	} else {
		setUIState(GameMenuStates.readyToSignup);
	}
}

export function getSelectedItems(items: IItemShopProduct[]): {
	selectedItems: IItemShopProduct[];
	totalPrice: number;
} {
	const selectedItems: IItemShopProduct[] = [];
	let totalPrice = 0;
	items.forEach((item) => {
		if (item.selected) {
			const p = getProductData(item);
			if (!p) return;
			selectedItems.push({
				...item,
				name: p.name,
			});
			totalPrice += p.price;
		}
	});
	return { selectedItems, totalPrice };
}

export type TProductStoreData = {
	name: string;
	description: string;
	subtitle: string;
	price: number;
	icon: string;
};

export function getProductData(item: IItemShopProduct): TProductStoreData {
	const entityData = getEntityDataByID(item.entityTypeId);
	const storePriceData = itemData.find((i) => i.itemId === item.entityTypeId);
	if (!storePriceData) {
		console.error("❌ No store price data for", item);
		return {
			name: "unknown",
			description: "unknown",
			subtitle: "unknown",
			price: 0,
			icon: "unknown.webp",
		};
	}
	const price = Number(
		formatUnits(BigInt(storePriceData?.price.toString()), 18),
	);
	const name = entityData.name;
	return {
		name: t(`items.${name}.shop_name`) || "unknown",
		description: t(`items.${name}.description`) || "unknown",
		subtitle: t(`items.${name}.shop_subtitle`) || "unknown",
		price,
		icon: `/icons/${entityData.metadata?.icon ?? "unknown.webp"}`,
	};
}
