import { logVerbose } from "@/lib/devtools/dev.store";
import type { TParticle } from "@/data/particle.data";
import { Debug } from "@/lib/debug/debug";
import type { Euler, Object3D, Object3DEventMap, Scene, Vector3 } from "three";
import {
	BatchedRenderer,
	type ParticleSystem,
	QuarksLoader,
} from "three.quarks";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export type TParticleEffect = {
	particle: TParticle;
	position?: Vector3;
	scale?: Vector3;
	rotation?: Euler;
	lifetime?: number;
	broadcast?: boolean;
	onDestroy?: (effect: TParticleEffectRef) => void;
};

export type TParticleEffectRef = {
	particles: ParticleSystem[];
	particleEffect: Object3D<Object3DEventMap>;
	createdAt: Date;
	expiration: number;
	timeout?: Timer;
	onDestroy?: (effect: TParticleEffectRef) => void;
};

export interface IParticleStore {
	scene: Scene | null;
	loader: QuarksLoader;
	renderer: BatchedRenderer;
	particles: TParticleEffectRef[];
	addParticleEffect: (effect: TParticleEffectRef) => void;
	removeParticleEffect: (effect: TParticleEffectRef) => void;
}

const useParticleStore = create<IParticleStore>()(
	immer((set, get) => ({
		scene: null,
		loader: new QuarksLoader(),
		renderer: new BatchedRenderer(),
		particles: [],
		addParticleEffect(effect: TParticleEffectRef) {
			logVerbose() &&
				Debug("Particle").log(
					`addParticle [${effect.particleEffect.name}] active:`,
					get().particles.length,
					effect,
				);
			set((state) => {
				state.particles = [...get().particles, effect];
			});
		},
		removeParticleEffect(effect: TParticleEffectRef) {
			set((state) => {
				state.particles = [...get().particles.filter((p) => p !== effect)];
			});
			effect.particles.forEach((p) => get().renderer.deleteSystem(p));
			effect.onDestroy?.(effect);
			logVerbose() &&
				Debug("Particle").log(
					`removeParticle [${effect.particleEffect.name}] active:`,
					get().particles.length,
					effect,
				);
		},
	})),
);

const ParticleStore = () => ({
	...useParticleStore.getState(),
	set: useParticleStore.setState,
});

ParticleStore().loader.setCrossOrigin("");
export { ParticleStore, useParticleStore };
