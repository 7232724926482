import type { SimEntity } from "./SimEntity";
import { getMUDState, type tables } from "@mud";
import type { TComponentInstanceCtor } from "./types/sim.types";
import type { Hex } from "viem";
import { Debug } from "@/lib/debug/debug";
import type { SimComponent } from "./components/SimComponent";
import { logVerbose } from "@/lib/devtools/dev.store";

type TablesType = typeof tables;

type TComponentPair<
	K extends keyof TablesType = keyof TablesType,
	T extends SimComponent = SimComponent,
> = [TablesType[K], TComponentInstanceCtor<T>];

type ComponentMap = TComponentPair[];

const componentMap: ComponentMap = [];

export function registerComponent<
	K extends keyof TablesType,
	T extends SimComponent,
>(table: TablesType[K], ctor: TComponentInstanceCtor<T>): void {
	if (
		!componentMap.some(
			([t, c]) => t.name === table.name && c.name === ctor.name,
		)
	) {
		componentMap.push([table, ctor]);
		logVerbose() &&
			Debug("Components").log(`Registered component ${ctor.name}`);
	}
}

export const addComponentsFromChain = async (entity: SimEntity) => {
	const added = [];
	for (const [table, ctor] of componentMap) {
		const value = getMUDState().getValue(table, { inst: entity.ref as Hex });
		if (value && !entity.component(ctor)) {
			entity.addComponent(ctor);
			added.push(ctor.name);
		}
	}
	if (added.length > 0)
		logVerbose() &&
			Debug("Components").log(
				`${entity.name} (${entity.ref.slice(0, 6)}) added ${added.join(", ")}`,
			);
};
