import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CCrop } from "@game/sim/components/CCrop";
import { CEdible } from "@game/sim/components/CEdible";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CPlantable } from "@game/sim/components/CPlantable";
import { CRenderer } from "@game/sim/components/CRenderer";
import { CUpdate } from "@game/sim/components/CUpdate";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { ItemYamRenderer } from "./yam.renderer";
import { registerFormula, type TFormula } from "@/data/formula/formula.data";

export class ItemYamPrefab extends SimEntityPrefab {
	crop: CCrop | null = null;

	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem).setSigil("vegetable");
		e.addComponent(CRenderer).setRenderer(ItemYamRenderer);
		e.addComponent(CEdible);
		e.addComponent(CPlantable).setProductPrefab(ItemYamPrefab);
		this.crop = e.addComponent(CCrop);
		e.addComponent(CUpdate).setUpdateFn(this.update.bind(this));
	}

	update = () => {
		this.crop?.checkGrowth();
	};
}

const entityData: TEntityDefinition = {
	name: "Yam",
	simEntityPrefab: ItemYamPrefab,
	id: 30014,
	metadata: {
		icon: "yam.webp",
	},
};

const yamPurple = {
	name: "YamPurple",
	activeItem: [30003],
	combinesWith: [30014],
	produces: 30410,
	destroysActiveItem: false,
}  as const as TFormula;

registerEntity(entityData);
registerFormula(yamPurple)