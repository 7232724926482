import type { Block } from "@game/world/block";
import { getMUDState, tables } from "@mud";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import { SimComponent } from "./SimComponent";

export type TRendererProps<P = unknown> = {
	entity: SimEntity;
} & P;
export type TRendererFn = ({
	entity,
	...props
}: TRendererProps) => React.ReactNode;

export class CRenderer extends SimComponent {
	renderer: TRendererFn | null = null;
	block: Block | null = null;
	orientation = 0;
	previousNeighbours: SimEntity[] = [];

	constructor(inst: SimEntity) {
		super(inst);
		this.inst.listenForEvent(
			"neighbourUpdate",
			this.onNeighbourUpdate.bind(this),
		);
	}

	onNeighbourUpdate() {
		// const newNeighbours = this.block?.getNeighbourEntities();
	}

	async loadFromChain(): Promise<void> {
		const value = getMUDState().getValue(tables.Orientation, {
			inst: this.inst.ref as Hex,
		});
		if (value) {
			this.orientation = value.orientation;
		}
	}

	setRenderer(renderer: TRendererFn<unknown>) {
		this.renderer = renderer;
	}

	setOrientation(orientation: number) {
		this.orientation = orientation;
	}

	setBlock(block: Block) {
		this.block = block;
	}
}
