import { getMUDState, tables } from "@mud";
import type { Hex } from "viem";
import { SimComponent } from "./SimComponent";
import { BYTES32_ZERO } from "@/lib/utils";
import { getEntityByRef } from "../sim.store";
import { CRenderer } from "./CRenderer";
import type { SimEntity } from "../SimEntity";

export class CDecorator extends SimComponent {
	decoratorKeys: readonly Hex[] = [];
	decoratorCount: number = 0;

	value = () =>
		getMUDState().getValue(tables.Decorator, {
			inst: this.inst.ref as Hex,
		})!;

	async loadFromChain(): Promise<void> {
		if (!this.value()) {
			console.warn(`no value for ${this.constructor.name}`, this.inst.ref);
			this.inst.clearComponent(CDecorator);
			return;
		}

		const value = this.value();
		this.decoratorKeys = value.decoratorKeys;
		this.decoratorCount = this.decoratorKeys.filter(
			(k) => k !== BYTES32_ZERO,
		).length;
	}

	getDecorators() {
		return this.decoratorKeys.map((d, idx) => {
			const decoratorEntity = getEntityByRef(d);
			return decoratorEntity;
		});
	}

	hasSideWall(index = 2) {
		return this.decoratorKeys.some(
			(d, idx) =>
				(idx + this.inst.component(CRenderer).orientation) % 4 === index &&
				getEntityByRef(d),
		);
	}

	hasTopWall() {
		return this.decoratorKeys.some((d, idx) => idx === 4 && getEntityByRef(d));
	}

	hasBottomWall() {
		return this.decoratorKeys.some((d, idx) => idx === 5 && getEntityByRef(d));
	}

	getOrientation(decorator: SimEntity) {
		return this.decoratorKeys.indexOf(decorator.ref as Hex) || 0;
	}

	isFullBuilding() {
		return this.decoratorCount >= 4;
	}
}
