import { ENV } from "@/lib/env";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { APP_DATA } from "./app.data";

interface IUserStore {
	signedUp: boolean;
	showTerminal: boolean;
	playerId: number;
	spectator: boolean;
	cinematic: boolean;
	worldAddress: string | undefined;
	isPlayer: () => boolean;
	isSpectator: () => boolean;
	isCinematic: () => boolean;
	setSignedUp: (worldAddress: string) => void;
	reset: () => void;
}

const defaultState = {
	signedUp: false,
	showTerminal: false,
	playerId: -1,
	spectator: false,
	cinematic: false,
	worldAddress: undefined,
};

const useUserStore = create<IUserStore>()(
	persist(
		immer((set, get) => ({
			...defaultState,
			isPlayer: () => get().playerId !== -1 && !ENV.SPECTATOR,
			isSpectator: () => ENV.SPECTATOR ?? get().spectator,
			isCinematic: () => ENV.CINEMATIC ?? get().cinematic,
			setSignedUp: (worldAddress: string) => {
				set((state) => {
					state.signedUp = true;
					state.worldAddress = worldAddress;
				});
			},
			reset: () => {
				if (window !== undefined) {
					window.localStorage.clear();
					window.location.reload();
				}
				set({
					...get(),
					...defaultState,
				});
			},
		})),
		{
			name: `${APP_DATA.storageName}-user`,
			version: parseInt(ENV.WORLD_ADDRESS || "8"),
		},
	),
);

const UserStore = () => ({
	...useUserStore.getState(),
	set: useUserStore.setState,
});
export { UserStore, useUserStore };
