import { tables } from "@mud/index";
import { registerComponent } from "./component.functions";
import { CRummage } from "./components/CRummage";
import { CStackable } from "./components/CStackable";
import { CInventoryItem } from "./components/CInventoryItem";
import { CBurnable } from "./components/CBurnable";
import { CCrop } from "./components/CCrop";
import { CGrowable } from "./components/CGrowable";
import { CContainer } from "./components/CContainer";
import { CDecorator } from "./components/CDecorator";
import { CFueled } from "./components/CFueled";
import { CVariant } from "./components/CVariant";

export const setupComponents = () => {
	registerComponent(tables.Stackable, CStackable);
	registerComponent(tables.Rummage, CRummage);
	registerComponent(tables.InventoryItem, CInventoryItem);
	registerComponent(tables.Burnable, CBurnable);
	registerComponent(tables.Crop, CCrop);
	registerComponent(tables.Growable, CGrowable);
	registerComponent(tables.Container, CContainer);
	registerComponent(tables.Decorator, CDecorator);
	registerComponent(tables.Fueled, CFueled);
	registerComponent(tables.Variant, CVariant);
};
