import type { Material, Mesh, Texture } from "three";
import { AssetStore } from "@/data/assetLoader/asset.store";
import type { RootState } from "@react-three/fiber";

export type TRenderData = {
	materials?: Record<string, Material>;
	meshes?: Record<string, Mesh>;
	textures?: Record<string, Texture>;
	onUpdate?: (state: RootState, delta: number, self: TRenderData) => void;
};

export const SHARED_RENDERDATA: Record<string, TRenderData> = {};

const _renderData_setup: Record<string, () => TRenderData> = {};

export const registerRenderData = (key: string, data: () => TRenderData) => {
	_renderData_setup[key] = data;
	if (AssetStore().loadingComplete) {
		SHARED_RENDERDATA[key] = data();
	}
};

export const setupSharedAssets = () => {
	Object.entries(_renderData_setup).forEach(([key, data]) => {
		SHARED_RENDERDATA[key] = data();
	});
};

export const updateRenderData = (state: RootState, delta: number) => {
	Object.values(SHARED_RENDERDATA).forEach((data) => {
		data.onUpdate?.(state, delta, data);
	});
};
