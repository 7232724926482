import { truncateAddress } from "@/lib/utils";
import { getNetwork, getSystemCalls } from "@mud";
import storeABI from "store-contracts/out/CloudlinesStore.sol/CloudlinesStore.abi.json";
import { useEffect, useState } from "react";
import { type Hex, formatEther, parseEther } from "viem";
import {
	type BaseError,
	useAccount,
	useBalance,
	useEnsName,
	useWaitForTransactionReceipt,
	useWriteContract,
} from "wagmi";
import { getBalance, switchChain } from "@wagmi/core";
import { ENV } from "@/lib/env";
import stores from "store-contracts/stores.json";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import { wagmiConfig } from "./web3Provider";

export type TPaymentStatus = "pending" | "success" | "error" | "confirming";

const usePayments = () => {
	const [connectText, setConnectText] = useState("Connect");
	const { authenticated, ready } = usePrivy();
	const { wallets } = useWallets();
	const { address, isDisconnected, isConnected } = useAccount();
	const { data: ensName, refetch: refetchEnsName } = useEnsName({
		address,
		query: { enabled: ready },
	});
	const balance = useBalance({ address, query: { enabled: ready } });
	const [paymentStatus, setPaymentStatus] = useState<TPaymentStatus | null>(
		null,
	);
	const [allowBypass, setAllowBypass] = useState(false);

	const {
		writeContractAsync,
		data: transactionHash,
		isError,
		isSuccess: isConfirmed,
		error,
	} = useWriteContract();
	const transactionReceipt = useWaitForTransactionReceipt({
		hash: transactionHash,
	});

	useEffect(() => {
		if (!ready) return;

		const checkBalance = async () => {
			if (!address) return;
			balance.refetch();
			const b = getBalance(wagmiConfig, {
				address: address as Hex,
			});
		};
		checkBalance();
		const checkBypass = async () => {
			if (!authenticated) return false;
			if (wallets.length === 0) {
				setAllowBypass(true);
			}
		};
		checkBypass();
		refetchEnsName();
		if (isConnected && address) {
			const name = ensName || truncateAddress(address);
			setConnectText(name);
		}
		if (isDisconnected) {
			setConnectText("Connect");
		}
	}, [
		address,
		isDisconnected,
		isConnected,
		ensName,
		refetchEnsName,
		balance,
		ready,
		authenticated,
		wallets,
	]);

	useEffect(() => {
		if (!error) return;
		const e = error as BaseError;
		console.error("error", e.shortMessage);
		console.error("error", e.name);
	}, [error]);

	const pay = async (
		amount: string,
		entityTypeIds: number[],
	): Promise<boolean> => {
		const chainId = ENV.VITE_STORE_CHAIN_ID as unknown as keyof typeof stores;
		const store = stores[chainId];
		if (!store) {
			throw new Error(`No store found for chain ${chainId}`);
		}
		if (
			ENV.BYPASS_PAYMENTS ||
			Number(amount) === 0 ||
			allowBypass ||
			!balance?.data
		) {
			const tx = await getSystemCalls().action_purchaseStartingItems(
				getNetwork().playerEntity as Hex,
				entityTypeIds,
			);
			await getNetwork().waitForTransaction(tx);
			setPaymentStatus("success");
		} else {
			if (!balance?.data) {
				throw new Error("can't read user balance");
			}
			const currentBalance = formatEther(balance.data!.value);
			if (amount > currentBalance) {
				throw new Error("Not enough balance");
			}

			await switchChain(wagmiConfig, { chainId: wagmiConfig.chains[0].id });

			setPaymentStatus("confirming");
			const args = [address, getNetwork().playerEntity as Hex, entityTypeIds];
			console.log(getNetwork().playerEntity, chainId);
			console.log("BALBA");
			// FIXME: If you end up here trying to find out why the 31337 Anvil TX isn't working; clear your pending transactions in your wallet based browser extension.
			const tx = await writeContractAsync({
				address: store.address as Hex,
				abi: storeABI,
				functionName: "purchaseItem",
				args,
				value: parseEther(amount),
			});
			console.log(tx);
		}
		return true;
	};

	useEffect(() => {
		if (transactionHash) {
			console.log("Transaction hash:", transactionHash);
		}
		if (isConfirmed) {
			setPaymentStatus("pending");
		}
		if (transactionReceipt.data) {
			setPaymentStatus("success");
		}
		if (isError) {
			setPaymentStatus("error");
		}
	}, [transactionHash, transactionReceipt, isError, isConfirmed]);

	return {
		connectText,
		allowBypass,
		isConnected,
		pay,
		paymentStatus,
		error: error as BaseError | null,
		balance,
		transactionReceipt,
	};
};

export default usePayments;
