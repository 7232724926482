import {
	useBatchedRenderStore,
	type TBatchedGeometryData,
	type TRenderBatchedOptions,
} from "@game/components/batchSystem";
import type { SimEntity } from "@game/sim/SimEntity";
import { useCallback, type DependencyList, useEffect, useState } from "react";
import type { BufferGeometry, Material } from "three";

export const useBatchedRenderer = (
	entity: SimEntity,
	renderFn:
		| (() => {
				geometry: BufferGeometry;
				material: Material;
				opts?: TRenderBatchedOptions;
		  })
		| null,
	dependencies?: DependencyList,
) => {
	const renderCallback = useCallback(
		renderFn || (() => null),
		dependencies || [entity],
	);
	const [batchData, setBatchData] = useState<TBatchedGeometryData | null>(null);
	const { addGeometry, removeGeometry } = useBatchedRenderStore();
	useEffect(() => {
		// when hook is called, we execute the render function and get the geometry and material -> this we want to set up in the store
		const result = renderCallback();
		if (!result) return;
		const { geometry, material, opts } = result;
		if (!geometry) {
			console.error("no geometry for", entity.ref);
			return;
		}
		if (!material) {
			console.error("no material for", entity.ref);
			return;
		}
		const batchData = addGeometry(material, geometry, opts);
		if (!batchData) {
			console.error("no batchData for", entity.ref);
			return;
		}
		setBatchData(batchData);
		return () => {
			removeGeometry(material, geometry);
		};
	}, [renderCallback, addGeometry, removeGeometry, entity.ref]);
	return batchData;
};
