import { ENV } from "@/lib/env";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { PropsWithChildren } from "react";
import { http } from "wagmi";
import { getChainDataById } from "@jungle/common/src/mud.supportedChains";
import { createConfig, WagmiProvider } from "@privy-io/wagmi";
import type { Chain, HttpTransport } from "viem";
import { PrivyProvider } from "@privy-io/react-auth";
import { Debug } from "@/lib/debug/debug";

const queryClient = new QueryClient();

const storeChain = getChainDataById(ENV.VITE_STORE_CHAIN_ID);
if (!storeChain) {
	throw new Error(`❌ No chain found for ${ENV.VITE_STORE_CHAIN_ID}`);
}
const chains = [storeChain] as readonly [Chain, ...Chain[]];

const transports = chains.reduce(
	(acc, chain) => {
		acc[chain.id.toString()] = http(chain.rpcUrls.default.http[0]);
		return acc;
	},
	{} as Record<string, HttpTransport>,
);

Debug("Web3Provider").log(chains.map((c) => c.id.toString()));

export const wagmiConfig = createConfig({
	chains, // Pass your required chains as an array
	transports,
});

export function Web3Provider({ children }: PropsWithChildren) {
	return (
		<PrivyProvider appId="clw8oxrcw0f4ghredtg7rvf3m">
			<QueryClientProvider client={queryClient}>
				<WagmiProvider config={wagmiConfig}>{children}</WagmiProvider>
			</QueryClientProvider>
		</PrivyProvider>
	);
}
