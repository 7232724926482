import { openItemShop } from "../itemShop/itemShop.fn";

export default function ShopIcon() {
	return (
		<div
			className="relative pointer-events-auto items-center flex w-0 overflow-visible group hover:cursor-pointer"
			onClick={() => openItemShop()}
		>
			<div className="absolute left-[15%] w-[100%] z-0 transition-all text-2xl items-center pointer-events-auto">
				<div className="absolute flex">
					<div className="absolute text-white w-10 h-10 bg-transparent text-center items-center m-auto self-center place-self-center">
						<div className="absolute text-white w-full h-full  pointer-events-none duration-200 -z-10 -mt-[4px] blur-[0.1px] bar-splash scale-[120%] group-hover:-rotate-180 rotate-0 group-hover:scale-[130%] opacity-20 group-hover:opacity-100" />
						<div className="absolute text-white w-full h-full bg-[#BCE5DA]/20 group-hover:bg-[#BCE5DA]/70 rounded-full pointer-events-auto duration-150 -z-10 -mt-[4px] blur-[0.1px] scale-[100%] group-hover:scale-[120%]" />
						<div
							// src="/icons/techbox.webp"
							className="w-8 h-8 rounded-full items-center self-center place-self-center m-auto group-hover:scale-[130%] duration-200 ease-in-out transition-all grayscale-[30%] group-hover:grayscale-0"
						>
							🎁
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
