import { Debug } from "@/lib/debug/debug";
import type { Hexagrid } from "@/lib/hexagrid/hexagrid";
import type { Quad } from "@/lib/hexagrid/hexagrid.types";
import { ISLANDS, type Island } from "@game/world/islands/islands.functions";
import { WorldCoordinate } from "@game/world/world.types";
import {
	type ExtrudeGeometry,
	type Intersection,
	type Object3D,
	type Object3DEventMap,
	type ShapeGeometry,
	type Vector2,
	Vector3,
} from "three";
import { getBlock, getEntityByCoordinate } from "./block.store";
import type { IBlock } from "./block.types";
import { getGrid } from "./grid.store";
import type { SimEntity } from "@game/sim/SimEntity";
import { getNetwork } from "@mud/index";
import {
	extrudeBlock,
	extrudeSides,
	getSideFromHit,
} from "@game/components/constructableInput.fn";

export class Block implements IBlock {
	position: Vector3;
	rotation: number;
	coordinate: WorldCoordinate;
	neighbours: WorldCoordinate[] = [];
	grid: Hexagrid;
	_quad: Quad;
	_quadCenter: Vector2;
	_points: Vector2[];
	_sides: Vector2[][];
	_seed: number;
	sideToNeighbour: Record<number, number> = {};
	nDirs: Vector3[] = [];
	_collisionGeometry: ExtrudeGeometry | null = null;
	_extrudeSides: (ExtrudeGeometry | ShapeGeometry)[] | null = null;

	constructor(coordinate: WorldCoordinate) {
		this.coordinate = coordinate;
		this.grid = getGrid(coordinate.grid);

		// Export internals
		this._quad = this.grid.getQuadByIndex(coordinate.quad)!;
		this._quadCenter = this.grid.exportQuadCenter(this._quad);
		this._points = this.grid.exportQuad(this._quad);

		// create Sides
		this._sides = [];
		for (let i = 0; i < this._points.length; i++) {
			const side = [
				this._points[i].clone().sub(this._quadCenter),
				this._points[(i + 1) % this._points.length]
					.clone()
					.sub(this._quadCenter),
			];
			this._sides.push(side);
		}

		// Create externals
		this.position = new Vector3(
			this._quadCenter.x,
			coordinate.y / 2,
			this._quadCenter.y,
		);
		this.rotation = coordinate.quad;

		// Cache neighbour positions
		this.getNeighbours();
		this._seed = Block.getSeed(this);
	}

	private getNeighbours() {
		const adjacent = this.grid.getAdjacentQuads(this._quad);
		const neighbours: WorldCoordinate[] = [];
		const toSide: Record<number, number> = {};
		const points = this._quad.points;
		// const checkedPoints: Point[] = [];
		for (let i = 0; i < points.length; i++) {
			const p = points[i];
			const p1 = points[(i + 1) % points.length];
			// find an adjacent quad that has these same points
			const quad = adjacent.find(
				(q) => q.points.includes(p) && q.points.includes(p1),
			);
			if (quad) {
				const n = new WorldCoordinate(
					this.coordinate.grid,
					this.grid.getQuadIndex(quad),
					this.coordinate.y,
				);
				neighbours[i] = n;
				toSide[i] = i;
			} else {
				if (this._quad.side) {
					const pointA = this.grid.points[p];
					const pointB = this.grid.points[p1];
					const nHexCoord = this.grid.getOppositeGridCoord(pointA, pointB);
					const nGrid = getGrid(nHexCoord);
					if (nGrid) {
						const ordPointA = this.grid.getOrderedIndex(pointA);
						const ordPointB = this.grid.getOrderedIndex(pointB);
						const oPointA = nGrid.getOppositePointIndexByIndex(ordPointA);
						const oPointB = nGrid.getOppositePointIndexByIndex(ordPointB);
						const nQuads = nGrid.getSideQuadsByOrderedPointIndices(
							oPointA,
							oPointB,
						);
						const nQuad = nQuads.find((q) => q !== this._quad);
						if (!nQuad) {
							Debug("Block").warn(
								"no quad found",
								ordPointA,
								ordPointB,
								oPointA,
								oPointB,
							);
							// throw new Error("no quad found");
							continue;
						}
						const nQuadIndex = nGrid.getQuadIndex(nQuad);
						if (nQuadIndex === -1) throw new Error("no quad found");
						const n = new WorldCoordinate(
							nGrid.coordinates,
							nQuadIndex,
							this.coordinate.y,
						);
						neighbours[i] = n;
						toSide[i] = i;
					}
				} else {
					throw new Error("neighbour out of grid and not a side");
				}
			}
		}
		if (neighbours.length < 4) {
			console.warn("Missing neighbours (hex edge)", this.coordinate);
		}
		neighbours[4] = new WorldCoordinate(
			this.coordinate.grid,
			this.coordinate.quad,
			this.coordinate.y + 1,
		);
		neighbours[5] = new WorldCoordinate(
			this.coordinate.grid,
			this.coordinate.quad,
			this.coordinate.y - 1,
		);
		toSide[4] = 4;
		toSide[5] = 5;
		this.neighbours = neighbours;
		this.sideToNeighbour = toSide;
	}

	hasNeighbourUp(): boolean {
		return getBlock(this.neighbours[4]) !== undefined;
	}

	hasNeighbourDown(): boolean {
		return getBlock(this.neighbours[5]) !== undefined;
	}

	hasSideNeighbours(): number {
		let n = 0;
		for (let i = 0; i < 4; i++) {
			if (this.neighbours[i] === undefined) continue; // TODO remove this
			n += getBlock(this.neighbours[i]) ? 1 : 0;
		}
		return n;
	}

	getNeighbourDirections(): boolean[] {
		return this.neighbours.map((n) => n !== undefined);
	}

	hasNeighbourInDirections(directions: number[]): boolean {
		for (let i = 0; i < 6; i++) {
			const dir = directions[i];
			const n = this.neighbours[dir];
			if (n && getEntityByCoordinate(n)) return true;
		}
		return false;
	}

	getNeighbourEntities(): SimEntity[] {
		return this.neighbours
			.map((n) => getEntityByCoordinate(n) || null)
			.filter((n) => n !== null);
	}

	getIsland(): Island {
		return ISLANDS.blockMapToIsland.get(this.coordinate)!;
	}

	getCollisionGeometry() {
		if (!this._collisionGeometry || !this._extrudeSides) {
			this._collisionGeometry = extrudeBlock(this);
			this._extrudeSides = extrudeSides(this);
		}
		return {
			blockCollisionGeometry: this._collisionGeometry!,
			sides: this._extrudeSides!,
		};
	}

	getSideFromHit(hit: Intersection<Object3D<Object3DEventMap>>) {
		return Number(getSideFromHit(this._collisionGeometry!, hit, this));
	}

	static getSeed(block: Block): number {
		const address = parseInt(
			parseInt(getNetwork().worldContract.address.slice(0, 32))
				.toString()
				.slice(-16),
		);
		const [gridX, gridY, gridZ] = block.position;
		const quadIndex = block.coordinate.quad;
		const poly =
			gridX + 31 * (gridY + 31 * (gridZ + 31 * (quadIndex + 31 * gridY)));
		const uniqueId =
			(gridX * 73856093) ^
			(gridY * 19349663) ^
			(gridZ * 83492791) ^
			(quadIndex * 39916801);
		const seed = Math.abs(parseInt((poly + uniqueId).toFixed(0)) + address);
		return seed;
	}
}
