import "./crops/bamboo.prefab";
import "./crops/berries.prefab";
import "./crops/carrot.prefab";
import "./crops/goldenrod.prefab";
import "./crops/mixedseed.prefab";
import "./crops/pepper.prefab";
import "./crops/spinach.prefab";
import "./crops/yam.prefab";
import "./crops/wheat.prefab";
import "./bed.prefab";
import "./dirtyGlasses.prefab";
import "./drumMachine.prefab";
import "./farm.prefab";
import "./fertilizer.prefab";
import "./goldenShovel.prefab";
import "./house.prefab";
import "./jungle.prefab";
import "./lapis.prefab";
import "./limestone.prefab";
import "./meteorite.prefab";
import "./ochre.prefab";
import "./pigment.prefab";
import "./pinkGlasses.prefab";
import "./player.prefab";
import "./rock.prefab";
import "./scaffold.prefab";
import "./shovel.prefab";
import "./stormBottle.prefab";
import "./terminal.prefab";
import "./turqoise.prefab";
import "./wood.prefab";
import "./world.prefab";
import "./sheetmetal.prefab";
import "./charcoal.prefab";
import "./ticket.prefab";
import "./lighter.prefab";
import "./waterbucket.prefab";

import { logVerbose } from "@/lib/devtools/dev.store";
import { getEntityData } from "@/data/entity/entity.data.fn";
import { Debug } from "@/lib/debug/debug";

logVerbose() &&
	Debug("Entities").log(
		"✅ Loaded Entities",
		Object.values(getEntityData())
			.sort((a, b) => a.id - b.id)
			.map((e) => `${e.id} - ${e.name}`),
	);
