import { useMemo } from "react";
import {
	type Material,
	type BufferGeometry,
	type Color,
	BatchedMesh,
	type Matrix4,
} from "three";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

type TBatchedRenderStore = {
	systems: Record<string, TBatchedRenderer>;
	addGeometry: (
		material: Material,
		geometry: BufferGeometry,
		opts?: TRenderBatchedOptions,
	) => TBatchedGeometryData | undefined;
	removeGeometry: (material: Material, geometry: BufferGeometry) => void;
	updateGeometry: (
		material: Material,
		geometry: BufferGeometry,
		geometryId: number,
	) => void;
	setGeometryMatrix: (
		material: Material,
		geometryId: number,
		matrix: Matrix4,
	) => void;
};

export const BatchedRenderStore = () => ({
	...useBatchedRenderStore.getState(),
	set: useBatchedRenderStore.setState,
});

export type TBatchedRenderer = {
	material: Material;
	batchedMesh: BatchedMesh;
	geometryData: Record<string, TBatchedGeometryData>;
};

export type TBatchedGeometryData = {
	geometryId: number;
	instanceId: number;
};

export type TRenderBatchedOptions = {
	matrix?: Matrix4;
	color?: Color;
	perObjectFrustumCulled?: boolean;
	frustumCulled?: boolean;
	allowShared?: boolean;
};
export const useBatchedRenderStore = create<TBatchedRenderStore>()(
	immer((set, get) => ({
		systems: {},
		addGeometry: (
			material: Material,
			geometry: BufferGeometry,
			opts?: TRenderBatchedOptions,
		) => {
			const id = geometry.uuid;
			// check if we have a system for this material
			if (!get().systems[material.uuid]) {
				// create a new system for this material
				const system = {
					material,
					batchedMesh: new BatchedMesh(1024 * 128, 500000, 1000000, material),
					geometryData: {},
				};
				system.batchedMesh.frustumCulled = opts?.frustumCulled || false;
				system.batchedMesh.perObjectFrustumCulled =
					opts?.perObjectFrustumCulled || true;
				set((state) => {
					state.systems[material.uuid] = system;
				});
			}
			if (get().systems[material.uuid].geometryData[id] && !opts?.allowShared) {
				console.warn(
					`Geometry already exists in system ${material.name}, ${material.uuid}`,
				);
				return;
			}
			const batchedMesh = get().systems[material.uuid].batchedMesh;
			const _geometryId = batchedMesh.addGeometry(geometry);
			const _instanceId = batchedMesh.addInstance(_geometryId);
			if (opts?.matrix) {
				batchedMesh.setMatrixAt(_instanceId, opts.matrix);
			}
			if (opts?.color) {
				batchedMesh.setColorAt(_instanceId, opts.color);
			}
			const batchedData = {
				geometryId: _geometryId,
				instanceId: _instanceId,
			};
			console.log(batchedData);
			set((state) => {
				state.systems[material.uuid].geometryData[id] = batchedData;
			});
			return batchedData;
		},
		removeGeometry: (material: Material, geometry: BufferGeometry) => {
			const id = geometry.uuid;
			console.log("remove");
			if (get().systems[material.uuid].geometryData[id]) {
				const system = get().systems[material.uuid];
				const data = system.geometryData[id];

				system.batchedMesh.deleteGeometry(data.geometryId);
				system.batchedMesh.deleteInstance(data.instanceId);
				//@dev this would be useful at some point- but dangerously, optimize repacks the indices, moving geometry to different instances. This would need to be wrapped in a way that keeps indices connected to the correct geometry
				// system.batchedMesh.optimize();
				set((state) => {
					delete state.systems[material.uuid].geometryData[id];
				});
			}
		},
		updateGeometry: (
			material: Material,
			geometry: BufferGeometry,
			geometryId: number,
		) => {
			const id = geometry.uuid;
			if (!get()?.systems[material.uuid]?.geometryData[geometry.uuid]) {
				console.warn(
					`Geometrydata not found in system ${material.name}, ${material.uuid}`,
				);
				return;
			}
			get().systems[material.uuid].batchedMesh.setGeometryAt(
				geometryId,
				geometry,
			);
			// console.log("yay", geometry.uuid);
		},
		setGeometryMatrix: (
			material: Material,
			geometryId: number,
			matrix: Matrix4,
		) => {
			if (!get().systems[material.uuid]) {
				console.warn(
					`Geometrydata not found in system ${material.name}, ${material.uuid}`,
				);
				return;
			}
			get().systems[material.uuid].batchedMesh.setMatrixAt(geometryId, matrix);
		},
	})),
);

export const BatchSystem = () => {
	const { systems } = useBatchedRenderStore();
	return useMemo(
		() => (
			<>
				{Object.values(systems).map((system) => {
					return (
						<primitive
							key={system.material.uuid}
							object={system.batchedMesh}
							castShadow
							receiveShadow
						/>
					);
				})}
			</>
		),
		[systems],
	);
};
