import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { UserStore } from "@/data/user-settings.store";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";

export class ItemTerminalPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem);
	}

	useTerminal() {
		UserStore().set({
			showTerminal: !UserStore().showTerminal,
		});
	}
}

const entityData: TEntityDefinition = {
	name: "Terminal",
	simEntityPrefab: ItemTerminalPrefab,
	id: 30301,
	metadata: {
		icon: "terminal.webp",
	},
};

registerEntity(entityData);
