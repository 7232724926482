import { getParticleData } from "@/data/particle.data";
import { createParticleEffect } from "@game/effects/particle.functions";
import { Vector3 } from "three";
import { JungleRenderer } from "./jungle.renderer";
import { CGrowable } from "@game/sim/components/CGrowable";
import { CRenderer } from "@game/sim/components/CRenderer";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import {
	registerBlueprint,
	type TBluePrint,
} from "@/data/blueprint/blueprint.data";
import Audio from "@game/audio/audioPlayer";
import { getMUDState, getSystemCalls, tables } from "@mud/index";
import { getBuildPosition } from "@game/sim/components/CConstruction.functions";
import type { Hex } from "viem";
import type { BufferedAction } from "@game/sim/actions/bufferedAction";
import { TheMouseData } from "@game/input/theInput";
import { CRummage } from "@game/sim/components/CRummage";
import { CBurnable } from "@game/sim/components/CBurnable";
import { t } from "i18next";

export class JunglePrefab extends SimEntityPrefab {
	growable: CGrowable | null = null;

	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CRenderer).setRenderer(JungleRenderer);
		e.addComponent(CRummage).setCanRummageFn(this.canRummageFn.bind(this));
		const growable = e.addComponent(CGrowable);
		this.growable = growable;
		const stages = [
			{ name: "empty", timing: 10, growFn: undefined },
			{ name: "grassy", timing: 20, growFn: undefined },
			{ name: "fresh jungle", timing: 30, growFn: undefined },
			{ name: "harvestable", timing: 40, growFn: undefined },
			{ name: "wild jungle", timing: 50, growFn: undefined },
		];
		growable.setStages(stages);
		this.inst.nameFn = this.getName.bind(this);
		this.inst.listenForEvent("rummageStart", this.onRummage.bind(this));
	}

	getName = () => {
		const burnable = getMUDState().getValue(tables.Burnable, {
			inst: this.inst.ref as Hex,
		});
		if (burnable) {
			if (burnable?.spreading) {
				return t("entity_state.inferno");
			}
			if (burnable.fuel <= 0) {
				return "Snags";
			}
		}
		const growthPercent = this.inst.component(CGrowable).growthPercent;
		if (growthPercent < 0.501) return "Grass";
		if (growthPercent < 0.751) return "Jungle";
		return "Wilderness";
	};

	canRummageFn = () => {
		if (
			this.inst.component(CGrowable).currentStage <= 1 &&
			!this.inst.component(CBurnable).isBurned()
		)
			return false;
		return (
			this.inst.component(CGrowable)?.currentStage > 1 ||
			this.inst.component(CBurnable)?.isBurned()
		);
	};

	onRummage = async () => {
		const particle = getParticleData("leaves");
		const position = new Vector3();
		position
			.copy(this.inst.component(CRenderer).block!.position!)
			.add(new Vector3(0, 0, 0));
		await createParticleEffect({
			particle,
			position,
			scale: new Vector3(0.25, 0.25, 0.25),
			lifetime: 3000,
		});
	};
}

const entityData: TEntityDefinition = {
	name: "Jungle",
	simEntityPrefab: JunglePrefab,
	id: 3,
};

const blueprint = {
	name: "Jungle",
	displayName: "Jungle",
	tools: [],
	ingredients: [{ amount: 1, name: "Mystery Seed" }],
	buildingSide: "TOP",
	requiresNeighbour: ["BOTTOM"],
	strFn: (_act: BufferedAction) => {
		return "Plant";
	},
	execute: async (
		doer: SimEntity,
		_recipe: TBluePrint,
		item: SimEntity,
		origin: SimEntity,
	) => {
		const { action_build } = getSystemCalls();
		origin.remove();
		Audio.playSound("PUT");
		const block = origin.component(CRenderer).block;
		if (!block) throw new Error("no block");
		const { coordinate, angle } = getBuildPosition(
			block,
			TheMouseData.getFaceIndex(),
		);
		await action_build(
			doer.ref as Hex,
			item.ref as Hex,
			coordinate,
			entityData.id,
			angle,
		);
	},
} as TBluePrint;

registerEntity(entityData);
registerBlueprint(blueprint);
