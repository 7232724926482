import { getParticleData } from "@/data/particle.data";
import Audio from "@game/audio/audioPlayer";
import { createParticleEffect } from "@game/effects/particle.functions";
import type { BufferedAction } from "@game/sim/actions/bufferedAction";
import { CBurnable } from "@game/sim/components/CBurnable";
import { CContainer } from "@game/sim/components/CContainer";
import { CFueled } from "@game/sim/components/CFueled";
import { CRenderer } from "@game/sim/components/CRenderer";
import { CRummage } from "@game/sim/components/CRummage";
import type { SimEntity } from "@game/sim/SimEntity";
import { UIStore } from "@game/ui/ui.store";
import { getSystemCalls } from "@mud/index";
import { Vector3 } from "three";

export const useShovelStrFn = (act: BufferedAction) => {
	if (act.doer.component(CContainer).isFull()) {
		return "Inventory full";
	}
	if (
		act.target?._entityTypeID === 2 &&
		act.target.component(CRummage) &&
		!act.doer.component(CContainer).isFull()
	) {
		return `Use ${act.invObject?.getName()}`;
	}
	if (
		(act.target?.component(CFueled)?.fuelType === "overgrowth" ||
			act.target?._entityTypeID === 3) &&
		!act.target.component(CBurnable)?.isBurned()
	) {
		return "Whack weeds";
	}
	if (
		act.target?.component(CBurnable)?.isBurned() &&
		!act.target?.component(CBurnable)?.isBurning()
	) {
		// for removing burnt junky junky
		return "Whack junk";
	}
	return "";
};

// Use check for using shovel on a rock to mine
export const canUseShovelFn = (_doer: SimEntity, target: SimEntity) => {
	// HACK very hacky
	if (target._entityTypeID === 2 && target.component(CRummage)) {
		// for rummaging sidewalls
		const side = UIStore().getBuildDirection();
		if (side === "TOP") return false;
		return true;
	}
	if (
		(target?.component(CFueled)?.fuelType === "overgrowth" ||
			target?._entityTypeID === 3) &&
		!target.component(CBurnable)?.isBurned()
	) {
		// for removing jungly jungly
		return true;
	}
	if (
		target.component(CBurnable)?.isBurned() &&
		!target.component(CBurnable)?.isBurning()
	) {
		// for removing burnt junky junky
		return true;
	}
	return false;
};

// Use function for using shovel on a rock to mine
export const useShovelFn = async ({
	doer,
	target,
	invObject,
}: BufferedAction) => {
	if (!doer || !target || !invObject) {
		throw new Error("No doer or target");
	}
	if (
		target.component(CFueled)?.fuelType === "overgrowth" ||
		target._entityTypeID === 3 ||
		(target.component(CBurnable)?.isBurned() &&
			!target.component(CBurnable)?.isBurning())
	) {
		const { action_useItemOnTarget } = getSystemCalls();
		Audio.playSound("LEAVES");
		const targetBlock = target.component(CRenderer).block;
		if (targetBlock) {
			const particle = getParticleData("leaves");
			const position = new Vector3();
			position.copy(targetBlock.position!).add(new Vector3(0, 0, 0));
			await createParticleEffect({
				particle,
				position,
				scale: new Vector3(0.25, 0.25, 0.25),
				lifetime: 3000,
			});
		}
		await action_useItemOnTarget(doer.ref, invObject.ref, target.ref);
		Audio.playSound("PICKUP_LEAVE", { broadcast: true });
		return;
	}
	if (doer.component(CContainer).isFull()) {
		Audio.playSound("PICKUP_LOCO");
		return;
	}
	if (
		target._entityTypeID === 2 &&
		target.component(CRummage) &&
		!doer.component(CContainer).isFull()
	) {
		await target.component(CRummage).rummage(doer, invObject);
		return;
	}
};
