import { type TSeededRand, seededRand } from "@/lib/random/seeded.functions";
import { Block } from "@game/world/block";
import { Euler, Matrix4, Vector2, Vector3, type Vector3Tuple } from "three";

export function generateCropPositions(
	numCrops: number,
	initialRadius: number,
	height: number,
): number[][] {
	const angleStep = Math.PI / 2;
	const positions: number[][] = [];
	let radius = initialRadius;

	for (let i = 0; i < numCrops; i++) {
		const angle = angleStep * i;
		const x = radius * Math.cos(angle);
		const z = radius * Math.sin(angle);
		positions.push([x, height, z]);
		if ((i + 1) % 4 === 0) {
			radius += initialRadius;
		}
	}
	return positions;
}

export function randomPointInQuad(
	points: Vector2[],
	rng: TSeededRand,
): Vector2 {
	const v1 = points[0];
	const v2 = points[1];
	const v3 = points[2];
	const v4 = points[3];
	// Helper function to calculate a random point in a triangle
	function randomPointInTriangle(a: Vector2, b: Vector2, c: Vector2): Vector2 {
		let r1 = rng(0, 10000) / 10000;
		let r2 = rng(0, 10000) / 10000;
		if (r1 + r2 > 1) {
			r1 = 1 - r1;
			r2 = 1 - r2;
		}
		const oneMinusR1R2 = 1 - r1 - r2;
		return new Vector2(
			a.x * r1 + b.x * r2 + c.x * oneMinusR1R2,
			a.y * r1 + b.y * r2 + c.y * oneMinusR1R2,
		);
	}
	if (rng(0, 10000) / 10000 < 0.5) {
		return randomPointInTriangle(v1, v2, v3);
	}
	return randomPointInTriangle(v1, v3, v4);
}

export function randomPos(block: Block, idx: number): Vector3 {
	const points = block._points.map((p) =>
		new Vector2(p.x, p.y).sub(block._quadCenter),
	);
	const rng = seededRand(Block.getSeed(block) * idx);
	const p = randomPointInQuad(points, rng);
	return new Vector3(p.x, 0, p.y);
}

export function createTransformationMatrix(
	position: Vector3 | Vector3Tuple,
	rotation: Vector3 | Vector3Tuple,
	scale: Vector3 | Vector3Tuple,
) {
	const matrix = new Matrix4();

	const pos = new Vector3(...position);
	const rot = new Euler(...(rotation || [0, 0, 0]));
	const scaleVec = new Vector3(...(scale || [1, 1, 1]));
	const translationMatrix = new Matrix4().makeTranslation(pos.x, pos.y, pos.z);
	const rotationMatrix = new Matrix4().makeRotationFromEuler(rot);
	const scaleMatrix = new Matrix4().makeScale(
		scaleVec.x,
		scaleVec.y,
		scaleVec.z,
	);

	// Combine the matrices (scale * rotation * translation)
	matrix.multiplyMatrices(translationMatrix, rotationMatrix);
	matrix.multiply(scaleMatrix);

	return matrix;
}
