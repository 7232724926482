import { getParticleData } from "@/data/particle.data";
import Audio from "@game/audio/audioPlayer";
import { createParticleEffect } from "@game/effects/particle.functions";
import { getMUDState, getSystemCalls, tables } from "@mud";
import { Vector3 } from "three";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import type { SimEntityPrefab } from "../SimEntityPrefab";
import type { TAction } from "../actions/actions.types";
import { getEntityByRef, getTaskByRef } from "../sim.store";
import { getPercentageComplete, type TTask } from "../task";
import type { TAsyncInstFn } from "../types/sim.types";
import { SimComponent } from "./SimComponent";
import { CContainer } from "./CContainer";
import { CInventoryItem } from "./CInventoryItem";
import { CRenderer } from "./CRenderer";
import { CBurnable } from "./CBurnable";

export class CCrop extends SimComponent {
	productPrefab: SimEntityPrefab | null = null;
	growthPercent = 0;
	rate = 1 / 1200;
	grower: SimEntity | null = null;
	task: TTask | undefined = undefined;
	onHarvestFn: TAsyncInstFn | null = null;

	value = () =>
		getMUDState().getValue(tables.Crop, {
			inst: this.inst.ref as Hex,
		})!;

	async loadFromChain(): Promise<void> {
		if (!this.value()) {
			console.warn(`no value for ${this.constructor.name}`, this.inst.ref);
			this.inst.clearComponent(CCrop);
			return;
		}
		this.rate = this.value().growRate;
		this.grower = getEntityByRef(this.value().growerKey) || null;
		this.task = getTaskByRef(this.value().taskKey);
		await this.checkGrowth();
	}

	setOnHarvestFn(fn: TAsyncInstFn) {
		this.onHarvestFn = fn;
		return this;
	}

	getDebugString() {
		let s = `[${this.productPrefab}]`;
		if (this.isReadyForHarvest()) {
			s += " DONE";
		} else {
			s += ` ${this.growthPercent}% (done in ${
				(1 - this.growthPercent) / this.rate
			})`;
		}
		return s;
	}

	async harvest(harvester: SimEntity) {
		const { action_harvest } = getSystemCalls();
		if (this.onHarvestFn) {
			return this.onHarvestFn(harvester);
		}
		try {
			createParticleEffect({
				particle: getParticleData("pickup"),
				position: this.grower
					?.component(CRenderer)
					?.block!.position!.clone()
					.add(new Vector3(0, -0.25, 0)),
				scale: new Vector3(0.25, 0.25, 0.25),
			});
			Audio.playSound("PICKUP");
			await action_harvest(harvester.ref, this.inst.ref);
			Audio.playSound("LEAVES", { broadcast: true });
			return true;
		} catch (e) {
			console.warn(e);
		}
		return false;
	}

	isReadyForHarvest() {
		return this.growthPercent >= 1;
	}

	collectWorldActions(doer: SimEntity, actions: TAction[]) {
		if (
			this.isReadyForHarvest() &&
			!this.inst.component(CInventoryItem)?.isHeld() &&
			!doer.component(CContainer).isFull() &&
			!this.inst.component(CBurnable)?.isBurned()
		) {
			actions.push("HARVEST");
		}
	}

	async checkGrowth() {
		if (!this.task) return;
		const task = this.task;
		const value = Math.max(0, Math.min(100, getPercentageComplete(task)));
		this.growthPercent = Number(value) / 100;
	}
}
