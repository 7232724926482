import {
	type TBluePrint,
	type TConstraint,
	getBlueprintsByNames,
} from "@/data/blueprint/blueprint.data";
import { UIStore } from "@game/ui/ui.store";
import type { SimEntity } from "../SimEntity";
import { SimComponent } from "./SimComponent";
import { CRenderer } from "./CRenderer";
import { getBuildPosition } from "./CConstruction.functions";
import { isPositionEmpty } from "@game/world/block.store";
import { TheMouseData } from "@game/input/theInput";
import { CBurnable } from "./CBurnable";

export class CConstruction extends SimComponent {
	recipes: TBluePrint[] = [];
	contents: SimEntity[] = [];
	buildDirections: { [key in TConstraint]: boolean } = {
		TOP: true,
		SIDES: true,
		BOTTOM: true,
	};

	isDirectionBuildable(direction: TConstraint) {
		return this.buildDirections[direction];
	}

	private isIngredientInRecipes(ingredient: SimEntity) {
		return this.recipes.find(
			(recipe) =>
				(recipe.ingredients?.some((i) => i.name === ingredient.name) ||
					recipe.tools?.some((i) => i.name === ingredient.name)) &&
				(!recipe.upgradesFrom || recipe.upgradesFrom === this.inst.name),
		);
	}

	canBuildInDirection(recipe: TBluePrint) {
		const side = UIStore().getBuildDirection();
		if (side === undefined) return false;
		if (!side) return false;
		if (recipe.buildingSide && recipe.buildingSide !== side) return false;
		if (!this.isDirectionBuildable(side)) return false;
		return true;
	}

	getRecipeByIngredient(ingredient: SimEntity): TBluePrint | undefined {
		const recipe = this.isIngredientInRecipes(ingredient);
		if (recipe && this.canBuildInDirection(recipe)) return recipe;
		return undefined;
	}

	setRecipes(...bluePrintNames: string[]) {
		this.recipes = getBlueprintsByNames(...bluePrintNames);
		return this;
	}

	setBuildDirections(directions: Partial<{ [key in TConstraint]: boolean }>) {
		this.buildDirections = { ...this.buildDirections, ...directions };
		return this;
	}

	isDirectionEmpty() {
		const faceIndex = TheMouseData.faceIndex;
		if (faceIndex === undefined) return false;
		const block = this.inst.component(CRenderer).block;
		if (!block) return false;
		const { coordinate } = getBuildPosition(block, faceIndex);
		if (isPositionEmpty(coordinate)) return true;
		return false;
	}

	canBuildItem(doer: SimEntity, item: SimEntity) {
		const recipe = this.getRecipeByIngredient(item);
		if (!recipe) return false;
		if (
			this.inst.component(CBurnable)?.isBurned() ||
			this.inst.component(CBurnable)?.isBurning()
		)
			return false;
		if (recipe.canBuildFn) {
			return recipe.canBuildFn?.(doer, recipe, item, this.inst);
		}
		return this.canBuildInDirection(recipe);
	}

	async buildRecipe(doer: SimEntity, recipe: TBluePrint, item: SimEntity) {
		recipe.execute(doer, recipe, item, this.inst);
		recipe.onBuildFn?.();
	}
}
