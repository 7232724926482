import {
	Vector3,
	type BufferGeometry,
	type Mesh,
	type NormalBufferAttributes,
} from "three";
import {
	getOrientationAngle,
	interpolateBoxPoints,
} from "./meshDeformation.fn";
import type { Block } from "@game/world/block";

const s = 1.25;

export const basicBox: Vector3[] = [
	new Vector3(-1, 1, 1).multiplyScalar(s),
	new Vector3(-1, 1, -1).multiplyScalar(s),
	new Vector3(1, 1, -1).multiplyScalar(s),
	new Vector3(1, 1, 1).multiplyScalar(s),
	new Vector3(-1, -1, 1).multiplyScalar(s),
	new Vector3(-1, -1, -1).multiplyScalar(s),
	new Vector3(1, -1, -1).multiplyScalar(s),
	new Vector3(1, -1, 1).multiplyScalar(s),
];

export const angles = [0, 90, 180, -90];

const interpolatedBasicBox = interpolateBoxPoints(basicBox, 5);

export type TDeformedGeometry = {
	geometry: BufferGeometry<NormalBufferAttributes>;
};

export const deformMesh = (
	mesh: Mesh,
	block: Block,
	direction = 0,
): TDeformedGeometry => {
	const geometry = mesh.geometry.clone();
	let orientation = direction;
	if (orientation !== undefined) {
		orientation = Math.max(0, orientation % 4);
	}
	const randomAngle = Math.floor(Math.random() * angles.length);
	angles[orientation !== undefined ? 4 - orientation : randomAngle];
	if (orientation) {
		geometry.rotateY(getOrientationAngle(orientation));
	}

	const positions = geometry.attributes.position;
	const blockPoints = block._points.map((v) => {
		const p = v.clone().sub(block._quadCenter);
		return new Vector3(p.x, 0.25, p.y);
	});

	for (let i = 0; i < 4; i++) {
		blockPoints.push(blockPoints[i].clone().setY(-0.25));
	}
	const deformationModel: Vector3[] = interpolateBoxPoints(blockPoints, 5);

	const vertex = new Vector3();
	for (let i = 0; i < positions.count; i++) {
		vertex.fromBufferAttribute(positions, i);

		const weights: number[] = [];
		for (let j = 0; j < interpolatedBasicBox.length; j++) {
			const dist = vertex.distanceTo(
				interpolatedBasicBox[j].clone().setY(vertex.y),
			);
			const force = 1 / (1 + dist);
			const w = (-dist * force) / 1;
			weights[j] = w;
		}
		const force = deformationModel.reduce((acc, curr, idx) => {
			return acc.add(curr.clone().multiplyScalar(weights[idx]));
		}, new Vector3());

		positions.setXYZ(
			i,
			(force.x / 5) * 1.125,
			vertex.y * 0.25,
			(force.z / 5) * 1.125,
		);
	}

	positions.needsUpdate = true;
	geometry.computeBoundingBox();
	geometry.computeVertexNormals();
	return {
		geometry,
	};
};
