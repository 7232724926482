import { getMesh, getTexture } from "@/data/assetLoader/asset.store";
import { randomFromArray, seededRand } from "@/lib/random/seeded.functions";
import { Interactable } from "@game/input/interactable";
import { CCrop } from "@game/sim/components/CCrop";
import { CGrowable } from "@game/sim/components/CGrowable";
import type { TRendererProps } from "@game/sim/components/CRenderer";
import useEntityUpdate from "@game/sim/useEntityUpdate";
import { getMUDState, tables } from "@mud/index";
import { useMemo, useRef } from "react";
import {
	DoubleSide,
	type Mesh,
	MeshStandardMaterial,
	type Vector3Tuple,
} from "three";
import type { Hex } from "viem";

const meshes = [
	"farmplants/farmplants/bamboo000",
	"farmplants/farmplants/bamboo001",
	"farmplants/farmplants/bamboo002",
	"farmplants/farmplants/bamboo003",
];

export const ItemBambooRenderer = ({
	entity,
	pos,
	rot,
}: TRendererProps<{ pos: Vector3Tuple; rot: number }>) => {
	const growthPercent = useRef(0);
	const meshRef1 = useRef<Mesh>(null!);
	const meshRef2 = useRef<Mesh>(null!);
	const scale = useRef<number[]>([0, 0, 0]);
	const grown = useRef(false);

	const { meshName, rotX } = useMemo(() => {
		const rng = seededRand(entity._seed);
		const meshName = randomFromArray(meshes, rng);
		const rotX = rng(-0.2, 0.2) * Math.PI * 0.1;
		return {
			meshName,
			rotX,
		};
	}, [entity]);

	useEntityUpdate(() => {
		const crop = entity.component(CCrop);
		crop?.checkGrowth();
		const growable = entity.component(CGrowable);
		if (!crop && !growable) {
			console.error("no crop or growable", entity.ref);
			return;
		}
		const progress = growable ? growable.growthPercent : crop.growthPercent;
		// if (crop.growthPercent === growthPercent.current) return;
		growthPercent.current = progress;
		const s = Math.max(0.1, progress) * 0.945 || 0;
		scale.current = [s, s, s];
		grown.current = progress >= 100;
		meshRef1.current?.scale.set(s, s, s);
		meshRef2.current?.scale.set(s, s, s);
	}, entity);

	const { bamboo, mat1, mat2 } = useMemo(() => {
		const mesh = getMesh(meshName);
		const mat1 = new MeshStandardMaterial({
			map: getTexture("farmplants/bamboo/color"),
			normalMap: getTexture("farmplants/bamboo/normal"),
		});
		const mat2 = new MeshStandardMaterial({
			map: getTexture("farmplants/bamboo/leaves_color"),
			normalMap: getTexture("farmplants/bamboo/leaves_normal"),
		});
		return {
			bamboo: mesh,
			mat1,
			mat2,
		};
	}, [meshName]);

	const burnable = getMUDState().getRecord(tables.Burnable, {
		inst: entity.ref as Hex,
	});

	const color = useMemo(() => {
		const color = (() => {
			if (!burnable?.value) return "white";
			if (burnable?.value.spreading) return "red";
			if (burnable?.value.fuel <= 0) return "black";
			return "white";
		})();
		return color;
	}, [burnable?.value]);

	if (!bamboo) return null;
	return useMemo(() => {
		return (
			<group
				position={pos}
				scale={[0.35, 0.35, 0.35]}
				rotation={[rotX, rot, 0]}
			>
				<Interactable
					entity={entity}
					args={[0.25, 1, 0.25]}
					position={[0, 0.45, 0]}
				/>
				<mesh
					geometry={(bamboo.children[0] as Mesh).geometry}
					ref={meshRef1}
					scale={scale?.current as Vector3Tuple}
					castShadow={true}
				>
					<meshStandardMaterial
						// attach={"material-1"}
						map={mat2.map}
						transparent
						side={DoubleSide}
						alphaTest={0.1}
						color={color}
					/>
				</mesh>
				<mesh
					geometry={(bamboo.children[1] as Mesh).geometry}
					ref={meshRef2}
					scale={scale?.current as Vector3Tuple}
				>
					<meshStandardMaterial
						// attach={"material-1"}
						map={mat1.map}
						color={color}
					/>
				</mesh>
				{/* {grown && (
          <Sparkles
            size={0.75}
            speed={0.2}
            count={5}
            noise={50}
            color={"cyan"}
            scale={0.25}
            position={[0, 0.125, 0]}
          />
        )} */}
			</group>
		);
	}, [pos, rot, bamboo, mat2, mat1, entity, rotX, color]);
};
