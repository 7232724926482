import { CRenderer } from "@game/sim/components/CRenderer";
import type { SimEntity } from "@game/sim/SimEntity";
import { useMemo } from "react";
import { seededRand, type TSeededRand } from "./random/seeded.functions";
import type { Vector3Tuple } from "three";
import type { Block } from "@game/world/block";

type TBlockProps = {
	block: Block;
	rng: TSeededRand;
	pos: Vector3;
	rot: number;
};

export const useBlock = ({ entity }: { entity: SimEntity }): TBlockProps => {
	const { block, rng, pos, rot } = useMemo(() => {
		const block = entity.component(CRenderer).block;
		if (!block) {
			throw new Error("no block");
		}
		const rng = seededRand(entity._seed);
		if (!rng) {
			throw new Error("no rng");
		}
		const pos = block.position.clone();
		const rot = block.rotation;
		return { block, rng, pos, rot };
	}, [entity]);

	return { block, rng, pos, rot };
};
