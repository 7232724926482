import { getMUDState, tables } from "@mud";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import type { TAsyncInstFn } from "../types/sim.types";
import { SimComponent } from "./SimComponent";
import { clamp01, f } from "@/lib/utils";

type TGrowStage = {
	name?: string;
	timing: number;
	growFn?: TAsyncInstFn;
};

export class CGrowable extends SimComponent {
	stages: TGrowStage[] = [{ timing: 0 }]; // stage timings/rates
	_canGrow = false;
	currentStage = 0;
	currentTime = 0;
	growthPercent = 0;
	totalTime = 0;

	constructor(inst: SimEntity) {
		super(inst);
		this.loadFromChain();
	}

	setStages(stages: TGrowStage[]) {
		this.stages = stages;
		return this;
	}

	async loadFromChain(): Promise<void> {
		// FIXME: based on broken growable component
		const growable = getMUDState().getValue(tables.Growable, {
			inst: this.inst.ref as Hex,
		});
		if (!growable) {
			console.error("no growable for container", this.inst.ref);
			return;
		}
		for (let i = 0; i < growable.stageTimes.length; i++) {
			if (this.stages[i]) {
				this.stages[i].timing = growable.stageTimes[i];
			} else {
				this.stages[i] = { timing: growable.stageTimes[i] };
			}
		}
		this._canGrow = growable.canGrow;
		this.totalTime = f(growable.totalTime);
		this.currentTime = f(growable.currentTime);
		this.growthPercent = Math.min(
			1,
			Math.max(0.0001, f(growable.currentTime) / f(growable.totalTime)),
		);
		let sum = 0;
		this.currentStage = growable.stageTimes.length - 1;
		for (let i = 0; i < growable.stageTimes.length; i++) {
			sum += growable.stageTimes[i];
			if (this.currentTime <= sum) {
				this.currentStage = i;
				break;
			}
		}
		if (growable.stageTimes.length === 1) {
			this.growthPercent = clamp01(this.currentTime / this.totalTime) || 0.0001;
		} else {
			this.growthPercent =
				clamp01(this.currentStage / growable.stageTimes.length) || 0.0001;
		}
	}

	canGrow(): boolean {
		return this._canGrow && !this.hasFinishedGrowing();
	}

	hasFinishedGrowing(): boolean {
		return this.currentStage >= this.stages.length;
	}

	isEmpty(): boolean {
		return this.stages.length === 0;
	}

	getCurrentStage(): TGrowStage {
		return this.stages[this.currentStage];
	}

	getDebugString(): string {
		const stage = this.getCurrentStage();
		return stage
			? `Growable: ${stage.name} (${stage.timing}/[${this.currentTime}])`
			: `Growable: empty || ERROR || [${this.currentTime}]`;
	}
}
