export const ASSET_PATHS_MODELS: string[] = [
	"/assets/farmplants/farmplants.glb", // farm plants (carrots, etc)
	"/assets/jungleplants/jungleplants.glb", // Contains jungle plants
	"/assets/rocks/rocks.glb", // Rocks
	"/assets/herring/herring.glb", // actual fucking fish, not a red herring
	"/assets/modular_set/modular_set.glb", // new modular set
];

export const ASSET_PATHS_TEXTURES: string[] = [
	"/assets/noise.png",
	"/assets/ui/dev_measure.png",
	"/assets/ui/selection.png",
	"/assets/jungleplants/plantas_dif_007_alpha.png",
	"/assets/farmplants/bamboo/color.png",
	"/assets/farmplants/bamboo/normal.png",
	"/assets/farmplants/bamboo/leaves_color.png",
	"/assets/farmplants/bamboo/leaves_normal.png",
	"/assets/clouds/clouds00.png",
	"/assets/clouds/clouds01.png",
	"/assets/clouds/clouds03.png",
	"/assets/farmplants/wheat/wheat.png",
	"/assets/modular_set/GradientTexture_m_512.png",
	"/assets/modular_set/GradientTexture_mask_512.png",
	// "/assets/fire/test_burning.png",
	// "/assets/fire/FireNoise_T.webp",
	"/assets/fire/FireNoise_A.webp",
];

export const ASSET_PRELOAD_IMAGES: string[] = ["/icons/heart.webp"];

export const ASSET_TOTAL =
	ASSET_PATHS_MODELS.length + ASSET_PATHS_TEXTURES.length;
