import { createGrid } from "@game/world/world.functions";
import type { THexCoordinate } from "@game/world/world.types";
import type { Hexagrid } from "./hexagrid";
import type { Quad } from "./hexagrid.types";

type HexNeighbour = { quad: Quad; quadIndex: number; hex: THexCoordinate };

const gridRefs = new Map<string, Hexagrid>();
const getGrid = (coordinate: THexCoordinate) => {
	let grid = gridRefs.get(coordinate.toString());
	if (!grid) {
		grid = createGrid(coordinate);
		gridRefs.set(coordinate.toString(), grid);
		console.log("created Hex:", coordinate.toString());
		return grid;
	}
	return grid;
};

export const getAllNeighbours = (hex: THexCoordinate) => {
	const neighbours = getSameHexNeighbours(hex);
	getHexNeighbours(neighbours);
	return neighbours;
};

export const getSameHexNeighbours = (
	hex: THexCoordinate,
	quadHexNeighbours: Record<number, HexNeighbour[]> = {},
) => {
	const hexZero = getGrid(hex);
	hexZero.subdQuads.forEach((q) => {
		q.neighbours.forEach((neighbour) => {
			const newRecord = {
				hex,
				quad: neighbour,
				quadIndex: hexZero.getQuadIndex(neighbour),
			};
			if (quadHexNeighbours[hexZero.getQuadIndex(q)]) {
				quadHexNeighbours[hexZero.getQuadIndex(q)].push(newRecord);
			} else {
				quadHexNeighbours[hexZero.getQuadIndex(q)] = [newRecord];
			}
		});
	});
	return quadHexNeighbours;
};

export const getHexNeighbours = (
	quadHexNeighbours: Record<number, HexNeighbour[]> = {},
): Record<number, HexNeighbour[]> => {
	const nonZeroCoords = [
		// [0, 0] as THexCoordinate,
		[0, 1] as THexCoordinate,
		[0, -1] as THexCoordinate,
		[1, 0] as THexCoordinate,
		[-1, 0] as THexCoordinate,
		[-1, 1] as THexCoordinate,
		[1, -1] as THexCoordinate,
	];

	const hexZero = getGrid([0, 0]);
	// Filter to quads that border the side of the hex
	const hexZeroSideQuads = hexZero.subdQuads.filter((q) => q.side);

	const hexZeroNeighbours: HexNeighbour[] = [];

	// Find the hex neighbours for each side quad
	hexZeroSideQuads.forEach((zeroSideQuad) => {
		const quadNeighbours: HexNeighbour[] = [];
		const zeroSideQuadPoints = hexZero.exportQuad(zeroSideQuad);

		// For each bordering grid check if a neighbour exists
		nonZeroCoords.forEach((coord) => {
			const hexNonZero = getGrid(coord);
			const hexNonZeroSideQuads = hexNonZero.subdQuads.filter((q) => q.side);

			// check each side quad of current grid
			hexNonZeroSideQuads.forEach((hexNonZeroSideQuad) => {
				let similar = 0;
				const sideQuadPoints = hexNonZero.exportQuad(hexNonZeroSideQuad);

				// Check all points for at least 2 matches
				for (let j = 0; j < zeroSideQuadPoints.length; j++) {
					for (let k = 0; k < sideQuadPoints.length; k++) {
						// Note - have to use almostZero as not exact match
						if (
							almostZero(zeroSideQuadPoints[j].x, sideQuadPoints[k].x) &&
							almostZero(zeroSideQuadPoints[j].y, sideQuadPoints[k].y)
						) {
							similar++;
						}
						if (similar === 2) {
							if (
								!quadNeighbours.find(
									(q) => q.hex === coord && q.quad === hexNonZeroSideQuad,
								)
							)
								quadNeighbours.push({
									quad: hexNonZeroSideQuad,
									hex: coord,
									quadIndex: hexNonZero.getQuadIndex(hexNonZeroSideQuad),
								});
							break;
						}
					}
				}
			});
		});
		if (quadNeighbours.length < 1)
			throw new Error(
				`Quad Missing Neighbour: ${hexZero.getQuadIndex(zeroSideQuad)}`,
			);
		if (quadNeighbours.length > 2)
			throw new Error(
				`Quad Missing Neighbour: ${hexZero.getQuadIndex(zeroSideQuad)}`,
			);

		quadNeighbours.forEach((n) => hexZeroNeighbours.push(n));
		// quadHexNeighbours[hexZero.getQuadIndex(zeroSideQuad)] = quadNeighbours;
		if (quadHexNeighbours[hexZero.getQuadIndex(zeroSideQuad)]) {
			quadNeighbours.forEach((n) =>
				quadHexNeighbours[hexZero.getQuadIndex(zeroSideQuad)].push(n),
			);
		} else {
			quadHexNeighbours[hexZero.getQuadIndex(zeroSideQuad)] = quadNeighbours;
		}
	});
	return quadHexNeighbours;
};

function almostZero(x: number, y: number): boolean {
	return Math.abs(x - y) < 0.1;
}
