import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CGrower } from "@game/sim/components/CGrower";
import type { CPlot } from "@game/sim/components/CPlot";
import { CRenderer } from "@game/sim/components/CRenderer";
import { CDecorator } from "@game/sim/components/CDecorator";
import { CUpdate } from "@game/sim/components/CUpdate";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { FarmRenderer } from "./farm.renderer";
import {
	type TBluePrint,
	registerBlueprint,
} from "@/data/blueprint/blueprint.data";
import { getBuildPosition } from "@game/sim/components/CConstruction.functions";
import { getMUDState, getSystemCalls, tables } from "@mud/index";
import type { Hex } from "viem";
import Audio from "@game/audio/audioPlayer";
import { CConstruction } from "@game/sim/components/CConstruction";
import { TheMouseData } from "@game/input/theInput";
import { t } from "i18next";

export class FarmPrefab extends SimEntityPrefab {
	grower: CGrower | null = null;
	plot: CPlot | null = null;

	constructor(e: SimEntity) {
		super(e);
		this.grower = e.addComponent(CGrower);
		e.addComponent(CRenderer).setRenderer(FarmRenderer);
		e.addComponent(CUpdate).setUpdateFn(this.update.bind(this));
		this.inst.nameFn = this.getName.bind(this);
	}

	update = () => {
		this.grower?.loadFromChain();
		this.plot?.loadFromChain();
	};

	getName = () => {
		const burnable = getMUDState().getValue(tables.Burnable, {
			inst: this.inst.ref as Hex,
		});
		if (burnable) {
			if (burnable?.spreading) {
				return t("entity_state.inferno");
			}
			if (burnable.fuel <= 0) {
				return t("entity_state.debris");
			}
		}
		return this.inst.name;
	};
}

const entityData: TEntityDefinition = {
	name: "Farm",
	simEntityPrefab: FarmPrefab,
	id: 501,
};

const blueprint = {
	name: "Farm",
	tools: [{ name: "Shovel" }, { name: "Golden Shovel" }],
	ingredients: [],
	buildingSide: "TOP",
	requiresNeighbour: ["BOTTOM"],
	canBuildFn: (
		_doer: SimEntity,
		recipe: TBluePrint,
		_item: SimEntity,
		origin: SimEntity,
	) => {
		const construction = origin.component(CConstruction);
		if (origin.component(CDecorator)) {
			// check if top then we need decorator at 4
			if (
				recipe.buildingSide === "TOP" &&
				!origin.component(CDecorator).hasTopWall()
			)
				return false;
			if (
				recipe.buildingSide === "BOTTOM" &&
				!origin.component(CDecorator).hasBottomWall()
			)
				return false;
		}
		return (
			construction.isDirectionEmpty() &&
			construction.canBuildInDirection(recipe)
		);
	},
	execute: async (
		doer: SimEntity,
		_recipe: TBluePrint,
		item: SimEntity,
		origin: SimEntity,
	) => {
		const { action_build } = getSystemCalls();
		origin.remove();
		Audio.playSound("PUT", { broadcast: true });
		const faceIndex = TheMouseData.getFaceIndex();
		const block = origin.component(CRenderer).block;
		if (!block) throw new Error("no block");
		const { coordinate, angle } = getBuildPosition(block, faceIndex);
		await action_build(
			doer.ref as Hex,
			item.ref as Hex,
			coordinate,
			entityData.id,
			angle,
		);
	},
} as TBluePrint;

registerEntity(entityData);
registerBlueprint(blueprint);
