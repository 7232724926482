import { getMesh } from "@/data/assetLoader/asset.store";
import { Interactable } from "@game/input/interactable";
import { CCrop } from "@game/sim/components/CCrop";
import { CGrowable } from "@game/sim/components/CGrowable";
import type { TRendererProps } from "@game/sim/components/CRenderer";
import useEntityUpdate from "@game/sim/useEntityUpdate";
import { Sparkles } from "@react-three/drei";
import { useMemo, useState } from "react";
import {
	Color,
	DoubleSide,
	type Mesh,
	MeshBasicMaterial,
	type MeshStandardMaterial,
	Vector3,
	type Vector3Tuple,
} from "three";

const meshNames = ["farmplants/farmplants/berries000"];

export const ItemBerriesRenderer = ({
	entity,
	pos,
	rot,
}: TRendererProps<{ pos: Vector3Tuple; rot: number }>) => {
	const [growthPercent, setGrowthPercent] = useState(0);

	useEntityUpdate(() => {
		const crop = entity.component(CCrop) || entity.component(CGrowable);
		if (crop.growthPercent !== growthPercent) {
			setGrowthPercent(crop.growthPercent);
		}
	}, entity);

	const meshes = useMemo(() => {
		const meshes: Mesh[] = [];
		for (let i = 0; i < meshNames.length; i++) {
			meshes.push(getMesh(meshNames[i]));
		}
		return meshes;
	}, []);

	const { model, material } = useMemo(() => {
		const geometry =
			meshes[Math.max(0, Math.floor(growthPercent * (meshes.length - 1)))]!;
		if (!geometry) return {};
		const geoMat = geometry.material as MeshStandardMaterial;
		const material = new MeshBasicMaterial({
			map: geoMat.map,
			transparent: true,
			opacity: 1,
			color: new Color(0xffffff),
			side: DoubleSide,
		});

		return {
			model: geometry,
			material,
		};
	}, [growthPercent, meshes]);

	const scale = 0.045;
	const grown = growthPercent >= 0.95;

	return useMemo(() => {
		if (!model) return null;
		return (
			<group position={new Vector3(...pos)}>
				<Interactable
					entity={entity}
					position={[0, 0.125, 0]}
					args={[0.15, 0.15, 0.15]}
				/>
				<mesh
					position={[0, 0.0155, 0]}
					scale={[scale, scale, scale]}
					rotation={[0, rot, 0]}
					geometry={model.geometry}
					material={material}
				>
					{grown && (
						<Sparkles
							size={0.75}
							speed={0.2}
							count={5}
							noise={50}
							color={"yellow"}
						/>
					)}
				</mesh>
			</group>
		);
	}, [pos, rot, grown, model, entity, material]);
};
