import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { InventoryStore } from "@game/ui/components/inventory/inventory.store";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CUpdate } from "@game/sim/components/CUpdate";
import { WorldGlobals } from "@game/sim/sim.store";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { WorldStore } from "@game/world/world.store";

export class ItemDirtyGlasses extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem)
			.setSigil("shovel")
			.setOnPickupFn(this.onPickupFn.bind(this))
			.setOnDroppedFn(this.onDroppedFn.bind(this));
		e.addComponent(CUpdate).setUpdateFn(this.update.bind(this));
	}

	onPickupFn = (_inst: SimEntity) => {
		if (
			this.inst.component(CInventoryItem).owner?.ref ===
			WorldStore().getPlayer()?.ref
		) {
			WorldGlobals().set({ blurredVision: true });
		}
	};

	onDroppedFn = (_inst: SimEntity) => {
		WorldGlobals().set({ blurredVision: false });
	};

	update = () => {
		if (InventoryStore().getActiveItem() === this.inst) {
			if (WorldGlobals().blurredVision) {
				WorldGlobals().set({ blurredVision: false });
			}
		} else if (
			this.inst.component(CInventoryItem).owner?.ref ===
			WorldStore().getPlayer()?.ref
		) {
			if (!WorldGlobals().blurredVision) {
				console.warn("pinked");
				WorldGlobals().set({ blurredVision: true });
			}
		}
	};
}

const entityData: TEntityDefinition = {
	name: "Dirty Glasses",
	simEntityPrefab: ItemDirtyGlasses,
	id: 50002,
	metadata: {
		icon: "dirty_glasses.webp",
	},
} as const;

registerEntity(entityData);
