import Audio from "@game/audio/audioPlayer";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CUsable } from "@game/sim/components/CUsable";
// import { WorldGlobals } from "@game/sim/sim.store";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { getSystemCalls } from "@mud/index";
import type { BufferedAction } from "@game/sim/actions/bufferedAction";

export class ItemStormBottle extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem).setSigil("shovel");
		e.addComponent(CUsable)
			.setCanUseFn(this.canUseFn.bind(this))
			.setUseFn(this.useFn.bind(this));
	}

	canUseFn = (_doer: SimEntity, target: SimEntity) => {
		if (target === this.inst) {
			return true;
		}
		return false;
	};

	useFn = async ({ doer }: BufferedAction) => {
		const { action_useItem } = getSystemCalls();
		Audio.playSound("STORM", { volume: 3, broadcast: true });
		await action_useItem(doer.ref, this.inst.ref);
	};
}

const entityData: TEntityDefinition = {
	name: "Storm Bottle",
	simEntityPrefab: ItemStormBottle,
	id: 50003,
	metadata: {
		icon: "storm_bottle.webp",
	},
} as const;

registerEntity(entityData);
