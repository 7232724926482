import { ItemShovelRenderer } from "./shovel.renderer";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CBuildable } from "@game/sim/components/CBuildable";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CRenderer } from "@game/sim/components/CRenderer";
import { CUsable } from "@game/sim/components/CUsable";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { canUseShovelFn, useShovelFn, useShovelStrFn } from "./shovel.fn";

export class ItemGoldenShovelPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem).setSigil("shovel");
		e.addComponent(CBuildable);
		e.addComponent(CRenderer).setRenderer(ItemShovelRenderer);
		e.addComponent(CUsable)
			.setCanUseFn(canUseShovelFn)
			.setUseFn(useShovelFn)
			.setUseStrFn(useShovelStrFn);
	}
}

const entityData: TEntityDefinition = {
	name: "Golden Shovel",
	simEntityPrefab: ItemGoldenShovelPrefab,
	id: 50001,
	metadata: {
		icon: "golden_shovel_2.webp",
	},
} as const;

registerEntity(entityData);
