import type { BoxGeometryProps, SphereGeometryProps } from "@react-three/fiber";
import { useMemo } from "react";
import {
	Color,
	type BufferGeometry,
	type Vector3,
	type Vector3Tuple,
	type Euler,
} from "three";
import type { SimEntity } from "../sim/SimEntity";

type TEntityInteractionProps = {
	entity: SimEntity | null;
	args?: BoxGeometryProps["args"] | SphereGeometryProps["args"];
	position?: Vector3 | Vector3Tuple;
	isInteractable?: boolean;
	collider?: "Box" | "Sphere";
	debug?: boolean | string;
	geometry?: BufferGeometry | undefined;
	rotation?: Euler | undefined;
	scale?: Vector3 | Vector3Tuple | undefined;
};

const defaultProps: TEntityInteractionProps = {
	entity: null,
	position: [0, 0, 0],
	isInteractable: true,
	collider: "Box",
	debug: false,
};

export const Interactable = (props: TEntityInteractionProps) => {
	const { entity } = props;

	const interactable = useMemo(() => {
		if (!entity) return null;
		const {
			args,
			position,
			isInteractable,
			collider,
			debug,
			geometry,
			rotation,
			scale,
		} = {
			...defaultProps,
			...props,
		};
		const color =
			typeof isInteractable === "string"
				? new Color(isInteractable)
				: new Color("green");

		return (
			<mesh
				position={position}
				userData={isInteractable ? { entityRef: entity.ref } : undefined}
				visible={debug !== undefined && debug !== false}
				geometry={geometry}
				scale={scale || undefined}
				rotation={rotation || undefined}
			>
				{!geometry && collider === "Box" && (
					<boxGeometry
						attach={"geometry"}
						args={(args as BoxGeometryProps["args"]) || [0.3, 0.75, 0.3]}
						// scale={scale}
					/>
				)}
				{!geometry && collider === "Sphere" && (
					<sphereGeometry
						attach={"geometry"}
						args={(args as SphereGeometryProps["args"]) || [0.1, 32, 16]}
						// scale={scale}
					/>
				)}
				<meshStandardMaterial color={color} />
			</mesh>
		);
	}, [entity, props]);
	return <>{interactable}</>;
};
