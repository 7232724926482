import { ENV } from "@/lib/env";
import { ASSET_PATHS_MODELS } from "./assetLoader/asset.data";

export const APP_DATA = {
	displayName: "Cloudlines",
	url: "https://game.cloudlines.xyz",
	description:
		"Welcome to an idyllic, chill and romantic multiplayer game about developing your floating island from shanty to city; a persistent collaborative world of townscaping",
	keywords: [
		"multiplayer",
		"citybuilder",
		"game",
		"web3",
		"collaboration",
		"romantic",
		"regenerative",
		"jungle",
		"mangrove",
	],
	ogImage: "https://game.cloudlines.xyz/og-image.jpg",
	social: {
		twitter: {
			name: "@CloudlinesGame",
			url: "https://twitter.com/CloudlinesGame",
		},
		discord: {
			name: "Cloudlines",
			url: "https://discord.gg/7G4NnkaGsA",
		},
		github: {
			name: "Cloudlines",
			url: "https://www.github.com/manadadev",
		},
	},
	boxVersion: "2.3.12",
	storageName: "JUNGLE",
	waitlistID: 11887,
} as const;

export const APP_SEO_META = {
	title: APP_DATA.displayName,
	description: APP_DATA.description,
	ogDescription: APP_DATA.description,
	ogTitle: APP_DATA.displayName,
	ogImage: APP_DATA.ogImage,
	ogType: "website",
	ogUrl: APP_DATA.url,
	ogSiteName: APP_DATA.displayName,
	ogLocale: "en_US",
	twitterCard: "summary_large_image",
	keywords: APP_DATA.keywords.join(),
} as const;

export const APP_WALLETCONNECT = {
	projectId: "d76bc14db07e9a0361ba6bab1b5f3465",
	metadata: {
		name: APP_DATA.displayName,
		description: APP_DATA.description,
		url: APP_DATA.url,
		icons: ["https://avatars.githubusercontent.com/u/162705533"],
	},
};

export const APP_FONTS = [
	"https://fonts.googleapis.com/css2?family=Anton&display=block",
	"https://fonts.googleapis.com/css2?family=Fredoka:wght@500..600&family=Space+Grotesk:wght@400;700&display=block",
];

export const APP_PRECONNECT = [
	"https://www.gstatic.com/draco/versioned/decoders/1.5.5/draco_decoder.wasm",
	"https://fonts.googleapis.com",
	"https://fonts.cdnfonts.com",
	"https://fonts.gstatic.com",
	...ASSET_PATHS_MODELS,
];

export const APP_IMAGE_PRELOAD = [
	"/assets/clouds/clouds00.png",
	"/assets/clouds/clouds01.png",
	"/assets/clouds/clouds03.png",
];

export const APP_ICON =
	"data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y='.9em' font-size='90' transform='translate(10, 0)'>🌴</text><text y='.1em' font-size='90' transform='translate(10, 35) scale(0.5,0.5)'>🏡</text><text y='.9em' font-size='90' transform='rotate(180, 50, 50) translate(0, -35)'>⛰️</text></svg>";

export const APP_PLAYTEST_ACCESS = {
	accessCode: ENV.VITE_ACCESS_SECRET,
	priorityMaximum: 30,
};
