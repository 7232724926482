import { defineWorld } from "@latticexyz/world";

export default defineWorld({
	namespace: "jungle",
	tables: {
		Admin: "bool",
		GameSettings: {
			schema: {
				nonce: "uint256",
				lastUpdate: "uint256",
				finishedGeneration: "bool",
				playerCount: "uint256",
				maxPlayers: "uint256",
			},
			key: [],
		},
		WorldGlobal: {
			schema: {
				currentRain: "uint256",
				fertilityMultiplier: "uint256",
			},
			key: [],
		},
		TickQueues: {
			schema: {
				fertilizableTick: "uint256",
				burnableTick: "uint256",
				fertilizableQueue: "bytes32[]",
				burnableQueue: "bytes32[]",
			},
			key: [],
		},
		Player: {
			schema: {
				inst: "bytes32",
				isPlayer: "bool",
				id: "uint256",
			},
			key: ["inst"],
		},
		BuildConditions: {
			schema: {
				inst: "uint32",
				requiresDirection: "bool[6]",
			},
			key: ["inst"],
		},
		Task: {
			schema: {
				inst: "bytes32",
				task: "bytes32",
				isPeriodic: "bool",
				startTime: "uint256",
				time: "uint256",
			},
			key: ["inst", "task"],
		},
		LootTable: {
			schema: {
				id: "uint32",
				itemTypes: "uint32[]",
				weights: "uint32[]",
			},
			key: ["id"],
		},
		// ENTITY
		EntityType: {
			schema: {
				inst: "bytes32",
				typeId: "uint32",
			},
			key: ["inst"],
		},
		// CHILD <> PARENT
		ChildToParent: {
			schema: {
				child: "bytes32",
				parent: "bytes32",
				parentIndex: "uint256",
			},
			key: ["child"],
		},
		ParentToChildren: {
			schema: {
				parent: "bytes32",
				children: "bytes32[]",
			},
			key: ["parent"],
		},
		Tags: {
			schema: {
				inst: "bytes32",
				tags: "bytes32[]",
			},
			key: ["inst"],
		},
		// COORDINATES
		CoordinateToEntity: {
			schema: {
				coordinateKey: "bytes32",
				entity: "bytes32",
			},
			key: ["coordinateKey"],
		},
		WorldCoordinate: {
			schema: {
				inst: "bytes32",
				hasCoordinate: "bool",
				x: "int32",
				y: "int32",
				quad: "uint16",
				height: "int32",
			},
			key: ["inst"],
		},
		Adjacents: {
			schema: {
				quadIndex: "uint16",
				// Array: [q, r, quadIndex]
				adjacent1: "int16[]",
				adjacent2: "int16[]",
				adjacent3: "int16[]",
				adjacent4: "int16[]",
			},
			key: ["quadIndex"],
		},
		Orientation: {
			schema: {
				inst: "bytes32",
				orientation: "uint8",
			},
			key: ["inst"],
		},
		Variant: {
			schema: {
				inst: "bytes32",
				hasVariants: "bool",
				color: "uint8",
				altColor: "uint8",
			},
			key: ["inst"],
		},
		// SIMCOMPONENTS
		Container: {
			schema: {
				inst: "bytes32",
				isContainer: "bool",
				owner: "bytes32",
				numSlots: "uint8",
				slots: "bytes32[]",
			},
			key: ["inst"],
		},
		InventoryItem: {
			schema: {
				inst: "bytes32",
				isItem: "bool",
				owner: "bytes32",
			},
			key: ["inst"],
		},
		Stackable: {
			schema: {
				inst: "bytes32",
				isStackable: "bool",
				stackSize: "uint8",
				maxSize: "uint8",
			},
			key: ["inst"],
		},
		Crop: {
			schema: {
				inst: "bytes32",
				isCrop: "bool",
				productEntityTypeId: "uint32",
				growRate: "uint32",
				growerKey: "bytes32",
				taskKey: "bytes32",
			},
			key: ["inst"],
		},
		Grower: {
			schema: {
				inst: "bytes32",
				isGrower: "bool",
				maxCrops: "uint8",
				crops: "bytes32[]",
			},
			key: ["inst"],
		},
		Growable: {
			schema: {
				inst: "bytes32",
				isGrowable: "bool",
				canGrow: "bool",
				totalTime: "uint256",
				currentTime: "uint256",
				onGrowFn: "bytes",
				shouldGrowFn: "bytes",
				stageTimes: "uint32[]",
			},
			key: ["inst"],
		},
		Plantable: {
			schema: {
				inst: "bytes32",
				isPlantable: "bool",
				productEntityTypeId: "uint32",
				produceAmount: "uint8",
			},
			key: ["inst"],
		},
		Plot: {
			schema: {
				inst: "bytes32",
				isPlot: "bool",
				acceptsItems: "bool",
				maxItems: "uint8",
				items: "bytes32[]",
			},
			key: ["inst"],
		},
		Edible: {
			schema: {
				inst: "bytes32",
				isEdible: "bool",
				healthValue: "uint8",
				hungerValue: "uint8",
			},
			key: ["inst"],
		},
		Eater: {
			schema: {
				inst: "bytes32",
				isEater: "bool",
				lastEatTime: "uint256",
			},
			key: ["inst"],
		},
		Rummage: {
			schema: {
				inst: "bytes32",
				isRummage: "bool",
				maxUses: "uint8",
				uses: "uint8",
				lootTableId: "uint32",
				removeOnEmpty: "bool",
				onRummageFn: "bytes",
			},
			key: ["inst"],
		},
		Decorator: {
			schema: {
				inst: "bytes32",
				hasDecorator: "bool",
				decoratorType: "uint32[]",
				decoratorKeys: "bytes32[]",
			},
			key: ["inst"],
		},
		Usable: {
			schema: {
				inst: "bytes32",
				isUsable: "bool",
				useFn: "bytes",
				useOnTargetFn: "bytes",
				canUseFn: "bytes",
				canUseOnTargetFn: "bytes",
			},
			key: ["inst"],
		},
		Fueled: {
			schema: {
				inst: "bytes32",
				isFueled: "bool",
				currentFuel: "uint256",
				maxFuel: "uint256",
				rate: "uint256",
				accepting: "bool",
				fuelType: "string",
				onTakeFuelFn: "bytes",
				onDepletedFn: "bytes",
				onFullFn: "bytes",
				onDeltaFn: "bytes",
			},
			key: ["inst"],
		},
		// PROPAGATION SYSTEMS
		Burnable: {
			schema: {
				inst: "bytes32",
				isBurnable: "bool",
				spreading: "bool",
				flashpoint: "uint8",
				currentHeat: "uint8",
				decayRate: "uint8",
				heatOutput: "uint8",
				fuel: "uint8",
				onBurnFn: "bytes",
				onStopBurnFn: "bytes",
				onStartBurnFn: "bytes",
				onEmptyFn: "bytes",
			},
			key: ["inst"],
		},
		Fertilizable: {
			schema: {
				inst: "bytes32",
				isFertilizable: "bool",
				spreading: "bool",
				currentFertility: "uint8",
				shouldSpreadFn: "bytes",
				shouldSpreadToFn: "bytes",
				onSpreadFn: "bytes",
			},
			key: ["inst"],
		},
	},
	// systems: {
	// 	// 	ActionSystem: {
	// 	// 		openAccess: true,
	// 	// 	},
	// 	AdjacentSystem: {
	// 		// name: "adjacents",
	// 		openAccess: false,
	// 	},
	// 	FueledSystem: {
	// 		// name: "fueled",
	// 		openAccess: false,
	// 	},
	// 	BuildConditionSystem: {
	// 		// name: "buildConditions",
	// 		openAccess: false,
	// 	},
	// 	BurnableSystem: {
	// 		// name: "burnables",
	// 		openAccess: false,
	// 	},
	// 	ChildSystem: {
	// 		// name: "children",
	// 		openAccess: false,
	// 	},
	// 	CoordinateSystem: {
	// 		// name: "coordinates",
	// 		openAccess: false,
	// 	},
	// 	ContainerSystem: {
	// 		// 		// name: "containers",
	// 		openAccess: false,
	// 	},
	// 	CropSystem: {
	// 		// name: "crops",
	// 		openAccess: false,
	// 	},
	// 	DecoratorSystem: {
	// 		// name: "decorators",
	// 		openAccess: false,
	// 	},
	// 	EdibleSystem: {
	// 		// name: "edibles",
	// 		openAccess: false,
	// 	},
	// 	EItemSystem: {
	// 		// name: "eItems",
	// 		openAccess: false,
	// 	},
	// 	EJungleSystem: {
	// 		// name: "eJungle",
	// 		openAccess: false,
	// 	},
	// 	EntitySystem: {
	// 		// name: "entities",
	// 		openAccess: false,
	// 	},
	// 	FertilitySystem: {
	// 		// name: "fertilizables",
	// 		openAccess: false,
	// 	},
	// 	GameSystem: {
	// 		// 		// name: "GameSystem",
	// 		openAccess: false,
	// 	},
	// 	GrowableSystem: {
	// 		// 		// name: "growables",
	// 		openAccess: false,
	// 	},
	// 	GrowerSystem: {
	// 		// 		// name: "growers",
	// 		openAccess: false,
	// 	},
	// 	InventoryItemSystem: {
	// 		// 		// name: "inventoryItems",
	// 		openAccess: false,
	// 	},
	// 	LootTableSystem: {
	// 		// 		// name: "lootTables",
	// 		openAccess: false,
	// 	},
	// 	PlantableSystem: {
	// 		// 		// name: "plantables",
	// 		openAccess: false,
	// 	},
	// 	PlotSystem: {
	// 		// 		// name: "plots",
	// 		openAccess: false,
	// 	},
	// 	PrefabSystem: {
	// 		// 		// name: "prefabs",
	// 		openAccess: false,
	// 	},
	// 	RummageSystem: {
	// 		// 		// name: "rummages",
	// 		openAccess: false,
	// 	},
	// 	StackableSystem: {
	// 		// 		// name: "stackables",
	// 		openAccess: false,
	// 	},
	// 	TaskSystem: {
	// 		// 		// name: "tasks",
	// 		openAccess: false,
	// 	},
	// 	TickSystem: {
	// 		// 		// name: "ticks",
	// 		openAccess: false,
	// 	},
	// 	UsableSystem: {
	// 		// 		// name: "usables",
	// 		openAccess: false,
	// 	},
	// 	VariantSystem: {
	// 		// 		// name: "variants",
	// 		openAccess: false,
	// 	},
	// 	WorldGlobalSystem: {
	// 		// 		// name: "worldGlobals",
	// 		openAccess: false,
	// 	},
	// },
});
