import { Debug } from "@/lib/debug/debug";
import { defaultState, registerUIStates, startTime } from "./ui/ui.states";
import { theSim } from "./sim/sim.store";

const GameStates = {
	cinematic: {
		...defaultState,
		requiresAuth: false,
		showGameLogo: false,
		onEnter: () => {
			Debug("⏱️ TIME").log(`cinematic: ${Date.now() - startTime}ms`);
		},
	},
	loading: {
		...defaultState,
		requiresAuth: false,
		showLoadingScreen: true,
		showGameLogo: true,
		onEnter: () => {
			Debug("⏱️ TIME").log(`loading: ${Date.now() - startTime}ms`);
			theSim.emit("loading");
		},
	},
	playMode: {
		...defaultState,
		showInventory: true,
		backgroundLoading: true,
		allowInput: true,
		onEnter: () => {
			Debug("⏱️ TIME").log(`playMode: ${Date.now() - startTime}ms`);
		},
	},
	tourismMode: {
		...defaultState,
		requiresAuth: false,
		showTourismbar: true,
		showWalletButton: true,
	},
};

registerUIStates(GameStates);

export { GameStates };
