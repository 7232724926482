import type { MUDChain } from "src/types";
import { arbitrumSepolia as _arbitrumSepolia } from "viem/chains";

export const arbitrumSepolia = {
	...(_arbitrumSepolia as MUDChain),
	rpcUrls: {
		default: {
			http: [
				"https://late-intensive-mountain.arbitrum-sepolia.quiknode.pro/dc9cce0f0d515efb6fa0a67a4d79417aff314fea/",
			],
			webSocket: [
				"wss://late-intensive-mountain.arbitrum-sepolia.quiknode.pro/dc9cce0f0d515efb6fa0a67a4d79417aff314fea/",
			],
		},
	},
};
