import { getEntityDataByName } from "@/data/entity/entity.data.fn";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export interface IItemShopProduct {
	name: string;
	entityTypeId: number;
	selected: boolean;
	required?: boolean;
}

export interface IItemShopStore {
	playpass: IItemShopProduct;
	products: IItemShopProduct[];
	detailedProduct: IItemShopProduct | null;
	currency: string;
	updateProduct: (product: IItemShopProduct) => void;
	deselectAllProducts: () => void;
}

const useItemShopStore = create<IItemShopStore>()(
	immer((set) => ({
		playpass: null!,
		products: [],
		detailedProduct: null,
		currency: "ETH",
		updateProduct: (product: IItemShopProduct) =>
			set((state) => {
				const idx = state.products.findIndex((p) => p.name === product.name);
				if (idx >= 0) {
					state.products[idx] = product;
				}
			}),
		deselectAllProducts: () => {
			set((state) => {
				const p = state.products.map((p) => ({ ...p, selected: false }));
				state.products = p;
			});
		},
	})),
);

const ItemShopStore = () => ({
	...useItemShopStore.getState(),
	set: useItemShopStore.setState,
});
export { ItemShopStore, useItemShopStore };

export const loadItemShopItems = async () => {
	const availableProducts: IItemShopProduct[] = [
		"Mystery Seed",
		"Waterbucket",
		"Shovel",
		"Golden Shovel",
		// "Dirty Glasses",
		// "Pink Glasses",
		"Storm Bottle",
		"Drum Machine",
		"Lighter",
		"Fertilizer",
	]
		.map((name) => {
			const e = getEntityDataByName(name);
			if (!e) {
				console.error(`❌ Shop Item ${name} was not found`);
				return undefined;
			}
			return {
				name,
				entityTypeId: e.entityTypeId,
				selected: name === "Mystery Seed",
			};
		})
		.filter((p) => p !== undefined) as IItemShopProduct[];

	const playpass = (() => {
		const name = "Entry Ticket";
		const e = getEntityDataByName(name);
		if (!e) throw new Error(`No ${name}`);
		return {
			name,
			entityTypeId: e.entityTypeId,
			selected: true,
			required: true,
		};
	})();

	ItemShopStore().set({
		playpass,
		products: availableProducts,
		detailedProduct: playpass,
	});
};
