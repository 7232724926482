import { getColorByName } from "@/data/colors/palette.data";
import { Environment } from "@react-three/drei";
import { useRef } from "react";
import { type DirectionalLight, Object3D } from "three";

export function Background() {
	const light0 = useRef<DirectionalLight>(null);
	const light1 = useRef<DirectionalLight>(null);
	const light2 = useRef<DirectionalLight>(null);
	const lightTargetRef = useRef<Object3D>(new Object3D());
	const size = 1024 * 8;
	const bias = -0.00009;

	return (
		<>
			<Environment
				files={"/bg.hdr"}
				background={"only"}
				blur={0.7}
				resolution={128}
			/>
			<ambientLight
				intensity={1.25}
				color={getColorByName("lights", "ambient")?.hex}
			/>
			<directionalLight
				ref={light0}
				castShadow
				position={[-150 / 5, 150 / 5, 150 / 5]}
				intensity={3.5}
				color={getColorByName("lights", "sunlight")?.hex}
				shadow-mapSize-height={size}
				shadow-mapSize-width={size}
				shadow-bias={bias}
				target={lightTargetRef.current}
			>
				<orthographicCamera
					attach="shadow-camera"
					args={[-10, 10, 10, -10, 0.01, 500]}
				/>
			</directionalLight>

			<directionalLight
				ref={light1}
				castShadow={false}
				position={[-50, 50, 50]}
				intensity={0.45}
				color={getColorByName("lights", "tint")?.hex}
				// shadow-mapSize-height={size}
				// shadow-mapSize-width={size}
				// shadow-bias={bias}
				target={lightTargetRef.current}
			/>
			<directionalLight
				ref={light2}
				castShadow
				position={[30 / 3, 100 / 3, -30 / 3]}
				intensity={3.75}
				color={getColorByName("lights", "sunlight")?.hex}
				// shadow-mapSize-height={size / 2}
				// shadow-mapSize-width={size / 2}
				shadow-bias={bias * 3.5}
				shadow-normalBias={bias}
				shadow-radius={10}
				target={lightTargetRef.current}
			>
				<orthographicCamera
					attach="shadow-camera"
					args={[-10, 10, 10, -10, 0.01, 500]}
				/>
			</directionalLight>
			<primitive object={lightTargetRef.current} />
		</>
	);
}
