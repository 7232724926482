import { CConstruction } from "@game/sim/components/CConstruction";
import { CRenderer } from "@game/sim/components/CRenderer";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { ScaffoldRenderer } from "./scaffold.renderer";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import {
	registerBlueprint,
	type TBluePrint,
} from "@/data/blueprint/blueprint.data";
import { getBuildPosition } from "@game/sim/components/CConstruction.functions";
import { getMUDState, getSystemCalls, tables } from "@mud/index";
import type { Hex } from "viem";
import Audio from "@game/audio/audioPlayer";
import { TheMouseData } from "@game/input/theInput";
import { t } from "i18next";

export class ScaffoldPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CRenderer).setRenderer(ScaffoldRenderer);
		e.addComponent(CConstruction).setRecipes(
			"Scaffold",
			"Farm",
			"SheetWall",
			"LimestoneWall",
		);
		this.inst.nameFn = this.getName.bind(this);
	}

	getName = () => {
		const burnable = getMUDState().getValue(tables.Burnable, {
			inst: this.inst.ref as Hex,
		});
		if (burnable) {
			if (burnable?.spreading) {
				return t("entity_state.inferno");
			}
			if (burnable.fuel <= 0) {
				return t("entity_state.debris");
			}
		}
		return this.inst.name;
	};
}

const entityData: TEntityDefinition = {
	name: "Scaffold",
	simEntityPrefab: ScaffoldPrefab,
	id: 450,
};

const blueprint = {
	name: "Scaffold",
	ingredients: [{ amount: 1, name: "Bamboo" }],
	tools: [],
	buildingSide: undefined,
	requiresNeighbour: [],
	canBuildFn: (
		_doer: SimEntity,
		recipe: TBluePrint,
		_item: SimEntity,
		origin: SimEntity,
	) => {
		const construction = origin.component(CConstruction);
		return (
			construction.isDirectionEmpty() &&
			construction.canBuildInDirection(recipe)
		);
	},
	onBuildFn: () => {
		Audio.playSound("PUT", { volume: 0.125, broadcast: true });
	},
	execute: async (
		doer: SimEntity,
		recipe: TBluePrint,
		item: SimEntity,
		origin: SimEntity,
	) => {
		const { action_build } = getSystemCalls();
		origin.remove();
		Audio.playSound("PUT");
		const block = origin.component(CRenderer).block;
		if (!block) throw new Error("no block");
		const { coordinate, angle } = getBuildPosition(
			block,
			TheMouseData.getFaceIndex(),
		);
		await action_build(
			doer.ref as Hex,
			item.ref as Hex,
			coordinate,
			entityData.id,
			angle,
		);
	},
} as TBluePrint;
registerBlueprint(blueprint);

registerEntity(entityData);
