import { getTexture } from "@/data/assetLoader/asset.store";
import type { MaterialProps, RootState } from "@react-three/fiber";
import { Color, type Material } from "three";
import {
	createBurnableMaterial,
	type TBurnableMaterial,
} from "../materials/meshBurnableMaterial";
import type { TRenderData } from "./sharedData";

export const defaultCoalMaterial = (
	baseMaterial: MaterialProps | TBurnableMaterial,
) => {
	return createBurnableMaterial({
		noiseTexture: getTexture("fire/FireNoise_A"),
		progress: 0,
		...(baseMaterial as Material),
		color: new Color("black"),
	}) as Material;
};

export const defaultFireMaterial = (
	baseMaterial: MaterialProps | TBurnableMaterial,
) => {
	return createBurnableMaterial({
		noiseTexture: getTexture("fire/FireNoise_A"),
		...(baseMaterial as Material),
	}) as Material;
};

export const defaultFireUpdate = (
	state: RootState,
	_delta: number,
	self: TRenderData,
) => {
	if (!self?.materials?.fire) {
		console.warn("no fire material");
		return;
	}
	const fireMat = self.materials.fire as TBurnableMaterial;
	fireMat.time = state.clock.getElapsedTime() / 100;
	fireMat.needsUpdate = true;
};
