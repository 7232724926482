import { getMesh } from "@/data/assetLoader/asset.store";
import {
	createTransformationMatrix,
	generateCropPositions,
} from "@/lib/placement.utils";
import { seededRand } from "@/lib/random/seeded.functions";
import { useMemo, useRef } from "react";
import type { SimEntity } from "../sim/SimEntity";
import {
	BufferAttribute,
	Color,
	type MeshStandardMaterial,
	Vector3,
	type Vector3Tuple,
} from "three";
import {
	registerRenderData,
	SHARED_RENDERDATA,
} from "@/lib/batching/sharedData";
import { CBurnable } from "@game/sim/components/CBurnable";
import {
	defaultFireMaterial,
	defaultFireUpdate,
} from "@/lib/batching/batching.utils";
import { useRenderChildren } from "@game/sim/SimEntity.fn";
import { useBatchedRenderer } from "@/lib/batching/useBatchedRenderer";
import { useBlock } from "@/lib/useBlock";
import { deformMesh } from "@/lib/deformation/meshDeformation";
import { useBurnableUpdate } from "@game/sim/useBurnableUpdate";

export const meshes = ["modular_set/modular_set/building_module_farm_dirt002"];

registerRenderData("farm", () => {
	const model = getMesh(meshes[0]);
	const map = (model?.material as MeshStandardMaterial).map;
	const baseMaterial = {
		maskColor: new Color("white"),
		map,
		opacity: 1,
		transparent: false,
		color: new Color("white"),
	};
	const materials = {
		fire: defaultFireMaterial(baseMaterial),
	};
	return {
		materials,
		meshes: {
			default: model,
		},
		onUpdate: (state, delta, self) => defaultFireUpdate(state, delta, self),
	};
});

export function FarmRenderer({ entity }: { entity: SimEntity }) {
	const { block, pos } = useBlock({ entity });
	const progressRef = useRef(0);

	const { geometry, material, burnable } = useMemo(() => {
		// @dev mesh + model code is unique per entity
		const model = getMesh(meshes[0]);
		const geo = deformMesh(model, block, 0).geometry;
		// @dev (FIRE MATERIAL) set the progress attribute to for all vertices
		const burnable = entity.component(CBurnable);
		geo.setAttribute(
			"progress",
			new BufferAttribute(
				new Float32Array(geo.attributes.position.count).fill(
					burnable.getProgress() || 0,
				),
				1,
			),
		);
		const mat = SHARED_RENDERDATA.farm?.materials?.fire;
		return { geometry: geo, material: mat!, burnable };
	}, [block, entity]);

	// FIXME: when removed from world- the batching system optimizes and changes the indices- this causes another geometry to shift into this one's spot
	const data = useBatchedRenderer(
		entity,
		() => {
			return {
				geometry,
				material,
				opts: {
					matrix: createTransformationMatrix(
						pos.clone().add(new Vector3(0, 0, 0)),
						[0, 0, 0],
						[2, 2, 2],
					),
				},
			};
		},
		[entity],
	);

	useBurnableUpdate({
		progressRef,
		burnable,
		entity,
		data,
		material,
		geometry,
	});

	const children = useRenderChildren(
		entity,
		(child: SimEntity | undefined, idx: number) => {
			return {
				child,
				props: {
					pos: generateCropPositions(entity.children().length, 0.0925, -0.215)[
						idx
					],
					rot: seededRand(idx)(0, 100),
				},
			};
		},
	);

	return useMemo(
		() => (
			<group position={[...pos] as Vector3Tuple}>
				<mesh
					visible={false}
					userData={{ entityRef: entity.ref }}
					geometry={geometry}
					scale={[2, 2, 2]}
				/>
				{children}
			</group>
		),
		[pos, children, entity.ref, geometry],
	);
}
