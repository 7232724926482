import { useCallback, useEffect, type DependencyList } from "react";
import type { SimEntity } from "./SimEntity";

/**
 * Triggers a callback function for *onUpdate* events on a SimEntity.
 *
 * **STRONGLY** recommended to memoize the callback function with *useCallback* when doing state updates.
 *
 * @param fn - The callback function to be invoked when the entity updates.
 * @param entity - The SimEntity instance to listen for update events.
 */
const useEntityUpdate = (
	callback: () => void,
	entity: SimEntity,
	dependencies?: DependencyList,
): void => {
	const _callback = useCallback(callback, dependencies || [entity, callback]);

	useEffect(() => {
		_callback();
		const c = _callback;
		entity.listenForEvent("onUpdate", c);
		return () => {
			entity.removeEventListener("onUpdate", c);
		};
	}, [entity, _callback]);
};

export default useEntityUpdate;
