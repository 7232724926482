import { LMBIcon } from "@/assets/LMB";
import { RMBIcon } from "@/assets/RMB";
import { useUserStore } from "@/data/user-settings.store";
import { cn } from "@/lib/utils";
import { getAction } from "@game/sim/actions/actions.fn";
import { BufferedAction } from "@game/sim/actions/bufferedAction";
import { getEntityByRef } from "@game/sim/sim.store";
import { WorldStore } from "@game/world/world.store";
import { motion, useAnimation } from "framer-motion";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UIStore, useUIStore } from "../../ui.store";
import { useInventoryStore } from "../inventory/inventory.store";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CStackable } from "@game/sim/components/CStackable";

const iconAnims = {
	bounce: { scale: [0.8, 1.2, 0.93, 1] },
};

const amountAnim = {
	bounce: { scale: [0.8, 1.4, 0.92, 1], opacity: [0, 1, 1, 1] },
};

export default function CursorRenderer() {
	const [amount, setAmount] = useState(1);
	const [mouseDownPosition, setMouseDownPosition] = useState<
		[number, number] | null
	>(null); // @dev track mousedown for dragging
	const { activeItem } = useInventoryStore((state) => {
		return {
			activeItem: state.getActiveItem(),
		};
	});
	const { actions, altActions, hoveredItem, hoveredObject, dropItem } =
		useUIStore((state) => {
			return {
				actions: state.actions,
				altActions: state.altActions,
				hoveredItem: state.hoveredItem,
				hoveredObject: state.hoveredObject,
				dropItem: state.dropItem,
			};
		});
	const activeAction = useUIStore((state) => state.activeAction);
	const isPlayer = useUserStore((state) => state.isPlayer());
	const controls = useAnimation();

	const changeAmount = useCallback(() => {
		const newAmount = activeItem?.component(CStackable)?.stackSize || 1;
		if (amount !== newAmount) setAmount(newAmount);
	}, [activeItem, amount]);

	// @dev item change
	const { item } = useMemo(() => {
		const ho = getEntityByRef(hoveredObject);
		changeAmount();
		return {
			amount: activeItem?.component(CStackable)?.stackSize || 1,
			item: activeItem || hoveredItem || ho || undefined,
		};
	}, [activeItem, hoveredItem, hoveredObject, changeAmount]);

	// @dev action change
	const { action, altAction } = useMemo(() => {
		if (!isPlayer) return { action: undefined, altAction: undefined };
		let alt = altActions && altActions.length > 0 ? altActions[0] : undefined;
		if (hoveredObject && dropItem) {
			UIStore().set({
				dropItem: false,
			});
		}

		if (
			alt === undefined &&
			activeItem &&
			activeItem.component(CInventoryItem) &&
			!hoveredObject &&
			dropItem
		) {
			const player = WorldStore().getPlayer();
			if (player) {
				alt = new BufferedAction({
					self: player!,
					doer: player!,
					target: activeItem,
					action: getAction("DROP_ITEM"),
					invObject: activeItem,
				});
			}
		}
		return {
			action: actions && actions.length > 0 ? actions[0] : undefined,
			altAction: alt,
		};
	}, [actions, altActions, hoveredObject, activeItem, dropItem, isPlayer]);

	// @dev handle mouse events
	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			if (!mouseDownPosition) return;
			if (UIStore().isHoveringSlot && !UIStore().uiState.allowInput) return;
			const [downX, downY] = mouseDownPosition;

			// @dev bail if dragging
			if (Math.abs(e.clientX - downX) > 2 || Math.abs(e.clientY - downY) > 2)
				return;

			if (action && e.button === 0) {
				UIStore().set({
					actions: [],
				});
				action.do();
			}
			if (altAction && e.button === 2) {
				UIStore().set({
					actions: [],
				});
				altAction.do();
			}
		};

		const handleMouseDown = (event: MouseEvent) => {
			setMouseDownPosition([event.clientX, event.clientY]);
			// event.stopPropagation();
		};

		const handleMouseMove = (_e: MouseEvent) => {
			const isHovering = UIStore().hoveredObject || UIStore().hoveredItem;
			if (UIStore().dropItem !== !isHovering) {
				UIStore().set({
					dropItem: !isHovering,
				});
			}
		};

		document.addEventListener("mouseup", handleClick);
		document.addEventListener("mousedown", handleMouseDown);
		document.addEventListener("mousemove", handleMouseMove);
		return () => {
			document.removeEventListener("mouseup", handleClick);
			document.removeEventListener("mousedown", handleMouseDown);
			document.removeEventListener("mousemove", handleMouseMove);
		};
	}, [action, altAction, mouseDownPosition]);

	// @dev react to amount changes on cursor items
	useEffect(() => {
		if (!activeItem) return;
		const stackSizeChanged = () => {
			changeAmount();
			controls.start("bounce");
		};
		stackSizeChanged();

		activeItem?.listenForEvent("onStackSizeChanged", stackSizeChanged);
		return () => {
			activeItem?.removeEventListener("onStackSizeChanged", stackSizeChanged);
		};
	}, [activeItem, controls, changeAmount]);

	if (activeAction || !item) return null;
	return (
		<>
			<motion.div
				className="flex flex-col text-black items-center justify-center -mt-10 ml-2 font-berkeley pointer-events-none"
				style={{
					scale: 1,
				}}
				transition={{ duration: 0.5 }}
				animate={{ ...amountAnim.bounce }}
				exit={{ scale: 0 }}
			>
				<div className="bar-button-size relative scale-110">
					{activeItem && (
						<motion.img
							layout
							layoutId={activeItem.ref}
							key={activeItem.ref}
							src={
								`/icons/${activeItem?.component(CInventoryItem).img}` ||
								"/icons/unknown.webp"
							}
							style={{
								scale: 1,
							}}
							animate={controls}
							className="bar-button-size relative top-0 left-0"
							transition={{ duration: 0 }}
							variants={iconAnims}
						/>
					)}
					{amount! > 1 && (
						<motion.div
							animate={controls}
							style={{
								scale: 1,
							}}
							transition={{ duration: 0.4 }}
							variants={amountAnim}
							className="absolute top-0 right-0 text-lg text-white bg-opacity-50 p-0.5 text-outline"
						>
							{amount}
						</motion.div>
					)}
				</div>
				{item && (
					<div
						className={cn(
							"text-center w-[300px] text-lg -mt-10",
							activeItem && "-mt-20",
							activeItem && action && altAction && "-mt-28",
						)}
					>
						<div className="text-outline flex flex-col">
							{action && (
								<div>
									<LMBIcon className="w-8 h-8 inline -mt-0.5 mr-1" />
									{action?.action.strFn?.(action)}
								</div>
							)}
							{altAction && (
								<div>
									<RMBIcon className="w-8 h-8 inline -mt-0.5 mr-1" />
									{altAction?.action.strFn?.(altAction)}
								</div>
							)}
							{/* @dev show name when no actions */}
							{!action && !altAction && item.getName()}
						</div>
					</div>
				)}
			</motion.div>
		</>
	);
}
