import sign from "@/assets/sigilSign.json";
import { Debug } from "@/lib/debug/debug";
import { createParticleEffect } from "@game/effects/particle.functions";
import { ParticleStore } from "@game/effects/particle.store";
import { useFrame } from "@react-three/fiber";
import { useRef, useState } from "react";
import {
	AdditiveBlending,
	type Mesh,
	type MeshBasicMaterial,
	SplineCurve,
	Vector2,
	Vector3,
} from "three";
import { useSigilSystem } from "./sigils.provider";
import { SigilStore } from "./sigils.store";
import type { TSigil } from "./sigils.types";
import { clamp01 } from "@/lib/utils";

const scaleCurve = new SplineCurve([
	new Vector2(0, 1),
	new Vector2(0.05, 0.6),
	new Vector2(0.8, 0.5),
	new Vector2(1, 0.4),
]);

const opacityCurve = new SplineCurve([
	new Vector2(0, 0),
	new Vector2(0.2, 0.1),
	new Vector2(0.5, 1),
	new Vector2(0.9, 1),
	new Vector2(1, 1),
]);

const createSigilParticle = async (sigil: TSigil, position: Vector3) => {
	// @dev we want to override the texture url in the particle
	const temp = { ...sign };
	temp.images[0].url = `/icons/${sigil.sigilIcon}.webp`;
	console.log("TESTING", sign, temp);
	const obj = await ParticleStore().loader.parseAsync(temp);
	console.log("SIGIL", obj);
	const effect = await createParticleEffect({
		particle: { fileName: "", object: obj },
		position: position,
		scale: new Vector3(0.25, 0.25, 0.25),
		lifetime: 3000,
	});
	return effect;
};

export const Sigil = ({ sigil }: { sigil: TSigil }) => {
	const { finishSigil } = useSigilSystem();
	const [isValid, setIsValid] = useState(true);
	const sigilRef = useRef<Mesh>(null!);
	const { playerSigil } = SigilStore();
	const isPlayer = playerSigil?.id === sigil.id;
	const hasParticle = useRef(false);
	// const [particle, setParticle] = useState<TParticleEffectRef | null>(null);

	// useEffect(() => {
	// 	const createParticle = async () => {
	// 		if (particle) return;
	// 		const p = await createCustomParticle(sigil, sigilRef.current?.position!);
	// 		setParticle(p);
	// 	};
	// 	createParticle();
	// }, [sigil, particle]);

	useFrame((_state, delta) => {
		if (!sigilRef?.current || !isValid) return;

		const maxLifetime = 3000;
		const maxDuration = sigil.duration >= 0 ? sigil.duration : maxLifetime;
		const currentTime = Date.now();
		const lifetime = currentTime - sigil.lastUpdate;
		const normalizedLifetime = clamp01(lifetime / maxDuration);
		const mat = sigilRef.current.material as MeshBasicMaterial;

		if (lifetime > 800 && !hasParticle.current) {
			const createParticle = async () => {
				if (sigilRef.current?.position) {
					await createSigilParticle(sigil, sigilRef.current?.position);
				}
			};
			createParticle();
			hasParticle.current = true;
			if (isPlayer) {
				Debug("Sigil").capture("Sigil Long", { sigil });
			}
		}

		if (normalizedLifetime < 1) {
			// @dev do lifetime scale & opacity
			const scaleSample = (1 - scaleCurve.getPointAt(normalizedLifetime).y) * 5;
			const opacitySample =
				(1 - opacityCurve.getPointAt(normalizedLifetime).y) * 0.3;

			sigilRef.current.scale.set(scaleSample, scaleSample, scaleSample);
			mat.opacity = opacitySample;
		} else {
			// @dev do death scale & opacity
			const s = sigilRef.current.scale.x - delta * 0.25;
			const o = mat.opacity - delta * 0.7;
			sigilRef.current.scale.set(s, s, s);
			mat.opacity = Math.max(0, o);

			// @dev kill sigil
			if (mat.opacity <= 0.001) {
				if (isPlayer) {
					// @dev if player hasn't released mouse we kill it anyway
					finishSigil();
				}
				setIsValid(false);
				SigilStore().removeSigil(sigil.id);
			}
		}
	});

	return (
		<mesh ref={sigilRef} position={[sigil.x, sigil.y, sigil.z]}>
			<sphereGeometry args={[0.5, 32, 32]} />
			<meshBasicMaterial
				color={0xffffff}
				transparent
				opacity={0.5}
				blending={AdditiveBlending}
				depthWrite={false}
			/>
		</mesh>
	);
};
