import Audio from "@game/audio/audioPlayer";
import { getMUDState, getSystemCalls, tables } from "@mud";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import type { TAction } from "../actions/actions.types";
import { SimComponent } from "./SimComponent";
import { CContainer } from "./CContainer";
import { CBurnable } from "./CBurnable";
import { CGrowable } from "./CGrowable";

export class CRummage extends SimComponent {
	canRummageFn: ((self: SimEntity) => boolean) | null = null;
	value = () =>
		getMUDState().getValue(tables.Rummage, {
			inst: this.inst.ref as Hex,
		})!;
	uses = 0;
	maxUses = 0;
	useDefaultActions = true;

	setUseDefaultActions(useDefaultActions: boolean): this {
		this.useDefaultActions = useDefaultActions;
		return this;
	}

	setCanRummageFn(fn: ((self: SimEntity) => boolean) | null) {
		this.canRummageFn = fn;
		return this;
	}

	async loadFromChain(): Promise<void> {
		if (!this.value()) {
			console.warn(`no value for ${this.constructor.name}`, this.inst.ref);
			this.inst.clearComponent(CRummage);
			return;
		}
		this.uses = this.value().uses;
		this.maxUses = this.value().maxUses;
	}

	hasUsesLeft(): boolean {
		return this.uses < this.maxUses;
	}

	async rummage(player: SimEntity, invObject?: SimEntity): Promise<boolean> {
		this.inst.pushEvent("rummageStart", {});
		const { action_rummage } = getSystemCalls();
		Audio.playSound("LEAVES");
		await action_rummage(
			player.ref,
			this.inst.ref,
			invObject?.ref || undefined,
		);
		Audio.playSound("PICKUP_LEAVE", { broadcast: true });
		this.inst.pushEvent("rummageComplete", {});
		return true;
	}

	collectWorldActions(doer: SimEntity, actions: TAction[]) {
		if (
			this.useDefaultActions &&
			doer.component(CContainer) &&
			!doer.component(CContainer).isFull() && // allow stacks > && doer.component(CContainer).allStacksFull()
			this.hasUsesLeft()
		) {
			if (this.canRummageFn) {
				if (!this.canRummageFn(this.inst)) {
					return;
				}
			}
			actions.push("RUMMAGE");
		}
	}
}

export const getRummageTextStr = (entity: SimEntity) => {
	if (entity.component(CBurnable)?.fuel <= 0) {
		return "Gather";
	}
	if (entity.component(CGrowable)) {
		return "Forage";
	}
	return "Rummage";
};
