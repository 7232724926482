import { GameStateUpdate } from "@game/gameStateUpdate";
import UIRoot from "@game/ui/components/ui.root";
import { useSeoMeta } from "unhead";
import { APP_SEO_META } from "./data/app.data";
import { useDevStore } from "./lib/devtools/dev.store";
import { GameRoot } from "./game/game";
import { DevToolPanel } from "./game/ui/components/devtools/devToolPanel";
import { startGame } from "@game/game.functions";
import { useEffect } from "react";
import { theSim } from "@game/sim/sim.store";
import { BackgroundImgLoader } from "./data/assetLoader/backgroundImgLoader";
import { UIStore, useUIStore } from "@game/ui/ui.store";
import { usePrivy } from "@privy-io/react-auth";
import { setUIState } from "@game/ui/ui.states";
import { GameMenuStates } from "@game/ui/components/gameMenu/gameMenu.states";

export const App = () => {
	const devMode = useDevStore((state) => state.devMode);
	const { backgroundLoading } = useUIStore((state) => state.uiState);
	const uiState = useUIStore((state) => state.uiState);
	const { authenticated, ready } = usePrivy();

	useEffect(() => {
		if (ready) {
			if (UIStore().authenticated !== authenticated) {
				UIStore().set({
					authenticated,
				});
			}
		}
	}, [ready, authenticated]);

	useEffect(() => {
		if (!ready) return;
		if (uiState.requiresAuth && !authenticated) {
			setUIState(GameMenuStates.mainMenu);
		}
	}, [uiState, authenticated, ready]);

	useSeoMeta({
		...APP_SEO_META,
	});

	// check if we have a player somewhere stored in localstorage
	useEffect(() => {
		theSim.on("loadingComplete", startGame);
		return () => {
			theSim.off("loadingComplete", startGame);
		};
	}, []);

	return (
		<>
			<div
				className="h-screen w-full overflow-clip pointer-events-none"
				data-name="game-root"
			>
				<GameRoot />
				<GameStateUpdate />
				{backgroundLoading && <BackgroundImgLoader />}
			</div>
			{devMode && <DevToolPanel />}
			<UIRoot />
		</>
	);
};
