import { useEffect, useMemo } from "react";
import { cn, roundToDecimal } from "@/lib/utils";
import { alternateFlexLines, getProductData } from "./itemShop.fn";
import { ShopModalButton, ShopModal, ShopModalBlock } from "./itemShopModal";
import { type IItemShopProduct, ItemShopStore } from "./itemShop.store";
import { useTranslation } from "react-i18next";
import Audio from "@game/audio/audioPlayer";
import { useUIStore } from "@game/ui/ui.store";
import { setUIState } from "@game/ui/ui.states";
import { StoreStates } from "./itemShop.states";
import { motion } from "framer-motion";

export function ItemShopDetailPanel({
	product,
}: { product: IItemShopProduct }) {
	const { t } = useTranslation();
	const state = useUIStore((state) => state.uiState);

	const { name, description, subtitle, price, icon } = useMemo(
		() => getProductData(product),
		[product],
	);

	const { button } = useMemo(() => {
		if (state.confirmPass) {
			const onContinue = () => {
				Audio.playSound("PICKUP_GONG");
				setUIState(StoreStates.loadOutStore);
			};
			const button = (
				<>
					<ShopModalButton onClick={onContinue}>
						{t("continue")}
					</ShopModalButton>
				</>
			);
			return { button };
		}
		return { button: null };
	}, [state, t]);

	useEffect(() => {
		Audio.playSound("PUT");
	}, []);

	return (
		<ShopModal className="font-light overflow-clip rounded-lg receipt-style">
			{/* Top block with title and descriptions */}
			<ShopModalBlock className="pb-0 bg-transparent">
				<div className="text-5xl uppercase tracking-tighter flex-col flex z-10">
					{alternateFlexLines(name || "unknown")}
				</div>
			</ShopModalBlock>
			<ShopModalBlock className="relative py-0 -mb-4 bg-transparent">
				<div
					className={cn(
						"absolute top-[-10%] left-[15%] w-[70%] h-[70%] bg-[#C0CBBD] z-0 transition-all duration-200",
					)}
				/>
				<motion.div
					initial={{ opacity: 0, scale: 0.8 }}
					animate={{ opacity: 1, scale: 1 }}
					className="w-[80%] aspect-square overflow-auto z-10"
					style={{ background: `url(${icon}) no-repeat center/cover` }}
				/>
			</ShopModalBlock>
			<ShopModalBlock className="pt-0 z-10 bg-transparent">
				<div className="text-3xl uppercase tracking-tighter flex-col flex w-full leading-7">
					{alternateFlexLines(subtitle || "unknown")}
				</div>
			</ShopModalBlock>
			<ShopModalBlock className="py-0 z-10 bg-transparent flex-grow h-full">
				<div className="flex-grow" />
				{description}
			</ShopModalBlock>
			<ShopModalBlock className="bg-transparent">
				<div className="self-end flex flex-row w-full">
					<div className="flex flex-row gap-2 flex-grow self-center text-[#256718]">
						<div className="font-bold text-xl">
							{price > 0
								? `${roundToDecimal(price, 3)} ${ItemShopStore().currency}`
								: "FREE"}
						</div>
					</div>
					{button}
				</div>
			</ShopModalBlock>
		</ShopModal>
	);
}
