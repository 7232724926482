import { getColors } from "@/data/colors/palette.data";
import {
	type TSeededRand,
	randomFromArray,
	seededRand,
} from "@/lib/random/seeded.functions";
import { CRummage } from "@game/sim/components/CRummage";
import type { SimEntity } from "@game/sim/SimEntity";
import type { Block } from "@game/world/block";
import { Instance } from "@react-three/drei";
import { useMemo, useState } from "react";
import { Vector3 } from "three";

export type PlantProps = {
	positions: [number, number, number][];
	scales: number[];
	rotations: [number, number, number][];
	grassColors: string[];
};

// for veggie instance props
const CreatePosition = (position: Vector3, rand: TSeededRand) =>
	[
		((rand(0, 1000) / 1000) * 1 - 0.5) * 0.125 + position.x,
		position.y - 0.1,
		((rand(0, 1000) / 1000) * 1 - 0.5) * 0.125 + position.z,
	] as [number, number, number];

const CreateRotation = (rand: TSeededRand) =>
	[
		(rand(0, 1000) / 1000) * 1 - 0.5,
		(rand(0, 1000) / 1000) * 360,
		(rand(0, 1000) / 1000) * 1 - 0.5,
	] as [number, number, number];

const CreateScale = (rand: TSeededRand) => 0.05 + (rand(0, 1000) / 1000) * 0.15;

export function PlantInstance({
	entity,
	block,
}: {
	block: Block;
	entity: SimEntity;
}) {
	const limit = 8;
	const rummage = entity.component(CRummage);
	const [vegetationTier, setVegetationTier] = useState(0);

	const { rand, position } = useMemo(() => {
		const rand = seededRand(entity._seed);
		let tier = rand(1, limit);
		if (rummage) {
			tier = rummage.maxUses - rummage.uses;
		}
		setVegetationTier(tier);
		return {
			rand,
			position: block.position,
		};
	}, [block, rummage, entity]);

	const [amount, setAmount] = useState(0);
	const [properties, setProperties] = useState<PlantProps>({
		positions: [],
		scales: [],
		rotations: [],
		grassColors: [],
	});

	const plantProps = useMemo(() => {
		setAmount(Math.min(vegetationTier, limit) + 2);
		if (properties.positions.length >= amount) {
			return properties;
		}
		const { positions, scales, rotations, grassColors } = properties;

		const p = {
			positions: [...positions, CreatePosition(position, rand)],
			scales: [...scales, CreateScale(rand)],
			rotations: [...rotations, CreateRotation(rand)],
			grassColors: [
				...grassColors,
				randomFromArray(getColors("grass"), rand).hex,
			],
		};
		setProperties(p);
		return { ...properties };
	}, [vegetationTier, properties, amount, position, rand]);

	return (
		<>
			{/* // 🌱 */}
			{plantProps?.positions.map((position, index) => {
				if (index >= limit) return;
				const { scales, rotations, grassColors } = plantProps!;
				const p = entity.hasTag("hasLowPlants")
					? [position[0], position[1] - 0.25, position[2]]
					: position;
				return (
					<Instance
						key={index}
						castShadow
						receiveShadow
						color={grassColors[index]}
						position={new Vector3(p[0], p[1], p[2])}
						scale={[scales[index], scales[index], scales[index]]}
						rotation={rotations[index]}
						frustumCulled={true}
					/>
				);
			})}
		</>
	);
}
