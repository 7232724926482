"use client";
import { formatBytes32React } from "@/lib/format.utils";
import { cn } from "@/lib/utils";
import type { SimEntity } from "@game/sim/SimEntity";
import type { SimComponent } from "@game/sim/components/SimComponent";
import { getEntityByRef } from "@game/sim/sim.store";
import { toggleDev } from "@game/ui/ui.dev";
import { UIStore, useUIStore } from "@game/ui/ui.store";
import { useWorldStore } from "@game/world/world.store";
import { getNetwork, getSystemCalls, tables, useMUDStore } from "@mud/index";
import hotkeys from "hotkeys-js";
import { Leva, LevaPanel, useControls, useCreateStore } from "leva";
import type { StoreType } from "leva/dist/declarations/src/types";
import { createPlugin } from "leva/plugin";
import { useEffect, useMemo, useRef, useState } from "react";
import { Inspector as ReactInspector } from "react-inspector";
import type { Hex } from "viem";

const ChildrenInspectorField = ({ entityRef }: { entityRef: Hex }) => {
	return (
		<div className="m-auto flex-inline bg-black/50 rounded-full p-1 px-3">
			{formatBytes32React(entityRef as Hex)}
		</div>
	);
};

const ComponentInspector = ({ component }: { component: SimComponent }) => {
	return (
		<div className="py-1">
			<div className="text-xxs text-cyan-300/90 inline">
				{component.constructor.name}
			</div>
			{Object.entries(component).map(([key, value]) => {
				if (
					(typeof value !== "object" && typeof value !== "function") ||
					value === undefined ||
					value === null
				) {
					const textvalue = JSON.stringify(value);
					const isUndefined = value === undefined || value === null;
					return (
						<div key={key} className="grid grid-cols-2 gap-2">
							<div className="text-xxs text-gray-400 inline">{key}</div>
							<div
								className={cn("text-xxs inline", isUndefined && "opacity-50")}
							>
								{textvalue}
							</div>
						</div>
					);
				}
			})}
		</div>
	);
};

const ComponentInspectorList = ({ entity }: { entity: SimEntity }) => {
	return (
		<div className="pb-2">
			{entity._components.flatMap((c) => (
				<>
					{c.debugInspector?.() || []}
					<ComponentInspector component={c} key={c.constructor.name} />
				</>
			))}
		</div>
	);
};

const HierarchyEntry = ({ entity }: { entity: SimEntity }) => {
	const [collapsed, setCollapsed] = useState(true);
	return (
		<div className="flex flex-col mb-2 mr-2">
			<div
				onClick={() => setCollapsed(!collapsed)}
				className="-mr-2 flex flex-col"
			>
				<div className="flex flex-row flex-grow">
					<div
						className={cn(
							"inline-block text-sm mr-2 select-none text-gray-600 hover:text-white cursor-pointer",
							!collapsed && "rotate-90 text-white",
						)}
					>
						▶
					</div>
					<div
						className="inline text-gray-200 flex-grow hover:underline cursor-pointer"
						onClick={(e) => {
							console.log(entity);
							UIStore().setHoveredObject(entity.ref);
							e.stopPropagation();
						}}
					>
						{entity.name}{" "}
					</div>
					<div className="flex-row inline items-end">
						{formatBytes32React(entity.ref as Hex)}
					</div>
					<div
						className="inline text-gray-200 flex-grow hover:underline cursor-pointer"
						onClick={async (e) => {
							const { test_destroyEntity } = getSystemCalls();
							const { playerEntity } = getNetwork();
							console.log("🌱 destroying", entity);
							await test_destroyEntity(playerEntity!, entity.ref as Hex);
							e.stopPropagation();
						}}
					>
						❌
					</div>
				</div>
			</div>
			<div className="max-w-[400px]">
				{!collapsed && (
					<div className="mb-1 ml-2">
						<ReactInspector
							theme="chromeDark"
							data={{
								ref: entity.ref,
								name: entity.name,
								components: entity._components,
								children: entity._children,
								parent: entity._parent,
							}}
							expandLevel={3}
							name={entity.name}
							table={false}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

const Hierarchy = () => {
	const [filter, setFilter] = useState("");
	const inputRef = useRef<HTMLInputElement>(null);
	const entities = useWorldStore((state) => state.entities);

	useEffect(() => {
		hotkeys("shift+f", (event, _handler) => {
			event.preventDefault();
			inputRef.current?.focus();
		});
		return () => {
			hotkeys.unbind("shift+f");
		};
	}, []);

	const filteredEntities = useMemo(() => {
		if (!entities) return [];
		return entities.filter((e) => {
			if (filter === "") return true;
			return (
				e.name.toLowerCase().includes(filter.toLowerCase()) ||
				e.ref.toLowerCase().includes(filter.toLowerCase())
			);
		});
	}, [entities, filter]);

	return (
		<div
			className="text-white"
			onMouseMove={(e) => e.stopPropagation()}
			onClick={(e) => e.stopPropagation()}
		>
			<input
				ref={inputRef}
				className="pointer-events-auto button mb-2 w-full bg-transparent text-white p-1 px-2 border border-gray-600 rounded-md"
				type="text"
				placeholder="Filter"
				value={filter}
				onChange={(e) => {
					// console.log(e.target.value);
					setFilter(e.target.value);
				}}
				onClick={(e) => {
					console.log(e);
					inputRef.current?.focus();
				}}
			/>
			{filter.length > 0 && (
				<div
					className="inline-block mb-1 right-0 text-gray-500 outline-none"
					style={{ fontSize: "8px" }}
				>
					{filteredEntities.length} matches
				</div>
			)}
			{filteredEntities.map((entity) => (
				<HierarchyEntry key={entity.ref} entity={entity} />
			))}
		</div>
	);
};

const InspectorPanel = () => {
	const [lastHovered, setLastHovered] = useState<string | undefined>();
	const { hoveredObject } = useUIStore((state) => state);
	const entity = useMemo(() => {
		if (!hoveredObject && !lastHovered) return;
		if (hoveredObject) setLastHovered(hoveredObject);
		const entity = getEntityByRef(hoveredObject || lastHovered);
		if (!entity) return;
		return entity;
	}, [hoveredObject, lastHovered]);

	const [shouldUpdate, setShouldUpdate] = useState(true);

	useEffect(() => {
		if (!entity) return;
		const onUpdate = () => {
			setShouldUpdate(true);
		};
		entity.listenForEvent("onUpdate", onUpdate);
		return () => {
			entity.removeEventListener("onUpdate", onUpdate);
		};
	}, [entity]);

	const inspector = useMemo(() => {
		if (!entity) return null;
		setShouldUpdate(false);
		return (
			<div className="mb-1 pb-1">
				<ComponentInspectorList entity={entity} />
				<div className={cn("hover:grayscale-0 grayscale")}>
					<ReactInspector
						theme="chromeDark"
						data={{
							ref: entity.ref,
							name: entity.name,
							components: entity._components,
							children: entity._children,
							parent: entity._parent,
						}}
						expandLevel={0}
						name={entity.name}
						table={false}
					/>
				</div>
			</div>
		);
	}, [entity, shouldUpdate]);

	return (
		<>
			{entity && (
				<div className="bg-[#292D39] -mx-2 px-2 -my-2 mb-2 text-white">
					<div className="flex flex-col mb-2">
						<div className="flex flex-row py-1">
							<div
								className="inline flex-grow"
								onClick={() => {
									console.log(entity);
								}}
							>
								🔍 {entity.name}{" "}
							</div>
							<div className="flex-row inline items-end">
								{formatBytes32React(entity.ref as Hex)}
							</div>
						</div>
						<div className="flex flex-row flex-wrap gap-[0.125rem]">
							{entity._children.map((child) => (
								<ChildrenInspectorField key={child} entityRef={child as Hex} />
							))}
						</div>
						{inspector}
					</div>
				</div>
			)}
			<Hierarchy />
		</>
	);
};

const inspector = createPlugin({
	component: InspectorPanel,
});

const Inspector = ({ store }: { store: StoreType }) => {
	useControls({ inspector: inspector() }, { store });
	return null;
};

export function DevToolPanel() {
	const gameSettings = useMUDStore((state) =>
		state.getRecord(tables.GameSettings, {}),
	);

	// we hand off the store so it can do HMR refreshes
	const store = useCreateStore();

	useEffect(() => {
		const handleEscape = (e: KeyboardEvent) => {
			if (e.key === "Escape") {
				toggleDev();
			}
		};

		window.addEventListener("keydown", handleEscape);
		return () => {
			window.removeEventListener("keydown", handleEscape);
		};
	}, []);

	return (
		<>
			<Leva titleBar={false} />
			<LevaPanel store={store} titleBar={{ filter: false }} />
			<Inspector store={store} />
			<div className="fixed bottom-4 left-4 z-index-[1000000000000000000000] pointer-events-auto">
				<div className="flex flex-col gap-2">
					{gameSettings?.value.lastUpdate.toString() || "UPDATE MISSING"}
				</div>
			</div>
		</>
	);
}
