import { getColors } from "@/data/colors/palette.data";
import {
	randomFromArray,
	seededRand,
	type TSeededRand,
} from "@/lib/random/seeded.functions";
import { Interactable } from "@game/input/interactable";
import useEntityUpdate from "@game/sim/useEntityUpdate";
import { useCallback, useMemo, useRef } from "react";
import {
	BufferAttribute,
	Color,
	DoubleSide,
	Euler,
	type Mesh,
	type MeshStandardMaterial,
	Vector3,
	type Vector3Tuple,
} from "three";
import { CRenderer, type TRendererProps } from "../sim/components/CRenderer";
import { UIStore } from "@game/ui/ui.store";
import { CGrowable } from "@game/sim/components/CGrowable";
import { CBurnable } from "@game/sim/components/CBurnable";
import {
	registerRenderData,
	SHARED_RENDERDATA,
} from "@/lib/batching/sharedData";
import { getMesh, getTexture } from "@/data/assetLoader/asset.store";
import {
	defaultFireMaterial,
	defaultFireUpdate,
} from "@/lib/batching/batching.utils";
import { useBlock } from "@/lib/useBlock";
import type { SimEntity } from "@game/sim/SimEntity";
import { getPointAt, growthCurve } from "@/lib/prefab.utils";
import { useBatchedRenderer } from "@/lib/batching/useBatchedRenderer";
import { createTransformationMatrix } from "@/lib/placement.utils";
import { BatchedRenderStore } from "@game/components/batchSystem";
import { clamp01 } from "@/lib/utils";
import { useFrame } from "@react-three/fiber";
import { useBurnableUpdate } from "@game/sim/useBurnableUpdate";

registerRenderData("jungle", () => {
	const plantsMesh = getMesh("jungleplants/jungleplants/jungle000");
	const alphaMap = getTexture("jungleplants/plantas_dif_007_alpha");
	const map = (plantsMesh?.material as MeshStandardMaterial).map;
	const baseMaterial = {
		map: map,
		color: new Color("white"),
		metalnessMap: map,
		emissiveMap: map,
		alphaMap: alphaMap,
		transparent: true,
		depthWrite: true,
		side: DoubleSide,
	};
	const materials = {
		fire: defaultFireMaterial({
			...baseMaterial,
			scale: 0.2,
		}),
	};
	return {
		materials,
		meshes: {
			plants: plantsMesh,
		},
		onUpdate: (state, delta, self) => defaultFireUpdate(state, delta, self),
	};
});

const getJungleVariation = (entity: SimEntity, rng: TSeededRand) => {
	const block = entity.component(CRenderer).block;
	const pos = block!.position.clone().add(new Vector3(0, -0.265, 0));
	const rot = [
		(rng(0, 1000) / 1000) * 1 - 0.5,
		(rng(0, 1000) / 1000) * 360,
		(rng(0, 1000) / 1000) * 1 - 0.5,
	] as [number, number, number];
	const maxScale = rng(1.525, 1.55) * 0.09;
	const color = randomFromArray(getColors("jungle"), rng).hex;
	const geometry = SHARED_RENDERDATA.jungle?.meshes?.plants?.geometry!.clone()!;
	const burnable = entity.component(CBurnable);
	let initialProgress = 0;
	if (burnable.isBurned()) initialProgress = 1;
	if (burnable.isBurning()) initialProgress = 0.5;
	geometry.setAttribute(
		"progress",
		new BufferAttribute(
			new Float32Array(geometry.attributes.position.count).fill(
				initialProgress,
			),
			1,
		),
	);
	const material = SHARED_RENDERDATA.jungle?.materials?.fire!;
	return {
		color,
		rot,
		pos,
		geometry,
		maxScale,
		material,
		initialProgress,
	};
};

export function JungleRenderer({ entity }: TRendererProps) {
	const { block, rng } = useBlock({ entity });
	const meshRef1 = useRef<Mesh>(null!);

	const burnable = useMemo(() => entity.component(CBurnable), [entity]);

	const { pos, rot, maxScale, color, geometry, material, initialProgress } =
		useMemo(() => getJungleVariation(entity, rng), [entity, rng]);

	const progressRef = useRef(initialProgress);
	const scale = useRef<number[]>([0, 0, 0]);

	const data = useBatchedRenderer(
		entity,
		() => {
			const s = scale.current as Vector3Tuple;
			return {
				geometry,
				material,
				opts: {
					// allowShared: true,
					color: new Color(color),
					matrix: createTransformationMatrix(
						pos.clone().add(new Vector3(0, 0, 0)),
						rot,
						s,
					),
				},
			};
		},
		[entity],
	);

	const doGrow = useCallback(() => {
		if (!block || !data) return;
		const growable = entity.component(CGrowable);
		if (!growable) {
			return;
		}
		const { growthPercent: percent } = growable;
		if (UIStore().hoveredObject === entity.ref) {
			console.log(percent);
		}
		const s = Math.max(0.01, getPointAt(growthCurve, percent) * maxScale || 0);
		if (scale.current[0] === s) return;
		scale.current = [s, s, s];
		meshRef1.current?.scale.set(s, s, s);
		const matrix = createTransformationMatrix(
			pos.clone().add(new Vector3(0, 0, 0)),
			rot,
			[s, s, s],
		);
		BatchedRenderStore().setGeometryMatrix(material, data!.geometryId, matrix);
	}, [entity, block, maxScale, data, pos, rot, material]);

	useBurnableUpdate({
		progressRef,
		burnable,
		entity,
		data,
		material,
		geometry,
	});

	useEntityUpdate(
		() => {
			doGrow();
		},
		entity,
		[entity, doGrow],
	);

	const renderer = useMemo(() => {
		const s = Math.max(0.2, scale.current[0]) * 8;
		return (
			<Interactable
				entity={entity}
				position={pos}
				// args={new Vector3(...scale.current).multiplyScalar(1.3).toArray()}
				collider="Sphere"
				isInteractable={true}
				// debug={true}
				// geometry={geometry}
				scale={new Vector3(s, s, s)}
				rotation={new Euler(...rot) as Euler}
			/>
		);
	}, [entity, pos, rot, scale.current]);

	return <>{renderer}</>;
}
