import { setupPlayer } from "@game/game.functions";
import { defaultState, registerUIStates } from "@game/ui/ui.states";
import { ItemShopStore } from "./itemShop.store";
import Audio from "@game/audio/audioPlayer";

const baseStore = {
	...defaultState,
	showGameLogo: false,
	showItemShop: true,
	showWalletButton: true,
	backgroundLoading: true,
	showInventory: true,
	showDetailPane: true,
	showPurchaseWindow: true,
	allowInput: false,
	onEnter: () => {
		ItemShopStore().deselectAllProducts();
	},
};

const StoreStates = {
	store: {
		...baseStore,
	},
	loadOutStore: {
		...baseStore,
		isLoadoutStore: true,
		onEnter: () => {},
	},
	signUp: {
		...defaultState,
		showItemShop: true,
		showWalletButton: true,
		backgroundLoading: true,
		showSignup: true,
		onExit: () => {
			ItemShopStore().set({
				detailedProduct: ItemShopStore().playpass,
			});
		},
	},
	receivePlaypass: {
		...defaultState,
		showItemShop: true,
		showWalletButton: true,
		backgroundLoading: true,
		showDetailPane: true,
		confirmPass: true,
		onEnter: () => {
			Audio.playSound("PICKUP_GONG");
			Audio.playMusic("MUSIC_STORMBIRD");
			setupPlayer();
		},
		onExit: () => {
			const mysterySeed = ItemShopStore().products.find(
				(p) => p.name === "Mystery Seed",
			);
			if (mysterySeed) {
				mysterySeed.selected = true;
				ItemShopStore().set({
					detailedProduct: mysterySeed,
				});
			}
		},
	},
};
registerUIStates(StoreStates);

export { StoreStates };
