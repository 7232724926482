import { UserStore } from "@/data/user-settings.store";
import { Debug } from "@/lib/debug/debug";
import { getPlayerContainer } from "@game/game.functions";
import { GameStates } from "@game/game.states";
import {
	defaultState,
	registerUIStates,
	setUIState,
	startTime,
} from "@game/ui/ui.states";
import { UIStore } from "@game/ui/ui.store";

const GameMenuStates = {
	mainMenu: {
		...defaultState,
		requiresAuth: false,
		showLoadingScreen: false,
		showGameLogo: true,
		onEnter: async () => {
			Debug("⏱️ TIME").log(`menu: ${Date.now() - startTime}ms`);
			if (UserStore().isCinematic()) {
				Debug("Game").log("Cinematic mode");
				setUIState(GameStates.cinematic);
				return;
			}
			// check if player or spectator
			const pc = await getPlayerContainer();
			if (pc) {
				if (UserStore().isPlayer() && UIStore().authenticated) {
					setUIState(GameStates.playMode);
					return;
				}
			}
			if (UserStore().isSpectator()) {
				setUIState(GameStates.tourismMode);
				return;
			}
			setUIState(GameMenuStates.readyToSignup);
		},
	},
	readyToSignup: {
		...defaultState,
		requiresAuth: false,
		showGameLogo: true,
		showItemShop: false,
		showSignupButton: true,
		onEnter: () => {
			Debug("⏱️ TIME").log(`readyToSignup: ${Date.now() - startTime}ms`);
		},
	},
};
registerUIStates(GameMenuStates);

export { GameMenuStates };
