import { bool, cleanEnv, num, str } from "envalid";
import queryString from "query-string";
import { fromBase64 } from "./utils.hash";

const sanitize = (value: unknown) => {
	if (value === undefined) return undefined;
	if (value === null) return true;
	if (typeof value === "boolean") return value;
	// @dev sanityze other values
	const element = document.createElement("div");
	element.innerText = value as string;
	return element.innerHTML;
};

const parseAndSanitizeOptions = (search: string) => {
	// decode base64 params
	const params = (() => {
		const a = queryString.parse(search);
		if (a.C) {
			return queryString.parse(fromBase64(a.C as string));
		}
		return a;
	})();
	const getParam = (key: string) => sanitize(params[key]);

	const sanitized = {
		VITE_CHAIN_ID: getParam("chain"),
		VITE_STORE_CHAIN_ID: getParam("storeChain"),
		VITE_SIGILS_SERVER: getParam("sigils"),
		VITE_FAUCET: getParam("faucet"),
		VITE_INDEXER: getParam("indexer"),
		VITE_ANALYTICS: getParam("analytics"),
		WORLD_ADDRESS: getParam("worldAddress"),
		INITIAL_BLOCK_NUMBER: getParam("initialBlockNumber"),
		SPLOOF: getParam("idkfa"),
		SHOW_HEX_BORDERS: getParam("showHexBorders"),
		VERBOSE: getParam("verbose"),
		SPECTATOR: getParam("spectator"),
		CINEMATIC: getParam("cinematic"),
		CINEMATIC_SPEED: getParam("c_speed"),
		CINEMATIC_ZOOM: getParam("c_zoom"),
		DEV_SIGNUP: getParam("devSignup"),
		BYPASS_PAYMENTS: getParam("bypassPayments"),
		ACCESS_CODE: getParam("accessCode"),
		REF_ID: getParam("ref_id"),
	};
	// remove undefined values
	return Object.fromEntries(
		Object.entries(sanitized).filter(([_, v]) => v !== undefined),
	);
};

type TQueryParams = ReturnType<typeof parseAndSanitizeOptions>;

export const QUERY_PARAMS: Partial<TQueryParams> =
	typeof window !== "undefined"
		? parseAndSanitizeOptions(window.location.search)
		: {};

export const ENV = cleanEnv(
	{ ...import.meta.env, ...QUERY_PARAMS },
	{
		VITE_CHAIN_ID: num({ default: 31337 }),
		VITE_STORE_CHAIN_ID: num(),
		VITE_SIGILS_SERVER: str({ default: "jungleparty.cygnusfear.partykit.dev" }),
		VITE_FAUCET: str({ default: undefined }),
		VITE_INDEXER: str({ default: undefined }),
		VITE_ANALYTICS: str({
			default: "https://manada-analytics.cygnusfear.workers.dev",
		}),
		VITE_ACCESS_SECRET: str({ default: "GODMODE" }),
		DEV: bool({ default: false }),
		PORT: num({ default: 3000 }),
		MODE: str({
			choices: ["development", "test", "production", "staging"],
			default: "production",
		}),
		WORLD_ADDRESS: str({ default: undefined }),
		BASE_URL: str({ default: "" }),
		SPLOOF: bool({ default: false }),
		SHOW_HEX_BORDERS: bool({ default: false }),
		VERBOSE: bool({ default: false }),
		SPECTATOR: bool({ default: false }),
		CINEMATIC: bool({ default: false }),
		CINEMATIC_SPEED: num({ default: 2 }),
		CINEMATIC_ZOOM: num({ default: 1 }),
		DEV_SIGNUP: bool({ default: false }),
		BYPASS_PAYMENTS: bool({ default: false }),
		ACCESS_CODE: str({ default: undefined }),
		REF_ID: str({ default: undefined }),
	},
);

if ((ENV.DEV && ENV.VERBOSE) || ENV.SPLOOF) {
	console.log(import.meta.env, QUERY_PARAMS, { ...ENV });
}
