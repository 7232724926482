import { getEntityByRef } from "@game/sim/sim.store";
import { useWorldStore } from "@game/world/world.store";
import { useEffect, useState } from "react";
import type { Hex } from "viem";
import BottomLayout from "../ui.bottom";
import { InventoryStore, useInventoryStore } from "./inventory.store";
import { ItemCard } from "./itemCard";
import { UILayout } from "../ui.layout";
import { getNetwork, tables, useMUDStore } from "@mud/index";
import ShopIcon from "./shopIcon";

const EMPTY_SLOT =
	"0x0000000000000000000000000000000000000000000000000000000000000000";

export default function InventoryBar() {
	const [shouldUpdate, setShouldUpdate] = useState(true);
	const playerRef = useWorldStore((state) => state.playerRef);

	const playerContainer = useMUDStore((state) =>
		state.getValue(tables.Container, {
			inst: getNetwork().playerEntity as Hex,
		}),
	);
	const { inventorySlots } = useInventoryStore((state) => {
		return {
			inventorySlots: state.inventorySlots,
		};
	});

	useEffect(() => {
		shouldUpdate; // Exhaustive_dependencies_hack: force update on shouldUpdate
		const currentPlayer = getEntityByRef(playerRef);
		const onReceiveItem = async () => {
			setShouldUpdate(true);
		};
		if (!currentPlayer || !playerContainer) return;

		// We sync the inventory slots up with the onchain state, but we want to maintain the clientside order of the items
		const updateInventoryItems = () => {
			const onchainInventory = playerContainer.slots.map((s) => {
				return s || null;
			});
			const existingInventory =
				InventoryStore().inventorySlots.length === onchainInventory.length
					? [...InventoryStore().inventorySlots]
					: onchainInventory;

			// loop over existing inventory and remove items that are no longer in the new inventory
			for (let i = 0; i < existingInventory.length; i++) {
				if (existingInventory[i] === undefined) {
					throw new Error("undefined inventory slot");
				}
				if (existingInventory[i] === EMPTY_SLOT) {
					continue;
				}
				// item is no longer in the inventory
				if (onchainInventory.indexOf(existingInventory[i]!) === -1) {
					existingInventory[i] = EMPTY_SLOT;
				}
			}

			for (let i = 0; i < onchainInventory.length; i++) {
				if (onchainInventory[i] === EMPTY_SLOT) {
					continue;
				}
				// find first empty slot and add item
				if (existingInventory.indexOf(onchainInventory[i]!) === -1) {
					const idx = existingInventory.indexOf(EMPTY_SLOT);
					existingInventory[idx] = onchainInventory[i];
				}
			}

			InventoryStore().set({
				inventorySlots: existingInventory,
			});
		};

		updateInventoryItems();
		setShouldUpdate(true);
		currentPlayer.listenForEvent("onSlotsChanged", onReceiveItem);

		return () => {
			currentPlayer.removeEventListener("onSlotsChanged", onReceiveItem);
		};
	}, [playerRef, playerContainer?.slots, playerContainer, shouldUpdate]);

	return (
		<UILayout data-name="inventory-bar">
			<BottomLayout className="flex-row select-none">
				{inventorySlots.map((ref, idx) => {
					if (idx === inventorySlots.length - 1) return null; // last slot is empty
					const item = getEntityByRef(ref) || null;
					return <ItemCard key={idx} item={item} slotNum={idx} />;
				})}
				<ShopIcon />
			</BottomLayout>
		</UILayout>
	);
}
