import { SplineCurve, Vector2 } from "three";
import { clamp01 } from "./utils";

export const growthCurve = new SplineCurve([
	new Vector2(-0.01, 0.15),
	new Vector2(0.5, 0.19),
	new Vector2(0.501, 0.5),
	new Vector2(0.75, 0.55),
	new Vector2(0.751, 0.95),
	new Vector2(1.01, 1),
]);

export const getPointAt = (curve: SplineCurve, t: number) => {
	const tClamped = clamp01(t || 0);
	const point = curve.getPointAt(tClamped);
	return point.y;
};
