import { Debug } from "@/lib/debug/debug";
import { UI_EVENTS } from "@game/ui/ui.events";
import { UIStore } from "@game/ui/ui.store";
import { WorldStore } from "@game/world/world.store";
import type { MouseEvent } from "react";
import type { Hex } from "viem";
import { InventoryStore } from "./inventory.store";
import type { SimEntity } from "@game/sim/SimEntity";
import { CContainer } from "@game/sim/components/CContainer";
import { CPlayerActionPicker } from "@game/sim/components/CPlayerActionPicker";
import { TheMouseData } from "@game/input/theInput";

export const onInventorySlotClick = async (
	item: SimEntity | null,
	slotNum: number,
	event: MouseEvent,
) => {
	if (UIStore().activeAction) return;
	const button = event.button;
	const player = WorldStore().getPlayer();
	if (!player) return;
	const activeItem = InventoryStore().getActiveItem();

	const setActiveItem = (item: SimEntity | null) => {
		const inventorySlots = [...InventoryStore().inventorySlots];
		const oldItem = inventorySlots[inventorySlots.length - 1];
		inventorySlots[inventorySlots.length - 1] = item?.ref as Hex;
		inventorySlots[slotNum] = oldItem;
		InventoryStore().set({
			inventorySlots,
		});
		player.component(CContainer).swapSlots(slotNum, inventorySlots.length - 1);
	};

	const putActiveItemInSlot = (slotNum: number) => {
		const inventorySlots = [...InventoryStore().inventorySlots];
		const firstSlot = inventorySlots[inventorySlots.length - 1];
		inventorySlots[inventorySlots.length - 1] = inventorySlots[slotNum];
		inventorySlots[slotNum] = firstSlot;
		InventoryStore().set({
			inventorySlots,
		});
		player.component(CContainer).swapSlots(slotNum, inventorySlots.length - 1);
	};

	// does slot have an item
	if (item !== null) {
		// put item back in place
		if (item === activeItem) {
			setActiveItem(null);
			return;
		}

		// check RMB use actions on invItem
		if (
			activeItem === undefined &&
			button === 2 &&
			UIStore().altActions.length > 0
		) {
			const action = UIStore().altActions[0];
			action.do();
			return;
		}

		// pick up item
		if (button === 0 && item && activeItem === undefined) {
			Debug("Inventory", "verbose").capture(`active_item_${item.name}`, {
				item: item.name,
				slot: slotNum,
			});
			setActiveItem(item);
			return;
		}

		// test for actions on existing item
		if (activeItem !== undefined && item !== activeItem) {
			if (button === 0 && UIStore().actions.length > 0) {
				const action = UIStore().actions[0];
				action.do();
				return;
			}
			if (button === 2 && UIStore().altActions.length > 0) {
				const action = UIStore().altActions[0];
				action.do();
				return;
			}
			setActiveItem(item);
		}
		return;
	}

	// slot is empty, put active item in slot
	if (item === null && activeItem) {
		putActiveItemInSlot(slotNum);
	}
};

export const onInventorySlotPointerMove = async (item: SimEntity | null) => {
	if (!UIStore().isHoveringSlot) {
		UIStore().set({
			isHoveringSlot: true,
		});
	}
	TheMouseData.hoveringUI = true;
	const activeAction = UIStore().activeAction;
	const activeItem = InventoryStore().getActiveItem();
	if (item) {
		if (UIStore().hoveredItem !== item) {
			UIStore().set({
				hoveredItem: item,
			});
		}
	}
	if (activeItem !== item && item !== null) {
		const player = WorldStore().getPlayer();
		if (!player || activeAction) return;
		const actions = player.component(CPlayerActionPicker).getClickActions({
			target_ent: item!,
		});
		const altActions = player
			.component(CPlayerActionPicker)
			.getAltClickActions({
				target_ent: item!,
			});
		UIStore().set({
			actions: actions || [],
			altActions: altActions || [],
		});
	}
};

export const onInventorySlotPointerOut = async (_item: SimEntity | null) => {
	UIStore().set({
		hoveredItem: undefined,
		isHoveringSlot: false,
	});
	TheMouseData.hoveringUI = false;
	UI_EVENTS.emit("getPlayerActions");
};
