import { logVerbose } from "@/lib/devtools/dev.store";
import { Debug } from "@/lib/debug/debug";
import type { Hexagrid } from "@/lib/hexagrid/hexagrid";
import { createGrid } from "./world.functions";
import type { THexCoordinate } from "./world.types";

export const gridRefs = new Map<string, Hexagrid>();
export const getGrid = (coordinate: THexCoordinate) => {
	let grid = gridRefs.get(coordinate.toString());
	if (!grid) {
		grid = createGrid(coordinate);
		gridRefs.set(coordinate.toString(), grid);
		logVerbose() && Debug("World").log("created Hex:", coordinate.toString());
		return grid;
	}
	return grid;
};