import { clamp01 } from "@/lib/utils";
import {
	BatchedRenderStore,
	type TBatchedGeometryData,
} from "@game/components/batchSystem";
import { useFrame } from "@react-three/fiber";
import { BufferAttribute, type BufferGeometry, type Material } from "three";
import type { CBurnable } from "./components/CBurnable";
import type { MutableRefObject, Ref } from "react";
import type { SimEntity } from "./SimEntity";

export const useBurnableUpdate = ({
	progressRef,
	burnable,
	data,
	material,
	geometry,
}: {
	progressRef: MutableRefObject<number>;
	burnable: CBurnable;
	entity: SimEntity;
	data: TBatchedGeometryData | null;
	material: Material;
	geometry: BufferGeometry;
}) => {
	useFrame((_state, delta) => {
		if (!data || !material || progressRef?.current === undefined) return;
		// @dev (FIRE MATERIAL) all this code is for updating the progress in the geometry attributes
		if (burnable) {
			if (burnable.isBurning() && progressRef.current < 0.5) {
				progressRef.current += delta * 0.4;
				if (progressRef.current > 0.5) {
					progressRef.current = 0.5;
				}
			}
			if (burnable.isBurned() && progressRef.current <= 1) {
				progressRef.current += delta * 0.3;
				if (progressRef.current >= 1) {
					progressRef.current = 1;
				}
			}
			if (progressRef.current !== geometry.getAttribute("progress").array[0]) {
				progressRef.current = clamp01(progressRef.current);
				// console.log("updating", progressRef.current, burnable.isBurning());
				geometry.setAttribute(
					"progress",
					new BufferAttribute(
						new Float32Array(geometry.attributes.position.count).fill(
							progressRef.current,
						),
						1,
					),
				);
				BatchedRenderStore().updateGeometry(
					material,
					geometry,
					data.geometryId,
				);
			}
		}
	});

	return null;
};
