import { getMUDState, getSystemCalls, tables } from "@mud";
import type { Hex } from "viem";
import { SimComponent } from "./SimComponent";
import type { SimEntity } from "../SimEntity";

export class CBurnable extends SimComponent {
	flashpoint = 0;
	heat = 0;
	fuel = 0;
	spreading = false;

	value = () =>
		getMUDState().getValue(tables.Burnable, {
			inst: this.inst.ref as Hex,
		})!;

	async loadFromChain(): Promise<void> {
		if (!this.value()) {
			console.warn(`no value for ${this.constructor.name}`, this.inst.ref);
			this.inst.clearComponent(CBurnable);
			return;
		}
		this.flashpoint = this.value().flashpoint;
		this.heat = this.value().currentHeat;
		this.fuel = this.value().fuel;
		if (!this.spreading && this.value().spreading)
			this.inst.pushEvent("onStartBurning", {});
		if (this.spreading && !this.value().spreading)
			this.inst.pushEvent("onStopBurning", {});
		this.spreading = this.value().spreading;
	}

	isIgnitable() {
		return this.fuel > 0 && !this.spreading;
	}

	isBurned() {
		return this.fuel <= 0 && !this.spreading;
	}

	isBurning() {
		return this.heat > 0 && this.spreading;
	}

	ignite(doer: SimEntity) {
		const { action_burnEntity } = getSystemCalls();
		action_burnEntity(doer.ref, this.inst.ref);
	}

	getProgress() {
		if (this.isBurning()) return 0.5;
		if (this.isBurned()) return 1;
		return 0;
	}
}
