import { UIStore } from "./ui.store";
import { Debug } from "@/lib/debug/debug";
import { logVerbose } from "@/lib/devtools/dev.store";

export type TUIState = {
	[key: string]: boolean | (() => void) | undefined;
	requiresAuth: boolean;
	showInventory?: boolean;
	showTourismbar?: boolean;
	showWalletButton?: boolean;
	showLoadingScreen?: boolean;
	showGameLogo?: boolean;
	showItemShop?: boolean;
	showSignupButton?: boolean;
	backgroundLoading?: boolean;
	onEnter?: () => void;
	onExit?: () => void;
};

export let startTime = Date.now();

function findStateKeyByValue(obj: TUIState) {
	return Object.entries(UIStateRegistry).find((e) => e[1] === obj)?.[0];
}

export const setUIState = (newState: TUIState) => {
	const nextState = newState;
	if (!nextState) {
		throw new Error(`No UI state found for ${newState}`);
	}
	const currentState = UIStore().uiState;
	if (currentState === nextState) return;

	currentState?.onExit?.();
	logVerbose() &&
		Debug("UI-STATE").log(
			`exited: ${findStateKeyByValue(currentState)} entered: ${findStateKeyByValue(nextState)}`,
		);
	UIStore().gotoState(nextState);
	nextState.onEnter?.();
};

export const prevUIState = () => {
	const currentState = UIStore().uiState;
	setUIState(UIStore().prevState || currentState);
};

export const defaultState: TUIState = {
	requiresAuth: true,
	showInventory: false,
	showTourismbar: false,
	showWalletButton: false,
	showLoadingScreen: false,
	showGameLogo: false,
	showItemShop: false,
	showSignupButton: false,
	onEnter: () => {
		Debug("⏱️ TIME").log(`default: ${Date.now() - startTime}ms`);
		startTime = Date.now();
	},
};

export const UIStates = {} as const;

// Derive the type of valid state keys from the UIStates object
export type TUIStates = keyof typeof UIStates;

// Create a central registry initialized with the default states
const UIStateRegistry: Record<string, TUIState> = { ...UIStates };
// Function to register new UI states from an object with type safety

export const registerUIStates = (states: Record<string, TUIState>) => {
	Object.entries(states).forEach(([key, state]) => {
		if (UIStateRegistry[key]) {
			Debug("UI-STATE").warn(`State "${key}" already exists, overwriting it`);
		}
		UIStateRegistry[key] = state;
	});
};
// Function to register a new UI state with type safety

export const registerUIState = <K extends string>(
	key: K extends TUIStates ? never : K,
	state: TUIState,
) => {
	if (UIStateRegistry[key]) {
		Debug("UI-STATE").warn(`State "${key}" already exists, overwriting it`);
	}
	UIStateRegistry[key] = state;
};
// Function to retrieve a UI state by key

export const getUIState = (key: TUIStates | string): TUIState | undefined => {
	return UIStateRegistry[key];
};
