import { baseMesh, getMesh } from "@/data/assetLoader/asset.store";
import { SHARED_RENDERDATA } from "@/lib/batching/sharedData";
import { useBatchedRenderer } from "@/lib/batching/useBatchedRenderer";
import { deformMesh } from "@/lib/deformation/meshDeformation";
import { createTransformationMatrix } from "@/lib/placement.utils";
import {
	type TSeededRand,
	randomFromArray,
	seededRand,
} from "@/lib/random/seeded.functions";
import { useBlock } from "@/lib/useBlock";
import { Interactable } from "@game/input/interactable";
import { CBurnable } from "@game/sim/components/CBurnable";
import { CDecorator } from "@game/sim/components/CDecorator";
import { CPaint } from "@game/sim/components/CPaint";
import { CRenderer } from "@game/sim/components/CRenderer";
import { CVariant } from "@game/sim/components/CVariant";
import type { SimEntity } from "@game/sim/SimEntity";
import { useBurnableUpdate } from "@game/sim/useBurnableUpdate";
import { useEffect, useMemo, useRef, useState } from "react";
import { type Vector3Tuple, BufferAttribute } from "three";

const m = baseMesh("modular_set/modular_set");

const buildingModules = [
	m("Building_module_ac000"),
	m("Building_module_window.004"),
	m("Building_module_window.005"),
	m("Building_module_window.006"),
	m("Building_module_window.007"),
	m("Building_module_window.008"),
	m("Building_module_window.009"),
	m("Building_module_window.010"),
	m("Building_module_door.000"),
	m("Building_module_door.001"),
	// "Building_module_window.011",
	// "Building_module_window.013",
	// "Building_module_window.014",
];

const getModuleProperties = (entity: SimEntity) => {
	const parent = entity.parent()!;
	if (!parent) {
		throw new Error("no parent");
	}
	const rng = seededRand(entity.getSeed() * 2000);
	const block = parent.component(CRenderer).block;
	const burnable = parent.component(CBurnable);
	if (!block || !burnable) {
		throw new Error("no block");
	}
	const orientation = parent.component(CDecorator).getOrientation(entity);
	const randomModule = getMesh(randomFromArray(buildingModules, rng))!;
	const geometry = deformMesh(randomModule, block, orientation).geometry;
	geometry.setAttribute(
		"progress",
		new BufferAttribute(
			new Float32Array(geometry.attributes.position.count).fill(
				parent.component(CBurnable)?.getProgress() || 0,
			),
			1,
		),
	);
	const material = SHARED_RENDERDATA.scaffold?.materials?.fire!;
	const color = CPaint.getPaint(entity.component(CVariant).color);
	return {
		color,
		pos: block!.position.clone(),
		rot: [0, 0, 0],
		orientation: parent.component(CDecorator).getOrientation(entity),
		geometry,
		material,
		burnable,
	};
};

export const WallModuleRenderer = ({ entity }: { entity: SimEntity }) => {
	const { pos } = useBlock({ entity: entity.parent()! });
	const progressRef = useRef(0);
	const [color, setColor] = useState(
		CPaint.getPaint(entity.component(CVariant).color),
	);

	const { rot, burnable, geometry, material } = useMemo(
		() => getModuleProperties(entity),
		[entity, entity.component],
	);

	const data = useBatchedRenderer(
		entity.parent()!,
		() => {
			return {
				geometry,
				material,
				opts: {
					matrix: createTransformationMatrix(
						pos.clone(),
						rot,
						[2.02, 2.02, 2.02],
					),
					// color,
					userData: { entityRef: entity.ref },
				},
			};
		},
		[entity, geometry, color],
	);

	useBurnableUpdate({
		progressRef,
		burnable,
		entity,
		data,
		material,
		geometry,
	});

	return null;
};

const getWallProperties = (entity: SimEntity, fullHouse: boolean) => {
	const parent = entity.parent()!;
	if (!parent) {
		throw new Error("no parent");
	}
	const rng = seededRand(entity.getSeed());
	entity.addTag("isPaintable");
	const block = parent.component(CRenderer).block;
	const burnable = parent.component(CBurnable);
	const paint = entity.component(CVariant);
	const typeId = entity._entityTypeID;
	if (!block || !burnable) {
		throw new Error("no block");
	}
	const pos = block!.position.clone();
	const rot = [0, 0, 0] as Vector3Tuple;
	const orientation = parent.component(CDecorator).getOrientation(entity);
	if (orientation === undefined) {
		return {};
	}
	const geoTemplate = SHARED_RENDERDATA.scaffold?.meshes
		?.wall(typeId, orientation)
		.clone()!;
	const geometry = deformMesh(geoTemplate, block, orientation).geometry;
	geometry.setAttribute(
		"progress",
		new BufferAttribute(
			new Float32Array(geometry.attributes.position.count).fill(
				parent.component(CBurnable)?.getProgress() || 0,
			),
			1,
		),
	);
	const material = SHARED_RENDERDATA.scaffold?.materials?.fire!;
	const color = CPaint.getPaint(paint.color);
	return {
		color,
		pos,
		rot,
		orientation,
		geometry,
		material,
		burnable,
		renderModule: rng(0, 100) > 20 && fullHouse && orientation < 4,
	};
};

export const WallRenderer = ({ entity }: { entity: SimEntity }) => {
	const { pos } = useBlock({ entity: entity.parent()! });
	const [color, setColor] = useState(
		CPaint.getPaint(entity.component(CVariant).color),
	);
	const [fullHouse, setFullHouse] = useState(
		entity.parent()!.component(CDecorator).isFullBuilding(),
	);
	const progressRef = useRef(0);

	const { rot, burnable, geometry, material, renderModule } = useMemo(
		() => getWallProperties(entity, fullHouse),
		[entity, entity.component, fullHouse],
	);

	useEffect(() => {
		const variant = entity.component(CVariant);
		const onVariantChanged = () => {
			setColor(CPaint.getPaint(variant.color));
			setFullHouse(entity.parent()!.component(CDecorator).isFullBuilding());
		};
		entity.listenForEvent("onVariantChanged", onVariantChanged);
		entity.parent()?.listenForEvent("onNeighbourUpdate", onVariantChanged);
		return () => {
			if (entity) {
				entity.removeEventListener("onVariantChanged", onVariantChanged);
				if (entity.parent()) {
					entity
						.parent()
						.removeEventListener("onNeighbourUpdate", onVariantChanged);
				}
			}
		};
	}, [entity]);

	const data = useBatchedRenderer(
		entity.parent()!,
		() => {
			return {
				geometry,
				material,
				opts: {
					matrix: createTransformationMatrix(pos.clone(), rot, [2, 2, 2]),
					color,
					userData: { entityRef: entity.ref },
				},
			};
		},
		[entity, geometry, color],
	);

	useBurnableUpdate({
		progressRef,
		burnable,
		entity,
		data,
		material,
		geometry,
	});

	if (!geometry) return null;
	return (
		<>
			<Interactable
				entity={entity}
				position={[0, 0, 0]}
				geometry={geometry}
				scale={[2, 2, 2]}
				debug={false}
			/>
			{renderModule && <WallModuleRenderer entity={entity} />}
		</>
	);
};
