/*
 * The supported chains.
 * By default, MUD provides only two chains here:
 *
 * - mudFoundry, the chain running on anvil that bun dev
 *   starts by default. It is similar to the viem anvil chain
 *   (see https://viem.sh/docs/clients/test.html), but with the
 *   basefee set to zero to avoid transaction fees.
 * - latticeTestnet, our public test network.
 *
 */

import type { MUDChain } from "./types";
import {
	arbitrumSepolia,
	redstoneHolesky,
	conduitCloudlines,
	foundryAnvil,
	arbitrumQuicknode,
} from "./chains";

/*
 * See https://mud.dev/tutorials/minimal/deploy#run-the-user-interface
 * for instructions on how to add networks.
 */

export const supportedChains: MUDChain[] = [
	arbitrumSepolia,
	redstoneHolesky,
	conduitCloudlines,
	arbitrumQuicknode,
	foundryAnvil,
];

export const getChainData = (name: string): MUDChain | undefined => {
	return supportedChains.find(
		(c) => c.name.toLowerCase() === name.toLowerCase(),
	);
};

export const getChainDataById = (id: number): MUDChain | undefined => {
	return supportedChains.find((c) => c.id === id);
};
