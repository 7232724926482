import { FileLog } from "@/lib/debug/fileLogger";
import { ENV } from "@/lib/env";
import type { WorldCoordinate } from "@game/world/world.types";
import type { NetworkSetupResult } from "@jungle/common/src/mud.setup";
import type { WaitForTransactionResult } from "@latticexyz/store-sync";
import type { Hex } from "viem";
import { getNetwork } from "..";
import { BYTES32_ZERO } from "@/lib/utils";
export type SystemCalls = ReturnType<typeof createSystemCalls>;

export function createSystemCalls({
	waitForTransaction,
	playerEntity,
	worldContract,
	publicClient,
}: NetworkSetupResult) {
	const delayedWaitForTransaction = async (
		tx: Hex,
		tries = 50,
	): Promise<WaitForTransactionResult | null> => {
		let t = tries;
		console.time("TX duration");
		const _doWait = async (tx: Hex) => {
			try {
				const result = await waitForTransaction(tx);
				return result;
			} catch (e) {
				if (t > 0) {
					t--;
					await new Promise((r) => setTimeout(r, 500));
					return _doWait(tx);
				}
				throw e;
			}
		};
		const waitResult = await _doWait(tx);
		publicClient
			.getTransactionReceipt({ hash: waitResult.transactionHash })
			.then((x) => {
				console.log("TX Gas: ", x.gasUsed.toString());
			});
		console.log("waitResult", waitResult);
		console.timeEnd("TX duration");
		if (ENV.isDev) {
			publicClient.getTransaction({ hash: tx }).then((x) => {
				const data = {
					hash: x.hash.toString(),
					gas: x.gas.toString(),
					from: x.from,
					r: x.r.toString(),
					s: x.s.toString(),
					v: x.v.toString(),
					data: x.input.toString(),
				};
				FileLog.info(tx, data);
			});
		}
		return waitResult;
	};

	/**
	 * Creates a player.
	 * @returns A promise that resolves to the result of the transaction.
	 */
	const action_createPlayer = async () => {
		if (!getNetwork().playerEntity) throw new Error("No player entity");
		const tx = await worldContract.write.jungle__Action_createPlayer([
			getNetwork().playerEntity as Hex,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_purchaseStartingItems = async (
		playerKey: Hex,
		entityIDs: number[],
	) => {
		const tx = await worldContract.write.jungle__Action_purchaseItems([
			playerKey,
			entityIDs,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	/**
	 * Container takes item
	 * @param entityKey The key of the entity that takes the item.
	 * @param item The item to take.
	 * @param slot The slot to take the item from.
	 * @returns A promise that resolves to the result of the transaction.
	 */
	const action_containerTakeItem = async (
		entityKey: string,
		item: string,
		slot: number,
	) => {
		const tx = await worldContract.write.jungle__Action_takeItem([
			entityKey as Hex,
			item as Hex,
			slot,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_containerRemoveItem = async (
		entityKey: string,
		item: string,
	) => {
		const tx = await worldContract.write.jungle__Action_containerRemoveItem([
			entityKey as Hex,
			item as Hex,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_containerSwapSlots = async (
		entityKey: string,
		slot1: number,
		slot2: number,
	) => {
		const tx = await worldContract.write.jungle__Action_containerSwapSlots([
			entityKey as Hex,
			slot1,
			slot2,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_removePlotItem = async (entityKey: string, item: string) => {
		const tx = await worldContract.write.jungle__Action_removeItemFromPlot([
			entityKey as Hex,
			item as Hex,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_putItemInPlot = async (entityKey: string, itemKey: string) => {
		const tx = await worldContract.write.jungle__Action_putItemInPlot([
			entityKey as Hex,
			itemKey as Hex,
		]);
		return tx;
	};

	const action_harvest = async (playerKey: string, entityKey: string) => {
		const tx = await worldContract.write.jungle__Action_harvest([
			playerKey as Hex,
			entityKey as Hex,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const eat = async (_entityKey: string, _feedKey: string) => {
		console.error("unimplemented eat");
	};

	const action_plantItem = async (entityKey: string, itemKey: string) => {
		const tx = await worldContract.write.jungle__Action_plantCrop([
			entityKey as Hex,
			itemKey as Hex,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_rummage = async (
		playerKey: string,
		targetKey: string,
		invObject?: string,
	) => {
		console.log("rummage", playerKey, targetKey, invObject);
		const tx = await worldContract.write.jungle__Action_rummage([
			playerKey as Hex,
			targetKey as Hex,
			(invObject as Hex) || (BYTES32_ZERO as Hex),
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_build = async (
		playerKey: string,
		ingredientKey: string,
		coordinate: WorldCoordinate,
		entityTypeId: number,
		orientation: number,
	) => {
		const buildingData = {
			entityTypeId,
			x: coordinate.grid[0],
			y: coordinate.grid[1],
			quad: coordinate.quad,
			height: coordinate.y,
		};
		const tx = await worldContract.write.jungle__Action_build([
			playerKey as Hex,
			ingredientKey as Hex,
			buildingData,
			orientation,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_drop = async (playerKey: string, itemKey: string) => {
		const tx = await worldContract.write.jungle__Action_drop([
			playerKey as Hex,
			itemKey as Hex,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_addToBlueprint = async (
		playerKey: string,
		itemKey: string,
		entityTypeId: number,
		coordinate: WorldCoordinate,
		orientation: number,
	) => {
		const buildingData = {
			entityTypeId,
			x: coordinate.grid[0],
			y: coordinate.grid[1],
			quad: coordinate.quad,
			height: coordinate.y,
		};
		const tx = await worldContract.write.jungle__Action_addToBlueprint([
			playerKey as Hex,
			itemKey as Hex,
			buildingData,
			orientation,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_paint = async (
		playerKey: string,
		entityKey: string,
		ingredientKey: string,
		color: number,
		altColor: number,
	) => {
		const tx = await worldContract.write.jungle__Action_paint([
			playerKey as Hex,
			entityKey as Hex,
			ingredientKey as Hex,
			color,
			altColor,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_transmute = async (
		playerKey: string,
		entityKey: string,
		entityTypeId: number,
		activeItemKey?: string,
		destroyActiveItem: boolean = false,
	) => {
		const destroyOriginal = activeItemKey && destroyActiveItem;
		console.log("destroyOriginal", destroyOriginal);
		const [tx, tx2] = await Promise.all([
			worldContract.write.jungle__Action_transmute([
				playerKey as Hex,
				entityKey as Hex,
				entityTypeId,
			]),
			worldContract.write.jungle__Action_consume([
				playerKey as Hex,
				activeItemKey as Hex,
				1,
			]),
		]);
		const [r1, _r2] = await Promise.all([
			delayedWaitForTransaction(tx),
			tx2 && delayedWaitForTransaction(tx2),
		]);
		return r1;
	};

	const action_buildWall = async (
		playerKey: string,
		targetKey: string,
		entityKey: string,
		index: number,
	) => {
		const tx = await worldContract.write.jungle__Action_buildWall([
			playerKey as Hex,
			targetKey as Hex,
			entityKey as Hex,
			BigInt(index),
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_burnEntity = async (playerKey: string, entityKey: string) => {
		const tx = await worldContract.write.jungle__Action_burnEntity([
			playerKey as Hex,
			entityKey as Hex,
			50,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_useItem = async (playerKey: string, itemKey: string) => {
		const tx = await worldContract.write.jungle__Action_useItem([
			playerKey as Hex,
			itemKey as Hex,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const action_useItemOnTarget = async (
		playerKey: string,
		itemKey: string,
		targetKey: string,
	) => {
		const tx = await worldContract.write.jungle__Action_useItemOnTarget([
			playerKey as Hex,
			itemKey as Hex,
			targetKey as Hex,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const test_createTestInventoryItem = async (
		playerKey: string,
		entityTypeId: number,
	): Promise<string> => {
		const tx = await worldContract.write.jungle__Action_createTestInventoryItem(
			[playerKey as Hex, entityTypeId],
		);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	const test_getAdjacentKeys = async (
		x: number,
		y: number,
		quad: number,
		height: number,
	): Promise<readonly Hex[]> => {
		const tx = await worldContract.read.jungle__AdjacentSystem_getAdjacents([
			x,
			y,
			quad,
			height,
		]);
		return tx;
	};

	const test_getNeighbourChildren = async (
		entityKey: Hex,
	): Promise<readonly Hex[]> => {
		const tx = await worldContract.read.jungle__Test_getNeighbourChildren([
			entityKey as Hex,
		]);
		return tx;
	};

	const test_destroyEntity = async (player: Hex, entityKey: Hex) => {
		const tx = await worldContract.write.jungle__EntitySystem_destroyEntity([
			entityKey,
		]);
		await delayedWaitForTransaction(tx);
		return tx;
	};

	return {
		action_addToBlueprint,
		action_build,
		action_buildWall,
		action_burnEntity,
		action_containerRemoveItem,
		action_containerSwapSlots,
		action_containerTakeItem,
		action_createPlayer,
		action_drop,
		action_harvest,
		action_paint,
		action_plantItem,
		action_purchaseStartingItems,
		action_putItemInPlot,
		action_removePlotItem,
		action_rummage,
		action_transmute,
		action_useItem,
		action_useItemOnTarget,
		test_createTestInventoryItem,
		test_getAdjacentKeys,
		test_getNeighbourChildren,
		test_destroyEntity,
		eat,
	};
}
