// TEMPORARY COMPONENT TO MAKE PAINT

import Audio from "@game/audio/audioPlayer";
import { getSystemCalls } from "@mud";
import type { SimEntity } from "../SimEntity";
import type { TAction } from "../actions/actions.types";
import { CInventoryItem } from "./CInventoryItem";
import { SimComponent } from "./SimComponent";
import { getFormulaByItems } from "@/data/formula/formula.data";

export class CTransmute extends SimComponent {
	combos: [number, number][] = [];

	hasCombo(target: SimEntity) {
		return this.getCombo(target) !== undefined;
	}

	getCombo(target: SimEntity) {
		return getFormulaByItems(this.inst._entityTypeID, target._entityTypeID);
	}

	async transmute(doer: SimEntity, target: SimEntity) {
		const { action_transmute } = getSystemCalls();
		const combo = this.getCombo(target);
		if (combo) {
			const [activeItemKey, destroyActive] = combo.destroysActiveItem ? [this.inst.ref, true] : [undefined, false];
			await action_transmute(doer.ref, target.ref, combo.produces, activeItemKey, destroyActive);
			Audio.playSound("TRANSMUTE", { volume: 0.125 });
			return true;
		}
		return false;
	}

	setCombos(combos: [number, number][]) {
		this.combos = combos;
	}

	collectUseActions(
		_doer: SimEntity,
		target: SimEntity,
		actions: TAction[],
		alt?: boolean,
	) {
		if (
			!alt &&
			this.inst.component(CInventoryItem).isHeld() &&
			target.component(CInventoryItem) &&
			target.component(CInventoryItem).isHeld() &&
			this.hasCombo(target)
		) {
			actions.push("TRANSMUTE");
		}
	}
}
