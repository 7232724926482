import { useCallback, useEffect, useMemo, useState } from "react";
import { cn, customFormatEther, roundToDecimal } from "@/lib/utils";
import { getEntityMetadataByID } from "@/data/entity/entity.data.fn";
import {
	alternateFlexLines,
	closeItemShop,
	getSelectedItems,
} from "./itemShop.fn";
import { ShopModal, ShopModalBlock, ShopModalButton } from "./itemShopModal";
import {
	ItemShopStore,
	type IItemShopProduct,
	useItemShopStore,
} from "./itemShop.store";
import { useTranslation } from "react-i18next";
import usePayments from "@/wallet/usePayments";
import Audio from "@game/audio/audioPlayer";
import { formatEther } from "viem";
import { setUIState } from "@game/ui/ui.states";
import { ENV } from "@/lib/env";
import { Debug } from "@/lib/debug/debug";
import { UserStore } from "@/data/user-settings.store";
import { useInventoryStore } from "../inventory/inventory.store";
import { getEntityByRef } from "@game/sim/sim.store";
import { GameStates } from "@game/game.states";
import { useUIStore } from "@game/ui/ui.store";
import { motion } from "framer-motion";

function ItemShopCard({
	item,
	selectionLeft,
}: { item: IItemShopProduct; selectionLeft: number }) {
	const handleClick = () => {
		if (item.selected) {
			//deselect sound
			Audio.playSound("PICKUP_LOCO", { volume: 0.055 });
		} else {
			Audio.playSound("PICKUP");
		}
		Debug("ItemShop").capture("item_click", { item, selected: !item.selected });
		ItemShopStore().updateProduct({
			...item,
			selected: !item.selected,
		});
	};

	const handleMouseOver = () => {
		Debug("ItemShop").capture("item_hover", { item, selected: !item.selected });
		ItemShopStore().set({
			detailedProduct: item,
		});
	};

	const { icon } = useMemo(() => {
		const metadata = getEntityMetadataByID(item.entityTypeId);
		return {
			icon: `/icons/${metadata?.icon ?? "unknown.webp"}`,
		};
	}, [item.entityTypeId]);

	return (
		<button
			className={cn(
				"flex flex-col items-center justify-center w-22 h-22 group pointer-events-auto transition-all cursor-pointer text-sm relative",
				selectionLeft <= 0 && !item.selected && "opacity-50",
			)}
			onClick={handleClick}
			onMouseOver={handleMouseOver}
			disabled={selectionLeft <= 0 && !item.selected}
		>
			<div
				className={cn(
					"absolute top-[15%] left-[15%] w-[70%] h-[70%] bg-[#BAC1B9] z-0 transition-all opacity-20  duration-200",
					item.selected &&
						"bg-[#256718] top-[7.5%] left-[7.5%] w-[85%] h-[85%] opacity-20  duration-75",
				)}
			/>
			<div
				className={cn(
					"w-20 h-20 overflow-auto z-50 opacity-70 transition-all group-hover:opacity-90 duration-400 group-hover:scale-[110%]",
					item.selected && "opacity-100 scale-[105%]",
				)}
				style={{ background: `url(${icon}) no-repeat center/cover` }}
			/>
		</button>
	);
}

export function ItemShopPurchasePanel() {
	const { t } = useTranslation();
	const items = useItemShopStore((state) => state.products);
	const playpass = useItemShopStore((state) => state.playpass);
	const inventorySlots = useInventoryStore((state) => state.inventorySlots);
	const UIState = useUIStore((state) => state.uiState);

	const { totalPrice, selectedItems } = useMemo(() => {
		return getSelectedItems(items.filter((item) => item !== playpass));
	}, [items, playpass]);

	const { pay, paymentStatus, error, balance, isConnected, allowBypass } =
		usePayments();
	const [errorMessage, setErrorMessage] = useState<string | null>(null!);

	const handlePurchase = useCallback(async () => {
		setErrorMessage(null);
		await pay(
			totalPrice.toString(),
			selectedItems.map((item) => item.entityTypeId),
		);
	}, [selectedItems, totalPrice, pay]);

	useEffect(() => {
		// window swipe in sound
		Audio.playSound("PUT");
	}, []);

	useEffect(() => {
		if (paymentStatus === "success") {
			console.log("success");
			Debug("ItemShop").capture("payment_success", {
				selectedItems,
				totalPrice,
			});
			Audio.stopMusic();
			Audio.playSound("MUSIC_BOOTUP");
			if (!UserStore().signedUp) {
				setUIState(GameStates.playMode);
				return;
			}
			closeItemShop();
			return;
		}
		if (paymentStatus === "error") {
			if (error) {
				setErrorMessage(
					t(`cryptoErrors.${error.shortMessage}`) ||
						t(`cryptoErrors.${error.name}`) ||
						t("cryptoErrors.unknown"),
				);
			} else {
				setErrorMessage(t("cryptoErrors.unknown"));
			}
		}
	}, [paymentStatus, error, t, selectedItems, totalPrice]);

	const sufficientBalance = ENV.BYPASS_PAYMENTS
		? true
		: Number(formatEther(balance.data?.value || 0n)) >= totalPrice;

	const { inventoryFull, selectionLeft } = useMemo(() => {
		const maxItems = 3;
		const itemsInInventory = inventorySlots.filter(
			(slot) => getEntityByRef(slot) !== undefined,
		);
		const selectionLeft =
			maxItems - selectedItems.length - itemsInInventory.length;

		const isInvFull = () => {
			if (!UserStore().signedUp || inventorySlots.length === 0) return false;
			return itemsInInventory.length >= 3;
		};
		console.log(selectionLeft);

		return {
			inventoryFull: isInvFull(),
			selectionLeft,
		};
	}, [inventorySlots, selectedItems]);

	return (
		<ShopModal
			className={cn(
				"font-light rounded-lg px-0 relative",
				inventoryFull && "grayscale-[70%]",
			)}
		>
			{/* Top block with title and descriptions */}
			<ShopModalBlock className="pb-2 bg-transparent items-end flex px-6">
				{UIState.isLoadoutStore && (
					<>
						<ItemShopCard item={playpass!} selectionLeft={selectionLeft} />
						<div className="text-3xl uppercase tracking-tighter flex-col flex z-10 w-full leading-7">
							{alternateFlexLines(t("shop.starting_gear"))}
							<div className="text-xs tracking-normal text-right">
								{t("shop.max_items_count", {
									count: selectionLeft,
								})}
							</div>
						</div>
					</>
				)}
			</ShopModalBlock>
			<ShopModalBlock className="flex-row flex-wrap px-4 gap-4 pl-6 pt-0">
				{items.map((item) => (
					<ItemShopCard
						key={item.name}
						item={item}
						selectionLeft={selectionLeft}
					/>
				))}
			</ShopModalBlock>
			<ShopModalBlock className="h-full relative overflow-visible">
				<div className="flex-grow" />
				<div className="self-end flex flex-row w-full">
					<div
						className={cn(
							"flex flex-row gap-2 flex-grow self-center text-[#256718] font-bold",
							!sufficientBalance && "text-black-500",
						)}
					>
						<div className="font-bold capitalize">{t("total")}: </div>
						<div className="font-bold">
							{roundToDecimal(totalPrice, 3)} {ItemShopStore().currency}
						</div>
					</div>
					{(isConnected || ENV.BYPASS_PAYMENTS || allowBypass) && (
						<ShopModalButton
							onClick={handlePurchase}
							className={cn(
								!sufficientBalance &&
									"disabled:bg-[#FB913E]/70 disabled:cursor-not-allowed disabled:active:scale-100",
							)}
							disabled={
								inventoryFull ||
								!sufficientBalance ||
								paymentStatus === "pending" ||
								paymentStatus === "success"
							}
						>
							{t(
								inventoryFull
									? "inventory_full"
									: paymentStatus === "pending"
										? "pending"
										: "purchase",
							)}
						</ShopModalButton>
					)}
					{/* {!isConnected && !ENV.BYPASS_PAYMENTS && (
						<ShopModalButton onClick={connect}>{t("connect")}</ShopModalButton>
					)} */}
				</div>
			</ShopModalBlock>
			{(errorMessage || !sufficientBalance) && (
				<motion.div
					initial={{ height: 0 }}
					animate={{ height: "auto", transition: { duration: 0.125 } }}
					className={cn(
						"relative left-0 self-end flex flex-row w-full gap-2 bg-[#FB913E]/70 rounded-lg text-xs rounded-t-none",
						UIState.isLoadoutStore &&
							"-mt-1 -z-10 absolute top-[100%] bg-[#F6AB74]/100",
					)}
				>
					<div className="absolute w-full h-full from-black/10 via-transparent to-transparent bg-gradient-to-b z-0 border-2 border-white/20 rounded-b-lg border-t-0" />
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { delay: 0.125 } }}
						className="text-[#fff]/100 text-center py-3 px-2 font-medium z-10"
					>
						{!sufficientBalance &&
							`⚠️ ${t("shop.insufficientBalance", {
								count: Number(customFormatEther(balance.data?.value || 0n, 2)),
								currency: ItemShopStore().currency,
							})} `}
						{errorMessage}
					</motion.div>
				</motion.div>
			)}
		</ShopModal>
	);
}
