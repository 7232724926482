import { DATA_PALETTES } from "@/data/colors/palette.data";
import { logVerbose } from "@/lib/devtools/dev.store";
import posthog from "posthog-js";
import { debugStyle } from "./debug.style";
import { ENV } from "../env";

type TPrettyLogType = keyof typeof DATA_PALETTES.logs.colors;

const getPrettyLog = (type?: TPrettyLogType) => {
	const color =
		DATA_PALETTES.logs.colors[type || "Default"]?.hex ||
		DATA_PALETTES.logs.colors.Default.hex;
	const css = `background: ${color}50; color: #ffffff; border-radius: 5px; padding: 3px 5px; font-weight: bold; border: 1px solid ${color}/90; margin: 1px 0; margin-left: -7px; font-family: Berkeley, monospace; display: inline;`;
	return [`%c${type}`, css];
};

type TLogFlags = "verbose" | "event" | undefined;
type TIdentityProps = {
	playerId?: number;
	spectator?: boolean;
	email?: string;
	playerEntity?: string;
};

export const Debug = (
	type: TPrettyLogType = "Default",
	...args: TLogFlags[]
) => {
	const pretty = getPrettyLog(type);
	const consolePrint = () =>
		(args?.includes("verbose") && logVerbose()) || !args?.includes("verbose");

	const identity = (props?: TIdentityProps) => {
		const id = props?.email || undefined;
		posthog.identify(id, { ...props });
	};

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const capture = (event: string, params?: any) => {
		const e = `${type.toLowerCase()}_${event.replace(" ", "_").toLowerCase()}`;
		const env = ENV.isDev ? "dev" : "prod";
		posthog.capture(type, {
			event: e,
			...params,
			environment: env,
		});
		if (consolePrint()) console.log(...format(e), params);
		return this;
	};

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const format = (...args: any[]) => {
		return debugStyle.format(`<css="${pretty[1]}">${type}</css>`, ...args);
	};

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const log = (...args: any[]) => {
		if (consolePrint()) console.log(...format(...args));
		return this;
	};

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const info = (...args: any[]) => {
		const callSite = new Error().stack!.split("\n")[2].trim();
		if (consolePrint()) console.info(...format(...args), "@", callSite);
		return this;
	};

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const warn = (...args: any[]) => {
		if (consolePrint()) console.warn(...format(...args));
		return this;
	};

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const error = (...args: any[]) => {
		if (consolePrint()) console.error(...format(...args));
		return this;
	};

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const trace = (...args: any[]) => {
		if (consolePrint()) console.trace(...format(...args));
		return this;
	};

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const groupCollapsed = (...args: any[]) => {
		if (consolePrint()) console.groupCollapsed(...format(...args));
		return this;
	};

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const group = (...args: any[]) => {
		if (consolePrint()) console.group(...format(...args));
		return this;
	};

	const groupEnd = () => {
		if (consolePrint()) console.groupEnd();
		return this;
	};

	return {
		format,
		log,
		info,
		warn,
		error,
		trace,
		groupCollapsed,
		group,
		groupEnd,
		identity,
		capture,
	};
};
