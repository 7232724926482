import type { MUDChain } from "src/types";
import { arbitrum as _arbitrum } from "viem/chains";

export const arbitrumQuicknode = {
	...(_arbitrum as MUDChain),
	rpcUrls: {
		default: {
			http: [
				"https://neat-prettiest-surf.arbitrum-mainnet.quiknode.pro/ffec10e4577c6d109b62e0e2d28c4485eaa94550/",
			],
			webSocket: [
				"wss://neat-prettiest-surf.arbitrum-mainnet.quiknode.pro/ffec10e4577c6d109b62e0e2d28c4485eaa94550/",
			],
		},
	},
};
