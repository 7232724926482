import { cn, truncateAddress } from "@/lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import { UILayout } from "../ui.layout";
import { UIStore, useUIStore } from "@game/ui/ui.store";
import { setUIState } from "@game/ui/ui.states";
import { useTranslation } from "react-i18next";
import "./logo.css";
import { Debug } from "@/lib/debug/debug";
import { usePrivy, useLogin } from "@privy-io/react-auth";
import { StoreStates } from "../itemShop/itemShop.states";
import { useEffect, useState } from "react";
import { validateAccesCode } from "@/lib/util.waitlist";
import { UserStore } from "@/data/user-settings.store";
import { GameStates } from "@game/game.states";
import { ENV } from "@/lib/env";
import { useClipboard } from "@/lib/useClipboardCopy";
import { generateKey } from "@/lib/utils.accessCode";

export default function GameMenu() {
	const { t } = useTranslation();
	const [validAccess, setValidAccess] = useState(false);
	const { showGameLogo, showSignupButton } = useUIStore(
		(state) => state.uiState,
	);
	const [generatedKey, setGeneratedKey] = useState("");
	const { copyToClipBoard, status } = useClipboard();

	useEffect(() => {
		const validateAccess = async () => {
			if (ENV.DEV) {
				setValidAccess(true);
				return;
			}
			const valid = await validateAccesCode();
			setValidAccess(valid);
		};
		validateAccess();
	}, []);

	useEffect(() => {
		const makeKey = async () => {
			if (ENV.SPLOOF) {
				const key = await generateKey({ time: 3600, locationString: "?" });
				setGeneratedKey(`${location.origin}/?${key}`);
			}
		};
		makeKey();
	}, []);

	const handleStart = () => {
		if (UserStore().isPlayer() && UIStore().authenticated) {
			setUIState(GameStates.playMode);
			return;
		}
		if (authenticated) {
			setUIState(StoreStates.receivePlaypass);
			Debug("ItemShop").capture("confirm_pass");
			return;
		}
		login();
	};

	const { ready, authenticated } = usePrivy();
	const { login } = useLogin({
		onComplete: (
			_user,
			_isNewUser,
			_wasAlreadyAuthenticated,
			_loginMethod,
			_linkedAccount,
		) => {
			handleStart();
		},
		onError: (_error) => {
			handleStart();
		},
	});

	const handleStartButton = () => {
		if (!ready) return;
		handleStart();
	};

	return (
		<AnimatePresence>
			{showGameLogo && (
				<UILayout
					className="fullscreen pointer-events-auto"
					data-name="game-logo"
				>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.25 }}
						className={cn(
							"absolute mx-auto uppercase top-[67.5%] z-[10000] flex flex-col w-full items-center",
						)}
					>
						{/* <div className="relative w-[400px] h-[162px] aspect-auto logo-image bg-contain" /> */}
						<div
							data-src="cloudlines.png"
							className="relative max-w-[400px] h-[162px] aspect-[1/2.4691358] logo-image border-0 w-[80%] bg-contain bg-scale bg-no-repeat"
							// style={{ backgroundSize: "100% auto" }}
						/>
						{validAccess && showSignupButton && (
							<motion.button
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ type: "spring", duration: 0.25 }}
								onClick={handleStartButton}
								className="uppercase text-white font-extrabold bg-transparent mx-auto px-4 py-2 rounded-full active:scale-95 disabled:bg-slate-600 hover:scale-105 border-2 border-white/0 relative overflow-clip hover:border-white/5 transition-all hover:bg-white/5 font-berkeley"
							>
								<div className="trail" />
								{t("signupButton")}
							</motion.button>
						)}
						{ENV.SPLOOF && (
							<div className="flex flex-col gap-2 pt-4">
								<button
									className={cn(
										"text-xs text-white bg-[#333737]/80 hover:bg-[#333737] rounded-sm p-2 px-4 pointer-events-all",
										status && "bg-[#266818]",
									)}
									onClick={() => copyToClipBoard(generatedKey)}
								>
									{status && <span>{status ? "✅" : "Failed"}</span>}
									{!status && "🪂"} {truncateAddress(generatedKey)}{" "}
								</button>
							</div>
						)}
					</motion.div>
				</UILayout>
			)}
		</AnimatePresence>
	);
}
