import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import type { BufferedAction } from "@game/sim/actions/bufferedAction";
import { CBurnable } from "@game/sim/components/CBurnable";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CUsable } from "@game/sim/components/CUsable";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";

const useLighterStrFn = (act: BufferedAction) => {
	if (act.target?.component(CBurnable)?.isIgnitable()) {
		return `Burn ${act.target?.getName()}`;
	}
	return "";
};

const canUseLighterFn = (doer: SimEntity, target: SimEntity) => {
	// HACK very hacky
	if (target?.component(CBurnable)?.isIgnitable()) {
		return true;
	}
	return false;
};

const useLighterFn = async ({ doer, target }: BufferedAction) => {
	if (target?.component(CBurnable)?.isIgnitable()) {
		await target.component(CBurnable).ignite(doer);
	}
};

export class ItemLighter extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem).setSigil("lighter");
		e.addComponent(CUsable)
			.setCanUseFn(canUseLighterFn)
			.setUseFn(useLighterFn)
			.setUseStrFn(useLighterStrFn);
	}
}

const entityData: TEntityDefinition = {
	name: "Lighter",
	simEntityPrefab: ItemLighter,
	id: 50100,
	metadata: {
		icon: "lighter.webp",
	},
};

registerEntity(entityData);
