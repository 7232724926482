import type { SimEntity } from "@game/sim/SimEntity";
import mitt from "mitt";
import type { Intersection, Object3D, Object3DEventMap } from "three";

export type TUIEvent = {
	removeEntity: { inst: SimEntity };
	getPlayerActions: undefined;
	changedUIState: undefined;
	mouseover: {
		data: Intersection<Object3D<Object3DEventMap>>;
		entity: SimEntity;
	};
	mouseleave: { entity: SimEntity | null; current: SimEntity | null };
};

export const UI_EVENTS = mitt<TUIEvent>();
