import { baseMesh, getMesh, getTexture } from "@/data/assetLoader/asset.store";
import {
	randomFromArray,
	type TSeededRand,
} from "@/lib/random/seeded.functions";
import { BoidSystem } from "@game/components/boidSystem";
import { ConstructableInput } from "@game/components/constructableInput";
import { useMemo, useRef, type MutableRefObject } from "react";
import { BufferAttribute, Color, type Mesh } from "three";
import type { SimEntity } from "../sim/SimEntity";
import { CRenderer } from "../sim/components/CRenderer";
import { CDecorator } from "@game/sim/components/CDecorator";
import {
	registerRenderData,
	SHARED_RENDERDATA,
} from "@/lib/batching/sharedData";
import { CBurnable } from "@game/sim/components/CBurnable";
import {
	defaultFireMaterial,
	defaultFireUpdate,
} from "@/lib/batching/batching.utils";
import { useBlock } from "@/lib/useBlock";
import { useBatchedRenderer } from "@/lib/batching/useBatchedRenderer";
import { createTransformationMatrix } from "@/lib/placement.utils";
import { deformMesh } from "@/lib/deformation/meshDeformation";
import { useBurnableUpdate } from "@game/sim/useBurnableUpdate";
import { useRenderChildren } from "@game/sim/SimEntity.fn";
import { WallRenderer } from "./scaffoldWall.renderer";

const m = baseMesh("modular_set/modular_set");
const meshes = [m("building_scaffold002"), m("building_scaffold003")];
const halfScaffoldMeshes = [m("Building_scaffold_half001")];
const decoratorMeshes = {
	30003: [
		m("Building_stone_wall"),
		m("Building_stone_wall"),
		m("Building_stone_wall"),
		m("Building_stone_wall"),
		m("Building_stone_roof"),
		m("Building_stone_floor"),
	],
	30004: [
		m("Building_sheet_wall"),
		m("Building_sheet_wall"),
		m("Building_sheet_wall"),
		m("Building_sheet_wall"),
		m("Building_sheet_roof"),
		m("Building_sheet_floor"),
	],
};

registerRenderData("scaffold", () => {
	const materials = {
		fire: defaultFireMaterial({
			name: "ScaffoldMaterial",
			maskColor: new Color("white"),
			map: getTexture("modular_set/GradientTexture_m_512"),
			opacity: 1,
			transparent: false,
			color: new Color("white"),
		}),
	};
	return {
		materials,
		meshes: {
			halfScaffold: (rng: TSeededRand) =>
				getMesh(randomFromArray(halfScaffoldMeshes, rng)),
			fullScaffold: (rng: TSeededRand) => getMesh(randomFromArray(meshes, rng)),
			wall: (entityId: number, idx: number) =>
				getMesh(decoratorMeshes[entityId as keyof typeof decoratorMeshes][idx]),
		},
		onUpdate: (state, delta, self) => defaultFireUpdate(state, delta, self),
	};
});

const getScaffoldProperties = (
	entity: SimEntity,
	rng: TSeededRand,
	meshRef: MutableRefObject<Mesh>,
	progressRef: MutableRefObject<number>,
) => {
	const block = entity.component(CRenderer).block;
	const renderer = entity.component(CRenderer);
	const burnable = entity.component(CBurnable);
	const decorators = entity.component(CDecorator);
	if (!block || !renderer || !burnable) {
		throw new Error("no block, renderer or burnable");
	}
	const pos = block!.position.clone();
	const rot = [0, 0, 0];
	const orientation = renderer.orientation;
	const halfScaffold =
		!decorators.hasSideWall(2) &&
		!block.hasNeighbourInDirections([(orientation + 2) % 4, 5]);
	const geoTemplate = halfScaffold
		? SHARED_RENDERDATA.scaffold?.meshes?.halfScaffold(rng).clone()!
		: SHARED_RENDERDATA.scaffold?.meshes?.fullScaffold(rng).clone()!;
	if (geoTemplate !== meshRef.current) {
		entity.pushEvent("neighbourUpdate", {});
		meshRef.current = geoTemplate;
	}
	const geometry = deformMesh(geoTemplate, block, orientation).geometry;
	geometry.setAttribute(
		"progress",
		new BufferAttribute(
			new Float32Array(geometry.attributes.position.count).fill(
				progressRef.current,
			),
			1,
		),
	);
	// check to show fish
	const neighbourCount = block.getNeighbourEntities().length;
	const decoratorCount = entity.component(CDecorator).decoratorCount;
	const showFish =
		(halfScaffold || neighbourCount <= 2) &&
		decoratorCount < 3 &&
		!burnable.isBurning();
	// check to show fish
	const material = SHARED_RENDERDATA.scaffold?.materials?.fire!;
	return {
		rot,
		pos,
		geometry,
		showFish,
		material,
		burnable,
	};
};

export function ScaffoldRenderer({ entity }: { entity: SimEntity }) {
	const { block, rng } = useBlock({ entity });
	const meshRef = useRef<Mesh>(null!);
	const progressRef = useRef(0);

	const { pos, geometry, showFish, material, burnable } = useMemo(
		() => getScaffoldProperties(entity, rng, meshRef, progressRef),
		[entity, rng],
	);

	const data = useBatchedRenderer(entity, () => {
		return {
			geometry,
			material,
			opts: {
				matrix: createTransformationMatrix(
					pos.clone(),
					[0, 0, 0],
					[1.97, 1.97, 1.97],
				),
				color: new Color("white"),
			},
		};
	});

	useBurnableUpdate({
		progressRef,
		burnable,
		entity,
		data,
		material,
		geometry,
	});

	const children = useRenderChildren(entity, (child, _idx) => {
		return {
			child,
			props: {},
			renderer: WallRenderer,
		};
	});

	if (!block || !geometry) return;
	return (
		<>
			<group>
				<ConstructableInput entity={entity} args={[1, 1, 1]} key={entity.ref} />
				<group position={pos}>
					{showFish && <BoidSystem />}
					{children}
				</group>
			</group>
		</>
	);
}
