import { AssetStore } from "@/data/assetLoader/asset.store";
import { DevStore } from "@/lib/devtools/dev.store";
import { UserStore } from "@/data/user-settings.store";
import type { Hexagrid } from "@/lib/hexagrid/hexagrid";
import { getEntityByRef } from "@game/sim/sim.store";
import { UIStore, useUIStore } from "@game/ui/ui.store";
import { Html, Line } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import hotkeys from "hotkeys-js";
import { Perf } from "r3f-perf";
import { Fragment, type PropsWithChildren, useEffect, useMemo } from "react";
import { type Vector2, Vector3 } from "three";
import { Block } from "./block";
import { getBlock } from "./block.store";
import { getGrid, gridRefs } from "./grid.store";
import { ISLANDS } from "./islands/islands.functions";
import { getEntityData } from "@/data/entity/entity.data.fn";
import { CRenderer } from "@game/sim/components/CRenderer";
import { getSystemCalls, tables, useMUDStore } from "@mud/index";
import { toBytes, toHex } from "viem";

const currentHeight = () =>
	(UIStore().hoveredObject
		? getEntityByRef(UIStore().hoveredObject!)?.component(CRenderer)?.block
				?.coordinate?.y || 0
		: 0) / 2;

export const SidePointsDebugViz = () => {
	const hoveredObject = useUIStore((state) => state.hoveredObject);

	const { points, center } = useMemo(() => {
		const hObj = getEntityByRef(hoveredObject);
		if (!hObj?.component(CRenderer).block) return {};
		const coord = hObj?.component(CRenderer).block!.coordinate.grid;
		if (!coord) return {};
		const grid = getGrid(coord);
		const points = grid.orderedSidePoints;
		const height = currentHeight();
		const allpoints = points.map((p) => {
			const sortedIndex = grid.getOrderedIndex(p);
			const oppositeIndex = grid.getOrderedIndex(grid.getOppositePoint(p));
			return {
				index: p.index,
				sortedIndex,
				oppositeIndex,
				position: new Vector3(
					p.position.x + grid.hexOffset.x,
					height,
					p.position.y + grid.hexOffset.y,
				),
			};
		});
		const oppositeGridCoord = grid.getOppositeGridCoord(
			grid.orderedSidePoints[10],
			grid.orderedSidePoints[11],
		);
		const oppositeGrid = getGrid(oppositeGridCoord);
		return {
			points: allpoints,
			center: new Vector3(
				oppositeGrid.hexOffset.x,
				height,
				oppositeGrid.hexOffset.y,
			),
		};
	}, [hoveredObject]);
	return (
		<group>
			{points?.map((p) => (
				<Fragment key={p.index}>
					<mesh key={p.index} position={p.position}>
						<boxGeometry args={[0.05, 0.05, 0.05]} />
						<meshBasicMaterial color={0xff0000} />
					</mesh>
					{DevStore().showHexBorders && (
						<Html position={p.position}>
							<div style={{ fontSize: "8px" }}>{p.sortedIndex}</div>
							<div className="text-red-50" style={{ fontSize: "8px" }}>
								{p.oppositeIndex}
							</div>
						</Html>
					)}
				</Fragment>
			))}
			<mesh position={center}>
				<boxGeometry args={[0.15, 0.15, 0.15]} />
				<meshBasicMaterial color={0xff00ff} />
			</mesh>
		</group>
	);
};

export const QuadComponent = ({
	quads,
	color = 0xaaaaaa,
	lineWidth = 0.5,
	label,
	...props
}: {
	quads: Vector2[][];
	y?: number;
	color?: number | string;
	label?: string;
	lineWidth?: number;
} & PropsWithChildren) => {
	const hoveredObject = useUIStore((state) => state.hoveredObject);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const { allSegments, center } = useMemo(() => {
		const height = currentHeight();
		const center = new Vector3();
		let j = 0;
		const allSegments = quads.flatMap((quad) => {
			const segments = [];
			for (let i = 0; i < quad.length; i++) {
				const start = quad[i];
				const end = quad[i + 1] || quad[0];
				segments.push(new Vector3(start.x, height, start.y));
				segments.push(new Vector3(end.x, height, end.y));
				center.add(segments[segments.length - 1]);
				j++;
			}
			return segments;
		});
		center.divideScalar(j);
		return { allSegments, center };
	}, [quads, hoveredObject]);

	if (allSegments?.length === 0) return null;

	return (
		<>
			<Line
				points={allSegments}
				color={color}
				segments={true}
				lineWidth={lineWidth}
				{...props}
				opacity={0.3}
				transparent
			/>
			<Html position={center} className="text-white">
				<div className="text-white items-center text-xs text-center">
					{label}
				</div>
			</Html>
		</>
	);
};

const NeighbourRenderer = ({
	neighbours,
	block,
}: {
	neighbours: Block[];
	block: Block;
}) => {
	const adjacents = useMUDStore((state) =>
		state.getValue(tables.Adjacents, {
			quadIndex: block.coordinate.quad || -1,
		}),
	);

	const mappedAdjacents = useMemo(() => {
		const testAdj = async () => {
			const { test_getAdjacentKeys, test_getNeighbourChildren } =
				getSystemCalls();
			const adjacentKeys = await test_getAdjacentKeys(
				block.coordinate.grid[0],
				block.coordinate.grid[1],
				block.coordinate.quad,
				block.coordinate.y,
			);
			const e = adjacentKeys.map((k) => getEntityByRef(k));
			console.table(
				e.map((e) =>
					e
						? {
								ref: e.ref,
								name: e.name,
							}
						: { ref: "empty" },
				),
			);
			for (const k of adjacentKeys) {
				const nChild = await test_getNeighbourChildren(k);
				console.log(
					nChild.filter(
						(e) =>
							e !==
							"0x0000000000000000000000000000000000000000000000000000000000000000",
					),
				);
			}
		};
		testAdj();
		return [
			adjacents?.adjacent1[2],
			adjacents?.adjacent2[2],
			adjacents?.adjacent3[2],
			adjacents?.adjacent4[2],
		];
	}, [adjacents, block]);

	return (
		<>
			<QuadComponent
				key={block.coordinate.toString()}
				quads={[block.grid.exportQuad(block._quad)]}
				color="blue"
				lineWidth={1}
				label={`[${block.coordinate.quad.toString()}]`}
			/>
			{neighbours.slice(0, 4).map((b, idx) => {
				const quad = b.grid.exportQuad(b._quad);
				let color =
					b.coordinate.grid[0] === block.coordinate.grid[0] &&
					b.coordinate.grid[1] === block.coordinate.grid[1]
						? "black"
						: "red";
				if (b._quad === block._quad) {
					color = "blue";
				}
				return (
					<QuadComponent
						key={idx}
						quads={[quad]}
						color={color}
						lineWidth={2}
						label={`${b.coordinate.quad.toString()} ${mappedAdjacents.indexOf(b.coordinate.quad)}`}
					/>
				);
			})}
		</>
	);
};

const getBlockNeighbours = (block: Block) => {
	const neighbours: Block[] = [];
	block.neighbours.forEach((n) => {
		const b = getBlock(n)?.[1] || new Block(n);
		if (b) {
			neighbours.push(b as Block);
		} else {
			console.error("no block", n);
		}
	});

	return <NeighbourRenderer neighbours={neighbours} block={block} />;
};

const Grid = ({
	grid,
	color = 0xaaaaaa,
}: {
	grid: Hexagrid;
	color?: number | string;
}) => {
	const quads = useMemo(() => {
		const quads = grid.exportQuads();
		return quads;
	}, [grid]);
	return (
		<group>
			<QuadComponent quads={quads} color={color} />
		</group>
	);
};

export const WorldDevtools = () => {
	const { scene } = useThree();
	const hoveredObject = useUIStore((state) => state.hoveredObject);

	useEffect(() => {
		hotkeys("shift+t", (event, _handler) => {
			event.preventDefault();
			console.log(scene.children);
		});
		return () => {
			hotkeys.unbind("shift+t");
		};
	}, [scene]);

	const grids = useMemo(() => {
		const grids = [];
		// HACKY, setting height here and all components will use that value it's not state based
		if (hoveredObject) {
			const hObj = getEntityByRef(hoveredObject);
			if (hObj?.component(CRenderer)?.block) {
				grids.push(getBlockNeighbours(hObj.component(CRenderer).block!));
			}
		}
		for (const grid of Array.from(gridRefs.values())) {
			grids.push(<Grid key={grid.coordinates.toString()} grid={grid} />);
		}

		return grids;
	}, [hoveredObject]);

	return (
		<>
			{DevStore().showGrid && (
				<>
					{grids}
					<SidePointsDebugViz />
				</>
			)}
			<Perf position="top-left" />
		</>
	);
};

Object.assign(window, {
	getIslands: () => ISLANDS.all,
	assets: AssetStore,
	listMeshes: () => Object.keys(AssetStore().meshes),
	listMaterials: () => Object.keys(AssetStore().materials),
	listTextures: () => Object.keys(AssetStore().textures),
	toggleHexBorders: () =>
		DevStore().set({ showHexBorders: !DevStore().showHexBorders }),
	toggleTerminal: () => {
		UserStore().set({
			showTerminal: !UserStore().showTerminal,
		});
	},
	listEntityData: () => Object.values(getEntityData()),
});
