import {
	useCallback,
	useEffect,
	useMemo,
	useState,
	type DependencyList,
} from "react";
import { CRenderer, type TRendererFn } from "./components/CRenderer";
import type { SimEntity } from "./SimEntity";

export const useRenderChildren = (
	parent: SimEntity,
	renderFn: (
		value: SimEntity | undefined,
		index: number,
	) => { child: SimEntity | undefined; props?: unknown; renderer: TRendererFn },
	deps?: DependencyList, // Dependencies for the useCallback
) => {
	const [childrenUpdated, setChildrenUpdated] = useState(0);
	const memoizedRenderFn = useCallback(
		renderFn,
		deps || [parent, parent.children().length, childrenUpdated],
	);

	useEffect(() => {
		const onChildrenUpdated = () => {
			// FIXME: HACK: weird update hack
			setChildrenUpdated((childrenUpdated + 1) % 20);
		};

		parent.listenForEvent("onChildAdded", onChildrenUpdated);
		parent.listenForEvent("onChildRemoved", onChildrenUpdated);
		return () => {
			parent.removeEventListener("onChildAdded", onChildrenUpdated);
			parent.removeEventListener("onChildRemoved", onChildrenUpdated);
		};
	}, [parent]);

	const children = useMemo(() => {
		return parent
			.children()
			.map(memoizedRenderFn)
			.map(({ child, props, renderer }) => {
				if (!child) return null;
				return renderEntity(child, props, {
					requireBlock: false,
					renderer,
					warnOnMissing: true,
				});
			});
	}, [parent, memoizedRenderFn]);

	return children;
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const renderChildren = (entity: SimEntity, props?: any) => {
	const children = useMemo(() => {
		return entity.children().map((child, idx) => {
			if (!child) return null;
			// if (typeof props === "array") {
			return renderEntity(child, props, { requireBlock: false });
		});
	}, [entity, props]);
	return children;
};

type TRendererOpts = {
	requireBlock?: boolean;
	warnOnMissing?: boolean;
	renderer?: TRendererFn;
};

export const renderEntity = (
	p: SimEntity,
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	props?: any,
	{
		requireBlock = false,
		warnOnMissing = true,
		renderer = undefined,
	}: TRendererOpts = {},
) => {
	if (!p) return null;
	const Renderer = renderer || p.component(CRenderer)?.renderer;
	const block = p.component(CRenderer)?.block;
	if (!Renderer) {
		if (warnOnMissing)
			console.warn("No renderer found for item", p.getName(), p.ref);
		return null;
	}
	if (requireBlock && !block) return null;
	return <Renderer entity={p} key={p.ref} {...props} />;
};
