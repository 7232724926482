import { Vector3 } from "three";
import { angles } from "./meshDeformation";

export function getOrientationAngle(orientation: number) {
	// invert orientation
	const angle = angles[4 - orientation];
	const radians = (angle * Math.PI) / 180;
	return radians;
}

export function linearInterpolate(
	v1: Vector3,
	v2: Vector3,
	divisions: number,
): Vector3[] {
	const points: Vector3[] = [];
	for (let i = 0; i <= divisions; i++) {
		const t = i / divisions;
		points.push(
			new Vector3(
				v1.x + (v2.x - v1.x) * t,
				v1.y + (v2.y - v1.y) * t,
				v1.z + (v2.z - v1.z) * t,
			),
		);
	}
	return points;
}

export function interpolateBoxPoints(
	box: Vector3[],
	divisions: number,
): Vector3[] {
	const interpolatedPoints: Vector3[] = [];
	for (let i = 0; i < box.length; i++) {
		// Connect each point with the next, wrapping around at the end
		const nextIndex = (i + 1) % box.length;
		interpolatedPoints.push(
			...linearInterpolate(box[i], box[nextIndex], divisions),
		);
	}
	return interpolatedPoints;
}
