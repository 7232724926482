import { consola } from "consola";
import mudConfig from "contracts/mud.config";
import ReactDOM from "react-dom/client";
import { createHead } from "unhead";
import { App } from "./App";
import "./index.css";
import "./styles/devTools.css";
import posthog from "posthog-js";
import { ENV } from "./lib/env";
import { initializeMUD } from "./mud";
import { MUDProvider } from "./mud/mud.context";
import { SigilSystemProvider } from "./sigils/sigils.provider";
import { Web3Provider } from "./wallet/web3Provider";
import "./data/locale/i18n";
import { setUIState } from "@game/ui/ui.states";
import { GameStates } from "@game/game.states";
import { UserStore } from "./data/user-settings.store";

posthog.init("phc_ymxBknPyYI8zJHyQbm6yBSHp3FAu5mg5MDqibhSrbd4", {
	api_host: ENV.VITE_ANALYTICS,
});

// consola.wrapAll();
createHead();
setUIState(GameStates.loading);

const rootElement = document.getElementById("react-root");
if (!rootElement) throw new Error("React root not found");
const root = ReactDOM.createRoot(rootElement);

initializeMUD().then(async (result) => {
	if (
		UserStore().worldAddress !== undefined &&
		UserStore().worldAddress !== result.network.worldContract.address
	) {
		UserStore().reset();
	}

	root.render(
		<MUDProvider value={result}>
			<Web3Provider>
				<SigilSystemProvider>
					<App />
				</SigilSystemProvider>
			</Web3Provider>
		</MUDProvider>,
	);

	const miniSpinner = document.getElementById("mini-spinner");
	if (miniSpinner) miniSpinner.remove();

	localStorage.setItem("mud-dev-tools-shown", "false");
	// https://vitejs.dev/guide/env-and-mode.html
	if (ENV.DEV) {
		const { mount: mountMUDDevTools } = await import("@latticexyz/dev-tools");
		const { mountDevTools } = await import("@/lib/devtools/dev.tools");
		if (window !== undefined) {
			mountDevTools();
			Object.assign(window, {
				restart: () => {
					window.localStorage.clear();
					window.location.reload();
				},
			});
		}

		mountMUDDevTools({
			config: mudConfig,
			publicClient: result.network.publicClient,
			walletClient: result.network.walletClient,
			latestBlock$: result.network.latestBlock$,
			storedBlockLogs$: result.network.storedBlockLogs$,
			worldAddress: result.network.worldContract.address,
			worldAbi: result.network.worldContract.abi,
			write$: result.network.write$,
			useStore: result.network.useMUDStore,
		});
	}
});
