import { Debug } from "@/lib/debug/debug";
import { InventoryStore } from "@game/ui/components/inventory/inventory.store";
import { UI_EVENTS } from "@game/ui/ui.events";
import { UIStore } from "@game/ui/ui.store";
import type { SimEntity } from "../SimEntity";
import type { IAction } from "./actions.types";
import { onInventorySlotPointerMove } from "@game/ui/components/inventory/inventory.functions";
import { FileLog } from "@/lib/debug/fileLogger";
import { ENV } from "@/lib/env";

export class BufferedAction {
	self: SimEntity;
	doer: SimEntity;
	target?: SimEntity;
	invObject?: SimEntity;
	action: IAction;

	constructor({
		self,
		doer,
		target,
		invObject,
		action,
	}: {
		self: SimEntity;
		doer?: SimEntity;
		target?: SimEntity;
		invObject?: SimEntity;
		action: IAction;
	}) {
		this.self = self;
		this.doer = doer || self;
		this.target = target || undefined;
		this.invObject = invObject || undefined;
		this.action = action;
	}

	isValid(): boolean {
		return true;
	}

	isPlaying = false;

	async do() {
		console.time(`${this.getActionString()} duration:`);
		if (this.isPlaying || UIStore().activeAction) return;
		const setAct = async () => {
			this.isPlaying = true;
			UIStore().setActiveAction(this);
		};
		const clearAct = async () => {
			this.isPlaying = false;
			UIStore().setActiveAction(undefined);
			if (UIStore().hoveredItem) {
				onInventorySlotPointerMove(UIStore().hoveredItem || null);
			}
		};
		setAct();
		if (this.isValid()) {
			if (ENV.isDev) {
				await FileLog.info("Action", this.getActionString());
			}
			try {
				Debug("Action", "verbose").capture(`${this.action?.strFn?.(this)}`, {
					activeItem: InventoryStore().getActiveItem()?.name,
					hoveredItem: UIStore().hoveredItem?.name,
					target: this.target?.name,
					invObject: this.invObject?.name,
				});
				const result = await this.action.fn(this);
				await new Promise((r) => setTimeout(r, 100));
				if (result) {
					this.succeed();
					clearAct();
				} else {
					this.fail();
					clearAct();
				}
			} catch (e) {
				this.fail(e as Error);
				clearAct();
			}
		}
	}

	getActionString() {
		return this.action.strFn?.(this) || "NO_STR_ACTION";
	}

	succeed() {
		// execute onsuccess functions
		this.isPlaying = false;
		Debug("Action").log(`Success: ${this.getActionString()}`, this);
		UIStore().completeAction();
		UI_EVENTS.emit("getPlayerActions");
		console.timeEnd(`${this.getActionString()} duration:`);
	}

	fail(error?: Error) {
		// execute onfail functions
		this.isPlaying = false;
		Debug("Action").error(`Fail: ${this.getActionString()}`, error, this);
		UIStore().completeAction();
		UI_EVENTS.emit("getPlayerActions");
		console.timeEnd(`${this.getActionString()} duration:`);
	}
}
