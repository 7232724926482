import { getMUDState, tables } from "@mud";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import type { TAction } from "../actions/actions.types";
import { SimComponent } from "./SimComponent";
import { CInventoryItem } from "./CInventoryItem";

export class CStackable extends SimComponent {
	stackSize = 1;
	maxSize = 8 as number;

	async loadFromChain(): Promise<void> {
		const value = getMUDState().getValue(tables.Stackable, {
			inst: this.inst.ref as Hex,
		});
		if (!value) {
			console.warn(`no value for ${this.constructor.name}`, this.inst.ref);
			this.inst.clearComponent(CStackable);
			return;
		}
		this._setStackSize(value.stackSize);
		this.maxSize = value.maxSize;
	}

	private _setStackSize(size: number) {
		const oldSize = this.stackSize;
		this.stackSize = size;
		if (oldSize !== size) {
			this.inst.pushEvent("onStackSizeChanged", { oldSize, newSize: size });
		}
		return this;
	}

	isStack() {
		return this.stackSize > 1;
	}

	isFull() {
		return this.stackSize >= this.maxSize;
	}

	canCombineWith(target: SimEntity) {
		if (target.prefab !== this.inst.prefab) return false;
		if (
			target.component(CInventoryItem) &&
			target.component(CStackable) &&
			!target.component(CStackable).isFull() &&
			!this.isFull() &&
			!target.component(CInventoryItem).isHeld() &&
			!this.inst.component(CInventoryItem).isHeld()
			// target.component(CInventoryItem).canBePickedUp
		) {
			return true;
		}
		return false;
	}

	roomLeft() {
		return this.maxSize - this.stackSize;
	}

	collectUseActions(_doer: SimEntity, target: SimEntity, actions: TAction[]) {
		if (
			this.canCombineWith(target) &&
			this.inst.component(CInventoryItem).isHeld() &&
			!target.component(CInventoryItem).isHeld() &&
			!this.isFull()
		) {
			actions.push("COMBINESTACK");
		}
	}
}
