import { useCallback, useState } from "react";
import { copyTextToClipboard } from "./utils";

type TCopyStatus = "success" | "failure" | undefined;

export function useClipboard(): {
	copyToClipBoard: (text: string) => Promise<boolean>;
	status: TCopyStatus;
} {
	const [status, setStatus] = useState<TCopyStatus>(undefined);
	return {
		copyToClipBoard: useCallback(async (text: string): Promise<boolean> => {
			const success = await copyTextToClipboard(text);
			setStatus(success ? "success" : "failure");
			setTimeout(() => setStatus(undefined), 1000);
			return success;
		}, []),
		status,
	};
}
