import { Debug } from "@/lib/debug/debug";
import type { BufferedAction } from "@game/sim/actions/bufferedAction";
import type { SimEntity } from "@game/sim/SimEntity";

export type TConstraint = "TOP" | "SIDES" | "BOTTOM";

export type TBluePrint = {
	name: string;
	displayName?: string;
	upgradesFrom?: string;
	ingredients: {
		amount: number;
		name: string;
	}[];
	tools: {
		name: string;
	}[];
	buildingSide: TConstraint | undefined;
	requiresNeighbour: TConstraint[];
	strFn?: (act: BufferedAction) => string;
	canBuildFn?: (
		doer: SimEntity,
		recipe: TBluePrint,
		item: SimEntity,
		origin: SimEntity,
	) => boolean;
	onBuildFn?: () => void;
	execute: (
		doer: SimEntity,
		recipe: TBluePrint,
		item: SimEntity,
		origin: SimEntity,
	) => Promise<void>;
};

const DATA_BLUEPRINTS: Record<string, TBluePrint> = {};

export const getBlueprints = () => ({ ...DATA_BLUEPRINTS });

export const getBlueprintsByNames = (...names: string[]): TBluePrint[] => {
	return names.map((name) => {
		if (!DATA_BLUEPRINTS[name]) {
			throw new Error(`No blueprint found for name ${name}`);
		}
		return DATA_BLUEPRINTS[name];
	});
};

export const registerBlueprint = (blueprint: TBluePrint) => {
	if (DATA_BLUEPRINTS[blueprint.name]) {
		Debug("Blueprints").warn(
			`Registering duplicate blueprint ${blueprint.name}`,
		);
	}
	DATA_BLUEPRINTS[blueprint.name] = blueprint;
};
