import AssetLoader from "@/data/assetLoader/assetLoader";
import { useDevStore } from "@/lib/devtools/dev.store";
import { SigilsSystem } from "@/sigils/sigilSystem";
import { Canvas } from "@react-three/fiber";
import hotkeys from "hotkeys-js";
import { useEffect, useMemo, useRef, useState } from "react";
import { ACESFilmicToneMapping, type Fog, SRGBColorSpace } from "three";
import GameCamera from "./camera/gameCamera";
import { GameLoop } from "./gameLoop";
import { IslandGenerator } from "./generator/islandgenerator";
import { TheInput } from "./input/theInput";
import { WorldDevtools } from "./world/world.devtools";
import { WorldGenerator } from "./world/worldgenerator";

export function GameRoot() {
	const [enableGenerator, setEnableGenerator] = useState(false);
	const fogRef = useRef<Fog>(null!);
	const { devMode } = useDevStore((state) => {
		return {
			devMode: state.devMode,
		};
	});

	useEffect(() => {
		const toggleGenerator = (event: KeyboardEvent) => {
			event.preventDefault();
			setEnableGenerator(!enableGenerator);
		};

		hotkeys("shift+g", toggleGenerator);
		return () => {
			hotkeys.unbind("shift+g", toggleGenerator);
		};
	}, [enableGenerator]);

	const generatedLevel = useMemo(() => {
		if (!enableGenerator) return null;
		const generator = IslandGenerator({ options: { scale: 256 } });
		return generator.blocks;
	}, [enableGenerator]);

	return (
		<div id="canvas-container" className="h-screen w-full">
			<Canvas
				shadows
				gl={{ antialias: true, alpha: false }}
				onCreated={({ gl, events }) => {
					gl.outputColorSpace = SRGBColorSpace;
					gl.toneMapping = ACESFilmicToneMapping;
					gl.toneMappingExposure = 1.85;
					events.enabled = false;
				}}
				events={undefined}
			>
				<TheInput />
				<fog attach="fog" color="#A9D9D8" near={-1} far={35} ref={fogRef} />
				<AssetLoader />
				{enableGenerator && generatedLevel && (
					<WorldGenerator islands={generatedLevel} />
				)}
				{!enableGenerator && <GameLoop />}
				{devMode && <WorldDevtools />}
				<group>
					<GameCamera fogRef={fogRef} />
				</group>
				<SigilsSystem />
			</Canvas>
		</div>
	);
}
