import type { SimEntity } from "../SimEntity";
import type { TAction } from "../actions/actions.types";
import { CConstruction } from "./CConstruction";
import { SimComponent } from "./SimComponent";

// @dev buildable component resides on the ingredient/tool so it can be used on a building
export class CBuildable extends SimComponent {
	collectUseActions(_doer: SimEntity, target: SimEntity, actions: TAction[]) {
		if (target.component(CConstruction)) {
			if (target.component(CConstruction).canBuildItem(_doer, this.inst)) {
				actions.push("ADD_BUILD");
			}
		}
	}
}
