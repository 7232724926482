import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CPaint } from "@game/sim/components/CPaint";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";

export class ItemPigmentRedOchrePrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CPaint).setColor(1);
		e.addComponent(CInventoryItem).setSigil("pigment");
	}
}

const ochrePigmentData: TEntityDefinition = {
	name: "Pigment Red",
	simEntityPrefab: ItemPigmentRedOchrePrefab,
	id: 30401,
	metadata: {
		icon: "pigment1.webp",
	},
};

export class ItemPigmentGoldenrodPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CPaint).setColor(2);
		e.addComponent(CInventoryItem).setSigil("pigment");
	}
}

const goldPigmentData: TEntityDefinition = {
	name: "Pigment Yellow",
	simEntityPrefab: ItemPigmentGoldenrodPrefab,
	id: 30402,
	metadata: {
		icon: "pigment2.webp",
	},
};

export class ItemPigmentBlackberriesPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CPaint).setColor(3);
		e.addComponent(CInventoryItem).setSigil("pigment");
	}
}

const blackberriesPigmentData: TEntityDefinition = {
	name: "Pigment Purple",
	simEntityPrefab: ItemPigmentBlackberriesPrefab,
	id: 30403,
	metadata: {
		icon: "pigment3.webp",
	},
};

export class ItemPigmentCarrotPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CPaint).setColor(4);
		e.addComponent(CInventoryItem).setSigil("pigment");
	}
}

const carrotPigmentData: TEntityDefinition = {
	name: "Pigment Orange",
	simEntityPrefab: ItemPigmentCarrotPrefab,
	id: 30404,
	metadata: {
		icon: "pigment4.webp",
	},
};

export class ItemPigmentTurqoisePrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CPaint).setColor(5);
		e.addComponent(CInventoryItem).setSigil("pigment");
	}
}

const turqoisePigmentData: TEntityDefinition = {
	name: "Pigment Turqoise",
	simEntityPrefab: ItemPigmentTurqoisePrefab,
	id: 30405,
	metadata: {
		icon: "pigment5.webp",
	},
};

export class ItemPigmentBluePrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CPaint).setColor(6);
		e.addComponent(CInventoryItem).setSigil("pigment");
	}
}

const bluePigmentData: TEntityDefinition = {
	name: "Pigment Blue",
	simEntityPrefab: ItemPigmentBluePrefab,
	id: 30406,
	metadata: {
		icon: "pigment6.webp",
	},
};

export class ItemPigmentBlackPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CPaint).setColor(7);
		e.addComponent(CInventoryItem).setSigil("pigment");
	}
}

const blackPigmentData: TEntityDefinition = {
	name: "Black 5.0",
	simEntityPrefab: ItemPigmentBlackPrefab,
	id: 30407,
	metadata: {
		icon: "pigment_black.webp",
	},
};

export class ItemPigmentPepperPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CPaint).setColor(8);
		e.addComponent(CInventoryItem).setSigil("pigment");
	}
}

const pepperPigmentData: TEntityDefinition = {
	name: "Pigment Pepper",
	simEntityPrefab: ItemPigmentPepperPrefab,
	id: 30408,
	metadata: {
		icon: "pigment8.webp",
	},
};

export class ItemPigmentSpinachPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CPaint).setColor(9);
		e.addComponent(CInventoryItem).setSigil("pigment");
	}
}

const spinachPigmentData: TEntityDefinition = {
	name: "Pigment Spinach",
	simEntityPrefab: ItemPigmentSpinachPrefab,
	id: 30409,
	metadata: {
		icon: "pigment9.webp",
	},
};

export class ItemPigmentLilacPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CPaint).setColor(10);
		e.addComponent(CInventoryItem);
	}
}

const lilacPigmentData: TEntityDefinition = {
	name: "Pigment Lilac",
	simEntityPrefab: ItemPigmentLilacPrefab,
	id: 30410,
	metadata: {
		icon: "pigment10.webp",
	},
};

registerEntity(ochrePigmentData);
registerEntity(goldPigmentData);
registerEntity(blackberriesPigmentData);
registerEntity(carrotPigmentData);
registerEntity(turqoisePigmentData);
registerEntity(bluePigmentData);
registerEntity(blackPigmentData);
registerEntity(pepperPigmentData);
registerEntity(spinachPigmentData);
registerEntity(lilacPigmentData);
