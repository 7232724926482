import { SimComponent } from "./SimComponent";
import type { SimEntity } from "../SimEntity";

export class CUpdate extends SimComponent {
  private _update: ((e: SimEntity) => void) | null = null;
  frequency = 1;
  lastTick = 0;

  constructor(inst: SimEntity) {
    super(inst);
    inst.addTag("SHOULDUPDATE");
  }

  setUpdateFn(fn: () => void) {
    this._update = fn;
  }

  update() {
    this._update?.(this.inst);
  }
}
