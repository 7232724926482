import { getMUDState, tables } from "@mud";
import type { Hex } from "viem";
import type { SimEntity } from "../SimEntity";
import { SimComponent } from "./SimComponent";
import { f } from "@/lib/utils";

export class CFueled extends SimComponent {
	currentFuel = 0;
	maxFuel = 0;
	rate = 0;
	accepting = false;
	fuelType = "";

	value = () =>
		getMUDState().getValue(tables.Fueled, {
			inst: this.inst.ref as Hex,
		})!;

	constructor(inst: SimEntity) {
		super(inst);
		this.loadFromChain();
	}

	async loadFromChain(): Promise<void> {
		if (!this.value()) {
			console.warn(`no value for ${this.constructor.name}`, this.inst.ref);
			this.inst.clearComponent(CFueled);
			return;
		}

		const value = this.value();
		this.currentFuel = f(value.currentFuel);
		this.maxFuel = f(value.maxFuel);
		this.rate = f(value.rate);
		this.accepting = value.accepting;
		this.fuelType = value.fuelType;
	}

	isEmpty(): boolean {
		return this.currentFuel === 0;
	}

	isFull(): boolean {
		return this.currentFuel >= this.maxFuel;
	}

	getCurrentFuel() {
		return this.currentFuel;
	}

	getMaxFuel() {
		return this.maxFuel;
	}

	getFuelPercent() {
		return this.currentFuel / this.maxFuel;
	}
}
