import Audio from "@game/audio/audioPlayer";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CBurnable } from "@game/sim/components/CBurnable";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CUsable } from "@game/sim/components/CUsable";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { getSystemCalls } from "@mud/index";
import type { BufferedAction } from "@game/sim/actions/bufferedAction";

export class ItemWaterbucketPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem).setSigil("fertilizer");
		e.addComponent(CUsable).setCanUseFn(this.canUseFn).setUseFn(this.useFn);
	}

	canUseFn = (_doer: SimEntity, target: SimEntity) => {
		if (target.component(CBurnable)?.isBurning()) {
			return true;
		}
		return false;
	};

	useFn = async ({ doer, target }: BufferedAction) => {
		if (!doer || !target) {
			throw new Error("No doer or target");
		}
		const { action_useItemOnTarget } = getSystemCalls();
		console.log(this.inst.ref, target.ref);
		Audio.playSound("SPLASH", { volume: 1, broadcast: true });
		await action_useItemOnTarget(doer.ref, this.inst.ref, target.ref);
	};
}

const entityData: TEntityDefinition = {
	name: "Waterbucket",
	simEntityPrefab: ItemWaterbucketPrefab,
	id: 40003,
	metadata: {
		icon: "bucket.webp",
	},
};

registerEntity(entityData);
