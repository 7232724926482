import { getAllNeighbours } from "@/lib/hexagrid/hexagrid.neighbours";

export type TAdjacentRecord = {
	quadIndex: number;
	quadNeighbourIndex: number;
	q: number;
	r: number;
};

export const generateAdjacentRecords = () => {
	const adjacentRecords: TAdjacentRecord[] = [];

	const neighbours = getAllNeighbours([0, 0]);
	Object.entries(neighbours).forEach(([quadIndex, neighbour]) => {
		for (let i = 0; i < 4; i++) {
			const adjacentRecord = {
				quadIndex: Number.parseInt(quadIndex),
				quadNeighbourIndex: neighbour[i].quadIndex,
				q: neighbour[i].hex[0],
				r: neighbour[i].hex[1],
			};
			// console.log(adjacentRecord);
			adjacentRecords.push(adjacentRecord);
		}
	});
	return adjacentRecords;
};
