import { getMesh } from "@/data/assetLoader/asset.store";
import { useBatchedRenderer } from "@/lib/batching/useBatchedRenderer";
import { deformMesh } from "@/lib/deformation/meshDeformation";
import { createTransformationMatrix } from "@/lib/placement.utils";
import { randomFromArray } from "@/lib/random/seeded.functions";
import { useBlock } from "@/lib/useBlock";
import { ConstructableInput } from "@game/components/constructableInput";
import type { SimEntity } from "@game/sim/SimEntity";
import { useMemo } from "react";
import {
	Color,
	DoubleSide,
	MeshStandardMaterial,
	Vector3,
	type Material,
	type MeshBasicMaterial,
} from "three";

const modelPath = "rocks/rocks";

const meshes = [`${modelPath}/rock000`];
const bottom = [
	`${modelPath}/RockBottom000`,
	`${modelPath}/RockBottom001`,
	`${modelPath}/RockBottom002`,
];

const rockColor = new Color("#534746");

const rockMaterial = new MeshStandardMaterial();

export function RockRenderer({ entity }: { entity: SimEntity }) {
	const { block, rng, pos, rot } = useBlock({ entity });
	const blockBelow = useMemo(() => !block.hasNeighbourDown(), [block]);
	const material = useMemo(() => {
		const model = getMesh(randomFromArray(meshes, rng));
		const mat = model.material as Material;
		const material = rockMaterial;
		material.map = (mat as MeshBasicMaterial).map;
		material.dithering = true;
		material.shadowSide = DoubleSide;
		material.toneMapped = true;
		return material;
	}, [rng]);

	useBatchedRenderer(
		entity,
		() => {
			const model = getMesh(randomFromArray(meshes, rng));
			const o = rng(0, 4);
			if (!model) {
				throw new Error("no model");
			}
			const deformedGeo = deformMesh(model, block, o).geometry;
			return {
				geometry: deformedGeo,
				material,
				opts: {
					matrix: createTransformationMatrix(
						pos,
						[0, 0, 0],
						[1.125, 1.05, 1.125],
					),
					color: rockColor,
				},
			};
		},
		[entity],
	);

	useBatchedRenderer(
		entity,
		blockBelow
			? () => {
					const model = getMesh(randomFromArray(bottom, rng));
					const o = rng(0, 4);

					if (!model) {
						throw new Error("no model");
					}
					const deformedGeo = deformMesh(model, block, o).geometry;
					return {
						geometry: deformedGeo,
						material,
						opts: {
							matrix: createTransformationMatrix(
								pos.clone().add(new Vector3(...[0, -0.025, 0])),
								[0, rot, 0],
								[0.5, 0.5, 0.5],
							),
							color: rockColor,
						},
					};
				}
			: null,
		[entity],
	);

	return useMemo(() => {
		return (
			<group>
				<ConstructableInput
					entity={entity}
					args={[0.5, 0.5, 0.5]}
					key={entity.ref}
				/>
			</group>
		);
	}, [entity]);
}
