import { logVerbose } from "@/lib/devtools/dev.store";
import { getEntityDataByID } from "@/data/entity/entity.data.fn";
import { UserStore } from "@/data/user-settings.store";
import { Debug } from "@/lib/debug/debug";
import { getMUDState, getNetwork, getSystemCalls } from "@mud/index";
import type { Hex } from "viem";
import { WorldStore } from "./world/world.store";
import { ENV } from "@/lib/env";
import { createPrefabInstance } from "./sim/sim.functions";
import { getEntityByRef } from "./sim/sim.store";
import Audio from "./audio/audioPlayer";

export const startGame = async () => {
	// bypass signup if we're in dev mode
	if (ENV.DEV && ENV.DEV_SIGNUP) {
		Debug("Game").log("DEV MODE: Skipping signup");
		await setupPlayer();
		return;
	}
	// check if we have an existing player
	if (await getPlayerContainer()) {
		Debug("Game").log("Player already exists");
		await setupPlayer();
		return;
	}
};

export const getPlayerContainer = async () => {
	const { playerEntity, tables } = getNetwork();
	if (!playerEntity) return;

	const playerContainer = getMUDState().getRecord(tables.Container, {
		inst: playerEntity as Hex,
	});

	return playerContainer;
};

export const setupPlayer = async () => {
	logVerbose() && console.time("Player Sync");
	const { playerEntity, tables } = getNetwork();
	if (!playerEntity) return;

	const playerContainer = getMUDState().getRecord(tables.Container, {
		inst: playerEntity as Hex,
	});

	await getNetwork().faucet?.drip();

	// See if we have a player entity otherwise create one
	if (!playerContainer) {
		try {
			console.log("🌱 Creating player syscall");
			await getSystemCalls().action_createPlayer();
			setupPlayer();
			return;
		} catch (e) {
			console.error(e);
			console.log("Player already exists ok");
		}
		console.log("playerContainer", playerContainer);
	}

	await getNetwork().faucet?.enableFaucet();

	UserStore().setSignedUp(getNetwork().worldContract.address);

	const playerData = getMUDState().getValue(tables.Player, {
		inst: playerEntity as Hex,
	});

	logVerbose() && console.log(playerData);
	// We have player data
	if (playerData !== undefined) {
		const playerId = Number(playerData.id);

		// Log the player ID to Analytics
		Debug("Game").identity({
			playerId,
			playerEntity,
		});
		UserStore().set({
			playerId,
		});
	}

	// If we don't have a player entity yet, create one
	if (!getEntityByRef(playerEntity)) {
		const playerPrefab = getEntityDataByID(1);
		if (!playerPrefab) throw new Error("No player prefab");
		const p = createPrefabInstance(playerPrefab, playerEntity);
		Debug("Game").log("Player entity created:", p);
		Debug("Game").log("Player setup:", playerEntity);
		Debug("Game").capture("Player creation", {
			playerEntity: playerEntity,
		});
	}

	// Log the player as a spectator
	Debug("Game").identity({
		spectator: UserStore().isSpectator(),
		playerEntity,
	});

	Audio.playSound("PLAYER", { volume: 0.0625, broadcast: true });
	WorldStore().set({ playerRef: playerEntity });
	logVerbose() && console.timeEnd("Player Sync");
};
