import { getColorByName } from "@/data/colors/palette.data";
import Audio from "@game/audio/audioPlayer";
import { getMUDState, getSystemCalls, tables } from "@mud";
import { Color } from "three";
import type { SimEntity } from "../SimEntity";
import type { TAction } from "../actions/actions.types";
import { SimComponent } from "./SimComponent";
import type { Hex } from "viem";
import { PaintColors } from "./CPaint";

export class CVariant extends SimComponent {
	color = 0;
	altColor = 0;

	value = () =>
		getMUDState().getValue(tables.Variant, {
			inst: this.inst.ref as Hex,
		})!;

	setColor(color: number) {
		this.color = color;
	}

	collectUseActions(_doer: SimEntity, target: SimEntity, actions: TAction[]) {
		if (this.inst.hasTag("isPaintable")) {
			actions.push("PAINT");
		}
	}

	async loadFromChain(): Promise<void> {
		if (!this.value()) {
			console.warn(`no value for ${this.constructor.name}`, this.inst.ref);
			this.inst.clearComponent(CVariant);
			return;
		}
		const oldColor = this.color;
		const oldAltColor = this.altColor;
		this.color = this.value().color;
		this.altColor = this.value().altColor;
		if (oldColor !== this.color || oldAltColor !== this.altColor) {
			this.inst.pushEvent("onVariantChanged", {});
		}
	}

	async paintTarget(target: SimEntity) {
		const { action_paint } = getSystemCalls();
		await action_paint(this.inst.ref, target.ref, this.inst.ref, this.color, 0);
		Audio.playSound("FLESH_HIT", { volume: 0.125, broadcast: true });
	}

	static getPaint(color: number): Color {
		if (color < 0 || color > PaintColors.length) {
			throw new Error("Invalid color index");
		}
		const hex = getColorByName("houses", PaintColors[color])?.hex;
		if (!hex) {
			throw new Error("Invalid color");
		}
		return new Color(hex);
	}
}
