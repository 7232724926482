import { customFormatEther, truncateAddress } from "@/lib/utils";
import { UILayout } from "@game/ui/components/ui.layout";
import { useEffect, useState } from "react";
import { useAccount, useEnsName } from "wagmi";
import usePayments from "./usePayments";
import { ItemShopStore } from "@game/ui/components/itemShop/itemShop.store";
import { usePrivy } from "@privy-io/react-auth";
import { t } from "i18next";

/*
 * @deprecated Try to deprecate this ASAP
 */
const ConnectButton = () => {
	const { login, logout, authenticated, isModalOpen, ready } = usePrivy();
	const [connectText, setConnectText] = useState("Connect");
	const { address, isDisconnected, isConnected } = useAccount();
	const { data: ensName, refetch } = useEnsName({
		address,
		query: { enabled: ready },
	});
	const { balance } = usePayments();

	useEffect(() => {
		if (!ready) return;
		if (!authenticated) {
			setConnectText("connect");
			return;
		}
		refetch();
		if (isConnected && address) {
			const name = ensName || truncateAddress(address!);
			setConnectText(name);
		}
		if (authenticated && isDisconnected) {
			setConnectText("logout");
		}
		if (isConnected) {
			setConnectText("disconnect");
		}
	}, [
		ready,
		address,
		isDisconnected,
		isConnected,
		ensName,
		refetch,
		authenticated,
	]);

	return (
		<UILayout data-name="connect-button">
			<div className="flex items-right justify-right m-2 pt-[1px] gap-1">
				<div className="flex-grow" />

				{authenticated && balance.data && (
					<div className="flex self-center text-white font-bold mr-2">
						{customFormatEther(balance.data?.value || 0n, 2).toString()}{" "}
						{ItemShopStore().currency}
					</div>
				)}
				<button
					className="uppercase text-white font-extrabold bg-transparent mx-auto px-4 py-2 rounded-full active:scale-95 transition-all disabled:bg-slate-600 hover:scale-105 border-2 border-white/0 relative overflow-clip hover:border-white/5 hover:bg-white/5 font-berkeley pointer-events-auto cursor-pointer"
					onClick={() => {
						if (authenticated) {
							logout();
							return;
						}
						login();
					}}
				>
					{t(connectText)}
				</button>
			</div>
		</UILayout>
	);
};

export default ConnectButton;
