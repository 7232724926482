import {
	type TBluePrint,
	registerBlueprint,
} from "@/data/blueprint/blueprint.data";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import Audio from "@game/audio/audioPlayer";
import { TheMouseData } from "@game/input/theInput";
import { CBuildable } from "@game/sim/components/CBuildable";
import { CConstruction } from "@game/sim/components/CConstruction";
import { CDecorator } from "@game/sim/components/CDecorator";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CRenderer } from "@game/sim/components/CRenderer";
import { CTransmute } from "@game/sim/components/CTransmute";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { getSystemCalls } from "@mud/index";

export class ItemLimestonePrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.nameFn = (self) => {
			if (!e.component(CInventoryItem)) {
				return "Wall";
			}
			return "Limestone";
		};
		e.addComponent(CInventoryItem).setSigil("limestone");
		e.addComponent(CBuildable);
		e.addComponent(CTransmute);
	}
}

const entityData: TEntityDefinition = {
	name: "Limestone",
	simEntityPrefab: ItemLimestonePrefab,
	id: 30003,
	metadata: {
		icon: "limestone.webp",
	},
};

const limestoneWall: TBluePrint = {
	name: "LimestoneWall",
	displayName: "Limestone Wall",
	ingredients: [{ amount: 1, name: "Limestone" }],
	tools: [],
	buildingSide: undefined,
	requiresNeighbour: [],
	canBuildFn: (
		_doer: SimEntity,
		recipe: TBluePrint,
		_item: SimEntity,
		origin: SimEntity,
	) => {
		const construction = origin.component(CConstruction);
		return construction.canBuildInDirection(recipe);
	},
	onBuildFn: () => {
		Audio.playSound("PUT", { volume: 0.125, broadcast: true });
	},
	execute: async (
		doer: SimEntity,
		_recipe: TBluePrint,
		item: SimEntity,
		target: SimEntity,
	) => {
		const { action_buildWall } = getSystemCalls();
		const block = target.component(CRenderer).block;
		if (!block) throw new Error("no block");
		const decoratorCount = target.component(CDecorator).decoratorCount;
		await action_buildWall(
			doer.ref,
			target.ref,
			item.ref,
			TheMouseData.getFaceIndex(),
		);
		if (decoratorCount === 3) {
			Audio.playSound("MUSIC_BOOTUP");
		}
	},
};

registerBlueprint(limestoneWall);
registerEntity(entityData);
