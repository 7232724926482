import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import Audio from "@game/audio/audioPlayer";
import type { BufferedAction } from "@game/sim/actions/bufferedAction";
import { CGrowable } from "@game/sim/components/CGrowable";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CUsable } from "@game/sim/components/CUsable";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { getSystemCalls } from "@mud/index";

const canUseFertilizerFn = (doer: SimEntity, target: SimEntity) => {
	if (target.component(CGrowable)?.canGrow()) {
		return true;
	}
	return false;
};

const useFertilizerFn = async ({ doer, target, invObject }: BufferedAction) => {
	if (!doer || !target || !invObject) {
		throw new Error("No doer or target");
	}
	const { action_useItemOnTarget } = getSystemCalls();
	if (target.component(CGrowable)?.canGrow()) {
		Audio.playSound("SPLASH", { volume: 1, broadcast: true });
		await action_useItemOnTarget(doer.ref, invObject.ref, target.ref);
	}
};

export class ItemFertilizerPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem).setSigil("fertilizer");
		e.addComponent(CUsable)
			.setCanUseFn(canUseFertilizerFn)
			.setUseFn(useFertilizerFn);
	}
}

const entityData: TEntityDefinition = {
	name: "Fertilizer",
	simEntityPrefab: ItemFertilizerPrefab,
	id: 40002,
	metadata: {
		icon: "fertilizer.webp",
	},
};

registerEntity(entityData);
