import { logVerbose } from "@/lib/devtools/dev.store";
import { Debug } from "@/lib/debug/debug";
import type { SimEntity } from "@game/sim/SimEntity";
import { CRenderer } from "@game/sim/components/CRenderer";
import { ISLANDS } from "@game/world/islands/islands.functions";
import type { IBlock } from "./block.types";
import type { WorldCoordinate } from "./world.types";

const blocks = new Map<string, [SimEntity, IBlock]>();

export const addBlock = (entity: SimEntity) => {
	const block = entity.component(CRenderer).block!;
	if (blocks.has(block.coordinate._string)) {
		logVerbose() &&
			Debug("World").error(
				`Block already exists at ${block.coordinate._string}`,
			);
		return;
	}
	blocks.set(block.coordinate._string, [entity, block]);
	ISLANDS.registerBlock(block);
};

export const removeBlock = (coordinate: WorldCoordinate) => {
	const block = blocks.get(coordinate._string);
	if (!block) {
		logVerbose() &&
			Debug("World").error(`No block exists at ${coordinate._string}`);
		return;
	}
	// ISLANDS.unregisterBlock(block![1]);
	blocks.delete(coordinate._string);
};

export const getBlock = (coordinate: WorldCoordinate) => {
	return blocks.get(coordinate._string);
};

export const getEntityByCoordinate = (coordinate: WorldCoordinate) => {
	return blocks.get(coordinate._string)?.[0];
};

export const isPositionEmpty = (coordinate: WorldCoordinate) => {
	return !getBlock(coordinate);
};

export const exportBlocks = () => {
	return Array.from(blocks.values()).map(([entity, block]) => ({
		coordinate: block.coordinate,
		entity,
		block,
	}));
};
