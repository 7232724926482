import type { MUDChain } from "src/types";
import { arbitrumSepolia as _arbitrumSepolia } from "viem/chains";

export const conduitCloudlines = {
	...(_arbitrumSepolia as MUDChain),
	name: "Conduit Cloudlines",
	id: 573146821555,
	blockExplorers: {
		default: {
			name: "Conduit",
			url: "https://explorer-cloudlines-7gm4rnga08.t.conduit.xyz",
		},
	},
	rpcUrls: {
		default: {
			http: [
				"https://rpc-cloudlines-7gm4rnga08.t.conduit.xyz/Mg4rnK3YRneXzP5w11FdHeccGr36SD9Kf",
			],
			webSocket: [
				"wss://rpc-cloudlines-7gm4rnga08.t.conduit.xyz/Mg4rnK3YRneXzP5w11FdHeccGr36SD9Kf",
			],
		},
	},
};
