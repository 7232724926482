import { getParticleData } from "@/data/particle.data";
import { UserStore, useUserStore } from "@/data/user-settings.store";
import { ENV } from "@/lib/env";
import { expDecay } from "@/lib/utils";
import { Background } from "@game/background";
import {
	createParticleEffect,
	expireParticleEffect,
} from "@game/effects/particle.functions";
import {
	ParticleStore,
	type TParticleEffectRef,
} from "@game/effects/particle.store";
import { theSim, WorldGlobals } from "@game/sim/sim.store";
import { UIStore, useUIStore } from "@game/ui/ui.store";
import { ISLANDS } from "@game/world/islands/islands.functions";
import { CameraControls, PerspectiveCamera } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import hotkeys from "hotkeys-js";
import { useCallback, useEffect, useRef } from "react";
import { type Fog, type Group, MathUtils, Vector3 } from "three";
import { CloudBox } from "./cloudBox";

let fogDist = 15;
const pos = new Vector3();
let creatingStorm = false;

export default function GameCamera({
	fogRef,
}: {
	fogRef: React.MutableRefObject<Fog>;
}) {
	const { showGameLogo } = useUIStore((state) => state.uiState);
	const stormEffectRef = useRef<TParticleEffectRef | null>(null!);
	const angle = useRef(0);
	const userInteracting = useRef(0);
	const { camera } = useThree();
	const groupRef = useRef<Group>(null!);
	const backgroundRef = useRef<Group>(null!);
	const orbitRef = useRef<CameraControls>(null!);
	const dt = useRef(0);

	const playerId = useUserStore((state) => state.playerId);
	const { activeIsland, loadingComplete } = useUIStore((state) => {
		return {
			activeIsland: state.activeIsland,
			loadingComplete: state.loadingComplete,
		};
	});

	const setCamera = useCallback(() => {
		if (activeIsland && orbitRef.current) {
			const targetPosition = activeIsland.calculateCenter();

			const distance =
				Math.min(activeIsland.calculateBoundingDistance(), 2) *
				5 *
				ENV.CINEMATIC_ZOOM;
			const islandDir = camera.position.clone().sub(targetPosition).normalize();
			const newCameraPos = targetPosition
				.clone()
				.add(islandDir.multiplyScalar(distance));

			newCameraPos.y = targetPosition.y + 2;

			orbitRef.current.setLookAt(
				newCameraPos.x,
				newCameraPos.y,
				newCameraPos.z,
				targetPosition.x,
				targetPosition.y,
				targetPosition.z,
				true,
			);
		}
	}, [activeIsland, camera.position]);

	useEffect(() => {
		const i =
			playerId !== -1
				? ISLANDS.all[playerId % ISLANDS.all.length]
				: ISLANDS.getClosest(new Vector3(0, 0, 0));
		setCamera();
		hotkeys("space", (event, _handler) => {
			console.log(i, camera, orbitRef.current);
			event.preventDefault();
			UIStore().set({
				activeIsland: i,
			});
		});
		return () => {
			hotkeys.unbind("space");
		};
	}, [setCamera, playerId, camera]);

	useEffect(() => {
		if (!loadingComplete) return;
		const i =
			playerId !== -1
				? ISLANDS.all[playerId % ISLANDS.all.length]
				: ISLANDS.getClosest(new Vector3(0, 0, 0));
		const initCamera = async () => {
			if (UIStore().activeIsland === i) return;
			UIStore().set({
				activeIsland: i,
			});
			setCamera();
		};
		initCamera();

		theSim.on("loadingComplete", initCamera);

		return () => {
			theSim.off("loadingComplete", initCamera);
		};
	}, [playerId, loadingComplete, setCamera]);

	useEffect(() => {
		const nextIsland = () => {
			const next =
				(ISLANDS.all.indexOf(activeIsland!) + 1) % ISLANDS.all.length;
			UIStore().set({
				activeIsland: ISLANDS.all[next],
			});
			console.log(
				`thank u next (${next}) https://www.youtube.com/watch?v=gl1aHhXnN1k&pp=ygUOdGhhbmsgeW91IG5leHQ%3D`,
			);
		};

		const prevIsland = () => {
			let prev = (ISLANDS.all.indexOf(activeIsland!) - 1) % ISLANDS.all.length;
			if (prev < 0) prev = ISLANDS.all.length - 1;
			UIStore().set({
				activeIsland: ISLANDS.all[prev],
			});
		};

		// hotkeys("tab", (event, _handler) => {
		// 	event.preventDefault();
		// 	nextIsland();
		// });

		// hotkeys("shift+tab", (event, _handler) => {
		// 	event.preventDefault();
		// 	prevIsland();
		// });

		hotkeys("]", (event, _handler) => {
			event.preventDefault();
			nextIsland();
		});

		hotkeys("[", (event, _handler) => {
			event.preventDefault();
			prevIsland();
		});

		return () => {
			// hotkeys.unbind("tab");
			// hotkeys.unbind("shift+tab");
			hotkeys.unbind("]");
			hotkeys.unbind("[");
		};
	}, [activeIsland]);

	useFrame((_state, delta) => {
		dt.current = delta;
		const { currentRain } = WorldGlobals();
		const stormSize = Math.max(0.3, Number(currentRain) / 10000);
		if (currentRain > 0) {
			if (stormEffectRef.current) {
				stormEffectRef.current.particleEffect.scale.set(
					stormSize,
					stormSize,
					stormSize,
				);
			}
			if (stormEffectRef.current === null && !creatingStorm) {
				const createStorm = async () => {
					if (stormEffectRef.current) {
						ParticleStore().removeParticleEffect(stormEffectRef.current);
					}
					creatingStorm = true;
					stormEffectRef.current = await createParticleEffect({
						particle: getParticleData("rainfall"),
						position: ISLANDS.all[0].center.clone().add(new Vector3(0, 8, 0)),
						scale: new Vector3(stormSize, stormSize, stormSize),
						lifetime: 100000,
						onDestroy: (effect) => {
							console.log("effect destroyed", effect);
							stormEffectRef.current = null;
						},
					});
					console.log("creatied storm", stormEffectRef.current, currentRain);
					creatingStorm = false;
				};
				createStorm();
			}
		} else {
			if (stormEffectRef.current) {
				ParticleStore().removeParticleEffect(stormEffectRef.current);
			}
		}
		if (!activeIsland || userInteracting.current > 0) {
			userInteracting.current -= 0.5;
			return;
		}

		if (typeof window === "undefined") {
			return;
		}

		// Cinematic mode
		if (!showGameLogo && !UserStore().isCinematic()) return;

		// FIXME: types 💖. The way you're supposed to use them.
		const speed =
			(window as { cinematicSpeed?: number }).cinematicSpeed ||
			ENV.CINEMATIC_SPEED;

		angle.current =
			orbitRef.current.azimuthAngle + MathUtils.degToRad(delta * speed);
		orbitRef.current.rotateAzimuthTo(angle.current, false);
	});

	const onCameraChange = (e?: {
		type: "update" | "control";
	}) => {
		if (!orbitRef.current || !activeIsland || !fogRef.current) return;
		orbitRef.current.getPosition(pos);
		backgroundRef.current.position.copy(activeIsland.center);
		const dist = pos.distanceTo(activeIsland.center);
		const fogFar = dist * 3;
		if (fogFar !== fogDist) {
			fogDist = expDecay(fogRef.current.far, fogFar, 5, dt.current);
			fogRef.current.far = fogDist;
		}
		// Calculate angle from camera position
		if (e?.type === "control") {
			userInteracting.current = Math.max(userInteracting.current, 0);
			userInteracting.current += 1;
		}
	};

	return (
		<group ref={groupRef} key="cameras">
			<PerspectiveCamera
				makeDefault
				position={[130, 90, 150]}
				fov={65}
				near={0.3}
			/>
			{/* // TODO Render UI in second pass*/}
			<CameraControls
				makeDefault
				minDistance={1}
				maxDistance={15}
				ref={orbitRef}
				maxPolarAngle={(Math.PI / 16) * 12}
				minPolarAngle={(Math.PI / 16) * 2}
				onChange={onCameraChange}
			/>
			<CloudBox />
			<group ref={backgroundRef}>
				<Background />
			</group>
			{/* {orbitTarget && <primitive object={orbitTarget} />} */}
		</group>
	);
}
