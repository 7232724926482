import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { registerFormula, type TFormula } from "@/data/formula/formula.data";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CPaint } from "@game/sim/components/CPaint";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";

export class ItemLapisPrefab extends SimEntityPrefab {
	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CPaint).setColor(6);
		e.addComponent(CInventoryItem).setSigil("gemstone");
	}
}

const entityData: TEntityDefinition = {
	name: "Lapis Lazuli",
	simEntityPrefab: ItemLapisPrefab,
	id: 30201,
	metadata: {
		icon: "lapislazuli.webp",
	},
};

const lapisBlue = {
	name: "LapislazuliBlue",
	activeItem: [30003],
	combinesWith: [30201],
	produces: 30406,
	destroysActiveItem: false,
} as const as TFormula;

registerEntity(entityData);
registerFormula(lapisBlue);
