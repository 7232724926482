import { deformMesh } from "@/lib/deformation/meshDeformation";
import type { Block } from "@game/world/block";
import type { MeshProps } from "@react-three/fiber";
import { type PropsWithChildren, createRef, useEffect, useMemo } from "react";
import type { Mesh } from "three";

function DeformedMesh({
	block,
	model,
	children,
	orientation,
	userData,
	...props
}: {
	block: Block;
	model: Mesh;
	color: string;
	orientation?: number;
	userData?: { entityRef: string } | undefined;
} & PropsWithChildren &
	MeshProps) {
	const meshRef = createRef<Mesh>();

	const mesh = useMemo(() => {
		if (!model || !model.geometry) return null;
		return deformMesh(model, block, orientation).geometry;
	}, [block, model, orientation]);

	useEffect(() => {
		if (!meshRef.current) return;
		meshRef.current.matrixWorldNeedsUpdate = true;
		meshRef.current.matrixAutoUpdate = true;
		meshRef.current.matrixWorldAutoUpdate = true;
		meshRef.current.updateMatrix();
		meshRef.current.updateMatrixWorld();
		meshRef.current.updateWorldMatrix(true, true);
		meshRef.current.matrixWorldAutoUpdate = false;
		meshRef.current.matrixAutoUpdate = false;
		meshRef.current.matrixWorldNeedsUpdate = false;
	}, [meshRef]);

	return (
		<>
			<mesh
				ref={meshRef}
				geometry={mesh || undefined}
				{...props}
				castShadow
				receiveShadow
				matrixAutoUpdate={false}
				matrixWorldAutoUpdate={false}
				userData={userData}
			>
				{children}
			</mesh>
		</>
	);
}

export default DeformedMesh;
